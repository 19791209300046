import { Component } from '@angular/core';
import {AfterViewInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { Router } from '@angular/router';
@Component({
  selector: 'app-review-doctor',
  templateUrl: './review-doctor.component.html',
  styleUrls: ['./review-doctor.component.css']
})
export class ReviewDoctorComponent {
  displayName='HealthCamp';
  showFilter: boolean = false;
selectedCheckboxes: string[] = [];
showTextField: boolean = false;

toggleCheckbox(checkboxValue: string) {
  const index = this.selectedCheckboxes.indexOf(checkboxValue);
  if (index === -1) {
    this.selectedCheckboxes.push(checkboxValue);
  } else {
    this.selectedCheckboxes.splice(index, 1);
  }
}


removeCheckbox(checkboxValue: string) {
  const index = this.selectedCheckboxes.indexOf(checkboxValue);
  if (index !== -1) {
    this.selectedCheckboxes.splice(index, 1);
  }
}

applyFilter() {
  // Add logic to apply the filter based on the selectedCheckboxes array
  console.log('Selected Checkboxes:', this.selectedCheckboxes);
  // Add your display or filtering logic here
}
  

  toggleTextField() {
    this.showTextField = !this.showTextField;
  }
  

  onRowClicked(){
    this.routes.navigate(['/doctor2']);
  }


  
  constructor(private routes:Router){}
  displayedColumns: string[] = ['UIN', 'name', 'Class', 'Flag','Review','Reviewed','Reviewedon'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }




  // toggleFilter(): void {
  //   this.showFilter = !this.showFilter;
  //   // this.showFilterCard = !this.showFilterCard;
  //   // console.log(this.filterCaste)
  
  // }
  toggleFilter(): void {
    this.showFilter = !this.showFilter;
    
    // this.showFilterCard = !this.showFilterCard;
    // console.log(this.filterCaste)
  
  }
 



  }


export interface PeriodicElement {
  UIN: number;
  name: string;
  Class: string;
  Flag: string;
  // ReviewStatus: string;
  Reviewedon:string;
  Reviewed:string;
  Review:string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { UIN: 1, name: 'Arun', Class: 'Iv-5', Flag: 'Emergency', Review: 'On Hold',Reviewedon:'8 Oct 2023',Reviewed:'Patrudu' },
  { UIN: 2, name: 'Arun', Class: 'Iv-5', Flag: 'WNL', Review: 'Review Completed',Reviewedon:'8 Oct 2023',Reviewed:'Patrudu' },
  { UIN: 3, name: 'Arun', Class: 'Iv-5', Flag: 'Non-Urgent', Review: 'Not Reviewed',Reviewedon:'8 Oct 2023',Reviewed:'Patrudu' },


  
];


