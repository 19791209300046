import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SectionsComponent } from './sections/sections.component';
import { HcpLoginComponent } from './hcp-login/hcp-login.component';
import { HcpSectionsComponent } from './hcp-sections/hcp-sections.component';
import { ProviderSectionsComponent } from './provider-sections/provider-sections.component';
import { IdCardComponent } from './id-card/id-card.component';
import { DialogPopUpComponent } from './dialog-pop-up/dialog-pop-up.component';
import { InfoseekIdCardComponent } from './infoseek-id-card/infoseek-id-card.component';
import { HCPIdCardComponent } from './hcp-id-card/hcp-id-card.component';
import { HcpproviderComponent } from './hcpprovider/hcpprovider.component';
// import { HcpNoteComponent } from './hcp-note/hcp-note.component';
// import { ProviderNoteComponent } from './provider-note/provider-note.component';
import { ProviderListComponent } from './provider-list/provider-list.component';
// import { CanActivateAuthGuardOne } from './can-activate-authguard-one.service'; 
// import { CanActivateAuthGuardOne } from './path-to-can-activate-authguard-one.service';
import { ReviewDoctorComponent } from './review-doctor/review-doctor.component';
import { CampLoginComponent } from './camp-login/camp-login.component';
import { ReviewDoctorSecondComponent } from './review-doctor-second/review-doctor-second.component';
import { AuthGuard } from './auth.guard';
import { providerGuardGuard } from './provider-guard.guard';




const routes: Routes = [
  {path: 'login', component: LoginComponent },
  {path: 'register',component:SectionsComponent},
  {path:'',pathMatch:'full',redirectTo:'hcplogin'},
  {path:'hcplogin',component:HcpLoginComponent},
  {path:'provider',component:ProviderSectionsComponent,canActivate: [providerGuardGuard]},
  {path:'idcard',component:IdCardComponent,canActivate: [providerGuardGuard]},
  {path:'dialog',component:DialogPopUpComponent},
  {path:'infoseek',component:InfoseekIdCardComponent},
  {path:'HCPIdcard',component:HCPIdCardComponent,canActivate: [AuthGuard] },
  {path:'hcpsections',component:HcpSectionsComponent,canActivate: [AuthGuard]},
  {path:'hcpproviderlogin',component:HcpproviderComponent},
  // {path:'hcpnote',component:HcpNoteComponent},
  // {path:'providernote',component:ProviderNoteComponent},
  {path:'providerlist',component:ProviderListComponent,canActivate: [providerGuardGuard]},
  // {path:'reviewlogin',component:Rev}
  {path:'reviewdoctor',component:ReviewDoctorComponent},
  {path:'camplogin',component:CampLoginComponent},
  {path:'doctor2',component:ReviewDoctorSecondComponent},  
  ];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
  
})
export class AppRoutingModule { }
