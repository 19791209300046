import { isNgTemplate } from '@angular/compiler';
import { Component, ElementRef, ViewChild,OnInit,Input   } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NivishService } from '../nivish.service';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Validators } from '@angular/forms';




interface Activity {
  name: string;
  selected: boolean;
  othersInput? : string;

  
}

interface Intolerant {
  name: string;
  selected: boolean;
  othersIntolerant?: string;
  
}
interface sleep{
  name:  string;
  selected: boolean;
  otherssleep?: string
}
interface Ill {
  name: string;
  selected: boolean;
  othersIllness?: string;
}
interface Medical {
  name: string;
  selected: boolean;
  // othersIllness?: string;
}
interface Respect{
  name:string;
  selected:boolean;
}
interface Performance{
   name:string;
   selected:boolean;
}
interface Disatisfy{
  name:string;
  selected:boolean;
  othersDissatification?:string;
}
interface Current{
  name:string;
  selected:boolean;

}
interface Nap {
  napLabel: string;
  showRemoveButton: boolean;
} 
@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.css']
})


export class SectionsComponent {
  showOtherWarning: boolean = false;
  nextButtonClicked: boolean = false;
  dissatisifieds: any[] = [];
  relationshipStudentOthers:any;
  @Input() firstName: string='';
  formId = 2341567348902;
  activetab='section';
  showFullName: boolean = false;
  studentPersonalInformation:any;
  studentMiddleName1:any;
  studentMiddleName2:any;
  studentLastName:any;
  // dateofBirth: string = '15-10-1996';
  age: number | undefined;
  noofSisters:any;
  noOfBrothers:any;
  sleeppernight:any;
  healthInsuranceInformation: any = {};
  addressInformation: any = {};
  guardianInformation: any = {};
  displayName='sectionA';
  healthInsurance:any;
  studentFirstName:any;
  Insurance:any;
  gender:any;
  blood:any;
  sleepIssue:any;
  nightsleep:any;
  wheredoesitcomefrom:any;
  Typeofmeal:any;
  Rhfactor:any;
  ethnicity:any;
  fatherethnicity:any;
  varioushouseholdchores:any;
  SelectedstatesId:any;
  digitalSignImageUrl: string | null = null;
  studentPhotoImageUrl: string | null = null;
  NotKnown:any;
  sectionaForm!:NgForm;
  sectioneForm!:NgForm;
  
  sectioncForm!:NgForm;
  sectiondForm!:NgForm;
  notelastdate:any;
  studentname:any;
  parentsname:any;
  typeclass:any;
  signature:any;
  noteplace:any;
 
  dateOfBirth = new FormControl();
  // sectionb-----------------------------------------------------------------------------------------------------------------
  mediAid:any;
    policy:any;
  expireDate:any;
  getallcountry:any;
// sectionC------------------------------------------------------------------------------------------------------------------------
  streetAddress:any;
  showMealsError: boolean = false;
  showsnacksError: boolean= false;
  showsfluidError: boolean= false;

  city:any;
  Countrys:any;
  postalCode:any;
  phNumber:any;
  motherPhNumber:any;
  fatherPhNumber:any;
  studentphNumber:any;
  emailShm:any;
  Apartment_Name:any;
  apartmentNumber:any;
  alternativeBelongsTo:any;
  areaC:any;
  useAge: any;
  states:any;
  Country:any;
  selectedCountrys:any;
  SelectedcityId:any;
  showDatePicker: boolean = false;
  selectedDate: NgbDateStruct | null = null;

// sectionD------------------------------------------------------------------------------------------------
  mobile_1:any;
  mobile1_belongs:any; 
  mobil1eothers:any;
  mobilebelong_Fn:any;
  mobilebelong_Ln:any;
  mobile_2:any;
  mobile2_belongs:any;
  mobil2eothers:any;
  mobile2belong_Ln:any;
  mobile2belong_Fn:any;
  familyDocName:any;
  docContactNumber:any;

  selectedActivities:string[] = [];
  selectedFood:string[]=[];
  date:any;
  ParentName: any;
  relationshipStudent:any;
  StudentName:any;
  Class:any;
  otherscame:any;
  Mother:any;
  Father:any;
  Guardian:any;
  Place:any;
  policies: any;
  studentsreactiontochangeothers:any;
 
 
  isOthersChecked:boolean=false;
  isOthersRelationshipChecked = false;
  isOthersReactionChecked = false;
  mealsPerDay: any;
  UsualNumberofSnacksPerDay: any;
  fluidIntake: any;
  mealType: any;
  IfYeswheredoesitcomefrom:any; 
  HawKersorstalls:any; 
  breakfastRegular: any;
 regularmealatschool:any;
 foodIntolerances: string[] = [];
 StudentFreshandRelaxedAfterniNghtsSleep:any;
  SleepRelatedIssues:any;
  AverageLengthOfSleepPerNight:any;
  napCycleDuringtheDay:any;
  mealTypeothers:any;
  IfYeswheredoesitcomefromothers:any;
  ethnicityothers:any;
  SocialPersonalityOfTheStudent:any;
  AnyIrrationalFearsOrPhobias:any;
  StudentSelfImageSelfWorth:any;
  studentsreactiontochange:any;
  relationshipwithotherstudents:any;
  significantlyclosefriends:any;
  girlsCount: any;
  boysCount: any;
  selectedNumber:any;
  selectedNumberrate:any;
  selectedNumberHealth:any;
  StudentGeneralOpinioofSchool:any;
  studentgoestoschool:any;
  RespectTotheStudent:any;
  studentPersonalInformationothers:any;
  SchoolPerformanceSignificantlyAffected:any;
  SchoolPerformanceSignificantlyDissatisfied:any;
  othertypeofmeal:any;
  otherMealatSchool:any;
  activities:Activity[] =[{ name:'Athletics', selected: false },
  {name: 'Cycling', selected: false},
  {name: 'Gym', selected: false},
  {name: 'Music', selected: false },
    {name: 'Online social networking', selected: false},
  {name: 'Painting/Sketching', selected: false},
  {name: 'Playing computer games', selected: false},
  {name: 'Reading books', selected: false},
  {name: 'Sports', selected: false},
  {name: 'Swimming', selected: false},
  { name: 'Watching TV', selected: false },
  {name: 'Yoga/Pilates', selected: false },
  { name : "Other", selected:false ,othersInput: ''},
];
Intolerants:Intolerant[] =[{ name:'Eggs', selected: false },
  { name:'Milk', selected: false ,othersIntolerant: ''},
  {name: 'Nuts', selected: false,othersIntolerant: ''},
  {name: 'Sea food', selected: false,othersIntolerant: ''},
  {name: 'Wheat/Gulten', selected: false,othersIntolerant: ''},
  { name : "Other", selected:false ,othersIntolerant: ''},
];
otherIntolerant: string = '';
wheatInput:any;
seaFoodInput:any;
NutsInput:any;
milkInput:any;
EggsInput:any;

sleeping:sleep[] =[
  {name: 'Awakens due to nightmares', selected: false},
  {name: 'Bed wetting', selected: false},
  {name: 'Coughs during sleep', selected: false},
  {name: 'Frequent trips to toilet', selected: false},  
  {name: 'Restless sleep', selected: false },
  {name: 'Sleep breathlessness', selected: false},
  {name: 'Snoring', selected: false},
  {name: 'Takes long to fall asleep', selected: false},
  {name: 'Talks in sleep', selected: false},
  {name:'Unable to fall asleep', selected: false },
  {name: 'Walks in sleep', selected: false },
  {name : 'Other', selected:false ,otherssleep: ''},
];
  // useAge: any;
  selectedActivitiesString: any;
  studentPersonalreactioInformationothers: string | undefined;
  studentPersonalityInformationothers: any;

 



showOthersInput: boolean = false; 
othersValue: string = ''; 
sports:any;
sportsPlay='';
pets: string = 'No';
petsAnimal='';
petsAnimalDuraution='';
petsArray: { animal: string, duration: string }[] = [];
showPreviousButton = false;
IfYeswhereothers:any;
relationshipStudentothers:any;
CountryList: any;
statesList:any;
citylist:any;


// section j--------------------------------------------------------------------------------------------------------
respected:Respect[]=[{name: "Behaviour", selected: false},
{name: 'Development', selected: false},
{name: 'Friends', selected: false},
{name: 'General health', selected: false},
{name: 'Growth', selected: false},
{name: 'Habits', selected: false},
{name: 'No significant concerns', selected: false},
{name: 'School performance', selected: false},
{ name: 'Social activity', selected: false },
]
sliderValue:number=0;
sliderScaleValues: number[] = [0,1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
performancing:Performance[]=[{name: "Addiction", selected: false},
{name: 'Domestic discord', selected: false},
{name: 'Emotional difficulties', selected: false},
{name: 'Learning disabilities', selected: false},
{name: 'Physical health', selected: false},
{name: 'Separation/Divorce of parents', selected: false},
{name: 'Social activity', selected: false},
]

dissatisified:Disatisfy[]=[{name: 'Ability to communicate', selected: false},
{name: 'Appearance', selected: false},
{name: 'Family life', selected: false},
{name: 'Friendships', selected: false},
{name: 'Physical activities', selected: false},
{name: 'School performance', selected: false},
{name: 'Social status', selected: false,},

{name:'Other',selected:false,othersDissatification:''}

]
//section k --------------------------------------------------------------------------------------
illness:Ill[]=[{ name:'Acidity/GI ulcers', selected: false },
{name: "Alzheimer's disease", selected: false},
{name: 'Anemia', selected: false},
{name: 'Arthritis', selected: false},
{name: 'Bronchial asthma', selected: false},
{name: 'Bronchitis', selected: false},
{name: 'Cancer', selected: false},
{name: 'Chronic heart disease', selected: false},
{name: 'Color blindness', selected: false},
{ name: 'Depression', selected: false },
{ name: 'Diabetes', selected: false },
{name: 'Epilepsy', selected: false },
{ name : "Hair loss", selected:false },
{ name: 'Hemophilia', selected: false },
{ name: 'Hepatitis B/C', selected: false },
{ name: 'HIV', selected: false },

{name: 'Hypertension', selected: false },
{ name : "Mental illness", selected:false },
{ name: 'Obesity', selected: false },
{ name: 'Osteoporosis', selected: false },
{name: 'Smoking', selected: false },
{ name : "Stroke", selected:false },
{ name: 'Thalassemia', selected: false },
{ name: 'Tuberculosis', selected: false },
{name: 'Varicose veins', selected: false },
{ name : "Other", selected:false ,othersIllness: ''},
];

// section l-------------------------------------------------------------------------------
medicalproblem:Medical[]=[
  {name: "Acne vulgaris", selected: false},
  {name: 'Anemia', selected: false},
  {name: 'Appendicitis', selected: false},
  {name: 'Asthma', selected: false},
  {name: 'Attention/Learning disorders', selected: false},
  {name: 'Autism', selected: false},
  {name: 'Bleeding problems', selected: false},
  {name: 'Cancer', selected: false},
  { name: 'Cerebral palasy', selected: false },
  { name: 'Chicken pox', selected: false },
  {name: 'Cystic fibrosis', selected: false },
  { name : "Dental problems", selected:false },
  { name: 'Ear infections', selected: false },
  { name: 'Epilepsy/Seizure/Convulsions', selected: false },
  {name: 'G6 PD deficiency', selected: false },
  { name : "German measles", selected:false },
  { name: 'Heart problems', selected: false },
  { name: 'Hearing problems', selected: false },
  {name: 'Hernia', selected: false },
  { name : "High blood pressure", selected:false },
  { name: 'HIV infection', selected: false },
  { name: 'Juvenile diabetes', selected: false },
  {name: 'Kidney problems', selected: false },

  {name: "Learning disabilities", selected: false},
  {name: 'Measles', selected: false},
  {name: 'Meningitis', selected: false},
  {name: 'Mental illness', selected: false},
  {name: 'Mumps', selected: false},
  {name: 'Obesity', selected: false},
  {name: 'Orthopaedic problems', selected: false},
  {name: 'Poison ingestion', selected: false},
  { name: 'Poor control over urination', selected: false },
  { name: 'Pneumonia', selected: false },
  {name: 'Prematurity', selected: false },
  { name : "Rheumatic fever", selected:false },
  { name: 'Sexually transmitted infection', selected: false },
  { name: 'Sickle cell anemia', selected: false },
  {name: 'Skin problems', selected: false },
  { name : "Speech/Language problems", selected:false },
  { name: 'Thalassemia', selected: false },
  { name: 'Thyroid dysfunction', selected: false },
  {name: 'Tonsilitis/Adenoiditis', selected: false },
  { name : "Tuberculosis", selected:false },
  { name: 'Urinary tract infection', selected: false },
  { name: 'Unconsciousness', selected: false },
  {name: 'Vision problems', selected: false },
  {name: 'Vitamin deficiency', selected: false },

  
]
currentproblem:Current[]=[
  {name: "Acne vulgaris", selected: false},
  {name: 'Anemia', selected: false},
  {name: 'Appendicitis', selected: false},
  {name: 'Asthma', selected: false},
  {name: 'Attention/Learning disorders', selected: false},
  {name: 'Autism', selected: false},
  {name: 'Bleeding problems', selected: false},
  {name: 'Cancer', selected: false},
  { name: 'Cerebral palasy', selected: false },
  { name: 'Chicken pox', selected: false },
  {name: 'Cystic fibrosis', selected: false },
  { name : "Dental problems", selected:false },
  { name: 'Ear infections', selected: false },
  { name: 'Epilepsy/Seizure/Convulsions', selected: false },
  {name: 'G6 PD deficiency', selected: false },
  { name : "German measles", selected:false },
  { name: 'Heart problems', selected: false },
  { name: 'Hearing problems', selected: false },
  {name: 'Hernia', selected: false },
  { name : "High blood pressure", selected:false },
  { name: 'HIV infection', selected: false },
  { name: 'Juvenile diabetes', selected: false },
  {name: 'Kidney problems', selected: false },

  {name: "Learning disabilities", selected: false},
  {name: 'Measles', selected: false},
  {name: 'Meningitis', selected: false},
  {name: 'Mental illness', selected: false},
  {name: 'Mumps', selected: false},
  {name: 'Obesity', selected: false},
  {name: 'Orthopaedic problems', selected: false},
  {name: 'Poison ingestion', selected: false},
  { name: 'Poor control over urination', selected: false },
  { name: 'Pneumonia', selected: false },
  {name: 'Prematurity', selected: false },
  { name : "Rheumatic fever", selected:false },
  { name: 'Sexually transmitted infection', selected: false },
  { name: 'Sickle cell anemia', selected: false },
  {name: 'Skin problems', selected: false },
  { name : "Speech/Language problems", selected:false },
  { name: 'Thalassemia', selected: false },
  { name: 'Thyroid dysfunction', selected: false },
  {name: 'Tonsilitis/Adenoiditis', selected: false },
  { name : "Tuberculosis", selected:false },
  { name: 'Urinary tract infection', selected: false },
  { name: 'Unconsciousness', selected: false },
  {name: 'Vision problems', selected: false },
  {name: 'Vitamin deficiency', selected: false },

  
]
medicalIssue='';
currentMedication="";
pastMedication='';
medicalIssueCurrently:any;
medications: string[] = [];
// pastmedications: string[] = [];
napdurations:string[] = [];


// cycling: string[]=[];

allergies:string='';
// allergy1:string[]=[];
physicalhealth:any;
allergys:any;
napTimes: { napLabel: string }[] = [{ napLabel: '' }];
pastmedications:{pastMedLabel:string}[]=[{pastMedLabel:''}];
currentMedications:{currentMedLabel:string}[]=[{currentMedLabel:''}];
allergiesList:{allergyLabel:string}[]=[{allergyLabel:''}];
cyclingduration='';
// section m------------------------------------------------------------------------------------------

bcg0:any;
bcg1:any;
bcg2:any;
bcg3:any;
bcg4:any;

chickenPox0:any;
chickenPox1:any;
chickenPox2:any;
chickenPox3:any;
chickenPox4:any;

cholera0:any;
cholera1:any;
cholera2:any;
cholera3:any;
cholera4:any;

covid0:any;
covid1:any;
covid2:any;
covid3:any;
covid4:any;

dpt0:any;
dpt1:any;
dpt2:any;
dpt3:any;
dpt4:any;

dt0:any;
dt1:any;
dt2:any;
dt3:any;
dt4:any;

hepatitis0:any;
hepatitis1:any;
hepatitis2:any;
hepatitis3:any;
hepatitis4:any;

hepatitisb0:any;
hepatitisb1:any;
hepatitisb2:any;
hepatitisb3:any;
hepatitisb4:any;

hpv0:any;
hpv1:any;
hpv2:any;
hpv3:any;
hpv4:any;

influenza0:any;
influenza1:any;
influenza2:any;
influenza3:any;
influenza4:any;

influenzaV0:any;
influenzaV1:any;
influenzaV2:any;
influenzaV3:any;
influenzaV4:any;

mmr0:any;
mmr1:any;
mmr2:any;
mmr3:any;
mmr4:any;

mumps0:any;
mumps1:any;
mumps2:any;
mumps3:any;
mumps4:any;

oralP0:any;
oralP1:any;
oralP2:any;
oralP3:any;
oralP4:any;

rotaVirus0:any;
rotaVirus1:any;
rotaVirus2:any;
rotaVirus3:any;
rotaVirus4:any;

teatnus0:any;
teatnus1:any;
teatnus2:any;
teatnus3:any;
teatnus4:any;

typhoid0:any;
typhoid1:any;
typhoid2:any;
typhoid3:any;
typhoid4:any;

yellow0:any;
yellow1:any;
yellow2:any;
yellow3:any;
yellow4:any;


COVID_Dose0:any;
COVID_Dose1:any;
COVID_Dose2:any;
COVID_Dose3:any;
COVID_Dose4:any;

// Add this line to define the othersValue property
  @ViewChild('sidenav') sidenav!: MatSidenav;
  // @ViewChild('signatureCanvas', { static: true }) signatureCanvas!: ElementRef;
  // private ctx!: CanvasRenderingContext2D;
  // private drawing: boolean = false;

  
  mothersFirstName: any;
  mothersLastName: any;
  fathersFirstName: any;
  fathersLastName: any;
  message: any;
  fatherOthersInput: any;
  motherOthersInput: any;
  motherMiddleName1:any;
  mothersMiddleName2:any;
  fatherMiddleName2:any;
  fatherMiddleName1:any;

  imageUrl: string | ArrayBuffer | null = null;
  CountryIds: any;
  statesListId:any;
  selectedCountry:any;
  SelectedCountryId: any;
  statesIds: any;
  statesId: any;
  citysId: any;
  userDOB: string = '';
  Student_First_Name: any;
  getInfoseekMasterId: any;
  InfoseekMasterId: string | null;
  usualMeals: any;
  snacks: any;
  typeofmeal: any;
  typeofmealothers: any;
  mealatschool: any;
  whereitcome: any;
  studentothers: any;
  foodgroup: any;
  foodgroupothers: any;
  breakfast: any;
  following_information_providedby: any;
  invalidDetailsMessage: any;
  invalidDetailssectionc: any;
  invalidDetailssectiond: any;
  errorsecA: any;
  errorsecC: any;
  errorsecD: any;
  profileimage: any;
  selectedUserProfile:any | null = 'null';
  studentPhoto:any | null = 'null';

  fileTypeError: boolean = false;
  selectedImage: any;
picker:any;
  // dateofBirth: any;
  intolerant_Others: any;
  minDate: string;
  maxDate: string;
  notedates:any;
  notedate:any;
  showOtherInput: boolean=false;
  infoseekId: any;
myForm: any;
personalityOthers: any;
  showillWarning: boolean=false;
  showsleepWarning: boolean=false;
  showDisatisfyWarning: boolean=false;
  showIntolerantWarning: boolean=false;
  upload_photo: any;
  studentPhotofilename: string | undefined;
  errorupload: any;
  fullImagePath: any;
  picerror: any;



  
  // ngAfterViewInit() {
  //   this.ctx = this.signatureCanvas.nativeElement.getContext('2d');
  //   this.ctx.lineWidth = 2;
  //   this.ctx.strokeStyle = '#000';
  // }

  // onMouseDown(event: MouseEvent) {
  //   this.drawing = true;
  //   this.ctx.beginPath();
  //   this.ctx.moveTo(event.clientX - this.signatureCanvas.nativeElement.getBoundingClientRect().left, event.clientY - this.signatureCanvas.nativeElement.getBoundingClientRect().top);
  // }

  // onMouseMove(event: MouseEvent) {
  //   if (!this.drawing) return;
  //   this.ctx.lineTo(event.clientX - this.signatureCanvas.nativeElement.getBoundingClientRect().left, event.clientY - this.signatureCanvas.nativeElement.getBoundingClientRect().top);
  //   this.ctx.stroke();
  // }

  // onMouseUp() {
  //   this.drawing = false;
  // }

  // onMouseLeave() {
  //   this.drawing = false;
  // }

  // clearSignature() {
  //   this.ctx.clearRect(0, 0, this.signatureCanvas.nativeElement.width, this.signatureCanvas.nativeElement.height);
  // }



  constructor( private nivishservice:NivishService,private routes: Router,http:HttpClient) {
    this.selectedActivities = [];
    this.InfoseekMasterId = localStorage.getItem('InfoseekMasterId');
    this.dateofBirth = '';
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    this.notedate = `${day}-${month}-${year}`;
    this.minDate = this.notedate; // To restrict to the current date or later
    this.maxDate = this.notedate;

    

    const nextWeek = new Date(today);
    nextWeek.setDate(today.getDate() + 7);
    
    const yearToday = today.getFullYear();
    const monthToday = (today.getMonth() + 1).toString().padStart(2, '0');
    const dayToday = today.getDate().toString().padStart(2, '0');
    this.notedates = `${dayToday}-${monthToday}-${yearToday}`;
    
    const yearNextWeek = nextWeek.getFullYear();
    const monthNextWeek = (nextWeek.getMonth() + 1).toString().padStart(2, '0');
    const dayNextWeek = nextWeek.getDate().toString().padStart(2, '0');
    this.notedates = `${dayNextWeek}-${monthNextWeek}-${yearNextWeek}`;
    
    this.minDate = today.toISOString().split('T')[0]; // To restrict to the current date or later
    this.maxDate = this.notedates;
  }
  
  openSidebar() {
    this.sidenav.open();
  }
  toggleInput(isOthers: boolean) {
    this.isOthersChecked = isOthers;
    this.isOthersRelationshipChecked = !this.isOthersRelationshipChecked;
    this.isOthersReactionChecked = !this.isOthersReactionChecked;
  }
  // Close the sidebar
  closeSidebar() {
    this.sidenav.close();
  }
  openDatePicker() {
    this.showDatePicker = true;
  }
  formatDate(date: NgbDateStruct): string {
    if (date) {
      const day = date.day.toString().padStart(2, '0');
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const month = monthNames[date.month - 1];
      const year = date.year;
      return `${day}-${month}-${year}`;
    }
    return '';
  }
  onSubmit() {
    // TODO: Submit the form data to a backend server
  }
  sectionCompletion = {
    sectionA: false,
    sectionB: false,
    sectionC: false,
    sectionD: false,
    sectionE: false,
    sectionF: false,
    sectionG: false,
    sectionH: false,
    sectionI: false,
    sectionJ: false,
    sectionK: false,
    sectionL: false,
    sectionM: false,
    Consent:false,


    // Add other sections here and initialize them to false
  };
  
  sectionA()
  {
    this.displayName='sectionA';
    this.getInfoiseekMasterId();
    this.sectionCompletion.sectionA = true;
  }
  
  
  sectionB(){
    this.displayName='sectionB';
    this.getInfoiseekMasterId();
    this.sectionCompletion.sectionB = true;


  }
  sectionC(){
    this.displayName='sectionC';
    this.getInfoiseekMasterId();
    this.sectionCompletion.sectionC = true;

    // this.displayName='sectionE';
  }
  sectionD(){
    this.displayName='sectionD';
    this.getInfoiseekMasterId();
    this.sectionCompletion.sectionD = true;

  }
  sectionE(){
    this.displayName='sectionE';
    this.getInfoiseekMasterId();
    this.sectionCompletion.sectionE = true;


  }
  sectionF(){
    this.displayName='sectionF';
    this.getInfoiseekMasterId();
    this.sectionCompletion.sectionF = true;

  }
  sectionG(){
    this.displayName='sectionG';
    this.getInfoiseekMasterId();
    this.sectionCompletion.sectionG = true;

  }
 
 
  sectionJ(){
  this.displayName='sectionJ';
  this.getInfoiseekMasterId();
  this.sectionCompletion.sectionJ = true;

  }
  sectionH(){
    this.displayName='sectionH';
    this.getInfoiseekMasterId();
    this.sectionCompletion.sectionH = true;

    }
    sectionI(){
      this.displayName='sectionI';
      this.getInfoiseekMasterId();
      this.sectionCompletion.sectionI = true;

      
      }
  Note(){
    this.displayName='Note';
  }
  isValidNonNegativeInteger(value: any): boolean {
    // Check if the value is a non-negative integer
    const intValue = parseInt(value, 10);
    return !isNaN(intValue) && isFinite(intValue) && intValue >= 0;
  }

  validateInput(event: KeyboardEvent): void {
    // Allow only digits
    const allowedCharacters = /^[0-9]*$/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!allowedCharacters.test(inputChar)) {
      event.preventDefault();
    }
  }
  isValidMobileNumber(number: string): boolean {
    // Add your validation logic here
    // For example, you can check if it contains only digits and has a length up to 15
    const regex = /^\d{1,15}$/;
    return regex.test(number);
  }
  toggleInputSports() {
   
    if (this.sports === 'Yes') {
      this.sportsPlay = ''; 
    }
  }
  addPet() {
    this.petsArray.push({ animal: '', duration: '' });
  }
  trackByFn(index: number, item: any) {
    return index; // or unique identifier if available
  }
  
  
  showNewInput = false;
  maxPetsToShow = 5;
  maxRange: number = 10; 

  numbers: number[] = [];

  // constructor() { }

  submit() {
    this.routes.navigate(['/infoseek']);
  }

  private generateNumbers(): void {
    for (let i = 0; i <= this.maxRange; i++) {
      this.numbers.push(i);
    }
  }
  removePet(index: number) {
    this.petsArray.splice(index, 1);
  }
  toggleOthersInput(activity: { name: string; selected: boolean; othersInput?: string }) {
   
  }

  //note sections
  
// section j-------------------------------------------------------------------------------------------
formatLabel(value: number): string {
  if (value >= 11) {
    return Math.round(value / 10) + '';
  }

  return `${value}`;
}  
setValue(value: number) {
  this.sliderValue = value;
}
 
onOthersCheckboxChange(ill: any) {
  if (ill.selected && ill.name === 'Others') {
    // Show the input field for specifying the illness
    ill.showInput = true;
  } else {
    // Hide the input field if the checkbox is unchecked
    ill.showInput = false;
  }
}

  
dateofBirth: string = '2022-01-01';
onDateOfBirthChange(newDate: string) {

  
 
  this.dateofBirth = newDate;
  this.calculateAge();
}

calculateAge() {
  if (!this.dateofBirth) {
    return '';
  }

  const birthDate = new Date(this.dateofBirth);
  const currentDate = new Date();
  const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();

  if (
    currentDate.getMonth() < birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate())
  ) {
    return (ageDiff - 1).toString();
  } else {
    return ageDiff.toString();
  }
}



  // section k ------------------------------------------------------------------------------------
  sectionK(){
    this.displayName='sectionK';
    this.sectionCompletion.sectionK = true;
  }

  // sectionL---------------------------------------------------------------------------------------------------------
  sectionl(){
    this.displayName='sectionL';
    this.sectionCompletion.sectionL = true;


  }
  // addMedicationInput() {
  //   this.medications.push('');
  // }
  // addPastMedicationInput() {
  //   this.pastmedications.push('');
  // }
 
 
  getFormattedMaxDate(): string {
    const currentDate = new Date();
    const formattedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
    return formattedDate.toISOString().split('T')[0];
  }

  removeMedicationInput(index: number) {
    this.medications.splice(index, 1);
  }
  removePastMedicationInput(index: number) {
    this.pastmedications.splice(index, 1);
  }
 
 
  // addallergiesInput(){
  //   this.allergy1.push('')
  // }
  // removeallergiesInput(index: number){
  //   this.allergy1.splice(index, 1);
  // }
  addFirstPetInput() {
    if (this.pets === 'Yes' && this.petsArray.length === 0) {
      this.addPet();
    }
  }
  // section m -----------------------------------------------------------------------------------------------------------

  sectionM(){
    this.displayName='sectionM';
    this.sectionCompletion.sectionM = true;

  }
  setInitialDate() {
    const today = new Date();
    const futureDate = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000); // 7 days from now
    const year = futureDate.getFullYear();
    const month = (futureDate.getMonth() + 1).toString().padStart(2, '0');
    const day = futureDate.getDate().toString().padStart(2, '0');
    this.notelastdate = `${year}-${month}-${day}`;
  }
  
  Consent(){
    this.sectionCompletion.Consent = true;

    this.displayName='Consent';
  }
  // thank you -----------------------------------------------------------------------------------------------------------------
 
  thankYou(){
    this.displayName='thankYou';
  }
  ngOnInit(){
    const student = localStorage.getItem('details');

    this.fetchCountry()
   
    this.sectionA()
    this.dateofBirth = this.dateofBirth;
    this.calculateAge();
   
    this.getInfoiseekMasterId();
   
    this.calculateAge();
    
   
  
  }
  
  
toggleMealOptions() {
  if (this.regularmealatschool !== 'Yes') {
      // If the student does not have regular meals at school, hide the options
      this.wheredoesitcomefrom = ''; // Reset the value if needed
  }
}

  // -----------------------------------------------------------------------------------------------------------------
    sectiona(form: NgForm) {
      this.sectionaForm = form;
      
      const motherfn = this.mothersFirstName;
      sessionStorage.setItem('mothersFirstName', motherfn);
     
      const fatherfn = this.fathersFirstName;

      sessionStorage.setItem("fathersFirstName",fatherfn);


      if (form.invalid) {
        this.errorsecA = 'Please fill in all the required details';
        Object.keys(form.controls).forEach(key => {
          form.controls[key].markAsTouched();
        });
      } else {
        this.errorsecA = ''; 
      }
    
      if (form.valid) {
        this.savesectiona(form.value);
      }
    }
    savesectiona(sectionadata:any){
      const userId = localStorage.getItem('userId');
    
    if (!userId) {
      console.error('userId not found in local storage.');
      return;
    }

    

    const updA={
      following_information_providedby:this.studentPersonalInformation || "",
      If_Other:this.studentPersonalInformationothers || "",
      Student_FirstName:this.studentFirstName || "",

      Student_MiddleName1:this.studentMiddleName1 ,
      Student_MiddleName2:this.studentMiddleName2 ,
      Student_LastName:this.studentLastName || "",
      Gender:this.gender || "", 
      Student_DOB:this.dateofBirth || "",

      BloodGroup:this.blood || "",
      Rh_Factor:this.Rhfactor || "",
      Number_Of_Sisters: this.noofSisters ,
      Number_Of_Brothers: this.noOfBrothers ,
      Mothers_FirstName:this.mothersFirstName || "",
      Mothers_MiddleName1:this.motherMiddleName1,
      Mothers_MiddleName2:this.mothersMiddleName2,
      Mothers_LastName:this.mothersLastName || "",
      Mothers_Ethnicity:this.ethnicity || "",
      Mothers_Ethnicity_If_Other:this.motherOthersInput || "",
      Fathers_FirstName:this.fathersFirstName || "" ,
      Fathers_MiddleName1:this.fatherMiddleName1,
      Fathers_MiddleName2:this.fatherMiddleName2,
      Fathers_LastName:this.fathersLastName || "",
      Fathers_Ethnicity:this.fatherethnicity || "",
      Fathers_Ethnicity_If_Other:this.fatherOthersInput || "",
    }

  
  
    
    
    if (this.studentMiddleName1 !== null) {
      updA.Student_MiddleName1 = this.studentMiddleName1;
    }
    
    if (this.studentMiddleName2 !== null) {
      updA.Student_MiddleName2 = this.studentMiddleName2;
    }
    
    if (this.noofSisters !== null) {
      updA.Number_Of_Sisters = this.noofSisters;
    }
    
    if (this.noOfBrothers !== null) {
      updA.Number_Of_Brothers = this.noOfBrothers;
    }
    if (this.motherMiddleName1 !== null) {
      updA.Mothers_MiddleName1 = this.motherMiddleName1;
    }
    
    if (this.mothersMiddleName2 !== null) {
      updA.Mothers_MiddleName2 = this.mothersMiddleName2;
    }
    if (this.fatherMiddleName1 !== null) {
      updA.Fathers_MiddleName1 = this.fatherMiddleName1;
    }
    
    if (this.fatherMiddleName2 !== null) {
      updA.Fathers_MiddleName2 = this.fatherMiddleName2;
    }
    
    console.log(updA);
    // updA['id'] = userId;
    this.nivishservice.updateUserRegA(userId,updA).subscribe(
      (data:any)=>{
      if(data['Status'] === 200) {
        
        this.message='updated data';
        this.studentpicUpd();
        
      }
   
      }
      )
  }

  studentpicUpd() {
    console.log("student pic upload");
    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.log('userId not found in local storage.');
      return;
    }
  
    if (this.studentPhoto === 'null' || this.studentPhoto === undefined) {
      console.log("Image not provided, using filename:", this.studentPhotofilename);
    
      if (!this.studentPhotofilename || this.studentPhotofilename === '') {
        this.picerror = 'Pls choose the Image';
      } else {
        // Redirect to sectionB
        this.displayName = 'sectionB';
        this.sectionB();
      }
    }else {
      // Image is provided
      const digitalSignFormData = new FormData();
      digitalSignFormData.append('InfoseekId', userId);
      digitalSignFormData.append('upload_photo', this.studentPhoto);
  
      console.log(digitalSignFormData, "narayansss", this.studentPhoto);
  
      this.nivishservice.updateNote(userId, digitalSignFormData).subscribe(
        (data: any) => {
          if (data['Status'] === 200) {
            this.message = 'note';
            this.displayName = 'sectionB';
            this.sectionB();
          } else {
            console.error('Error updating note:', data);
            if (data['HasError'] && data['Message']) {
              console.error('Server error message:', data['Message']);
            }
          }
          console.log(this.message, 'mmmmmmmmm');
          console.log(data, 'dddddddddddd');
        },
        (error: any) => {
          console.error('Error updating note:', error);
          if (error.status === 400) {
            console.error('Record not found. The specified ID might be incorrect.');
          }
          // Handle the error here (e.g., show an error message to the user)
        }
      );
    }
  }
  
  // studentpicUpd() {
  //   console.log("student pic upload");
  //   const userId = localStorage.getItem('userId');
  //   if (!userId) {
  //     console.log('userId not found in local storage.');
  //     return;
  //   }
  
  //   const digitalSignFormData = new FormData();
  //   digitalSignFormData.append('InfoseekId', userId);
  
  //   // **Image handling:**
  //   if (this.studentPhoto === null) {
  //     console.log("Image not provided, using filename:", this.studentPhotofilename);
  
  //     if (this.studentPhotofilename) {
  //       // Update image display if filename is available
  //       this.studentPhotoImageUrl = this.getImageUrl(this.studentPhotofilename); // Assuming you have a function to construct image URLs
  //       this.studentPhoto = null; // Clear studentPhoto to avoid conflicts
  
  //       digitalSignFormData.append('upload_photo', this.studentPhotofilename);
  //     } else {
  //       console.log("photoname", this.studentPhotofilename);
  //       this.errorupload = 'Please choose an image';
  //       return;
  //     }
  //   } else {
  //     digitalSignFormData.append('upload_photo', this.studentPhoto);
  
  //     this.nivishservice.updateNote(userId, digitalSignFormData).subscribe(
  //             (data: any) => {
  //               if (data['Status'] === 200) {
  //                 this.message = 'note';
  //                 this.displayName = 'sectionB';
  //                 this.sectionB();
  //               } else {
  //                 console.error('Error updating note:', data);
  //                 if (data['HasError'] && data['Message']) {
  //                   console.error('Server error message:', data['Message']);
  //                 }
  //               }
  //               console.log(this.message, 'mmmmmmmmm');
  //               console.log(data, 'dddddddddddd');
  //             },
  //             (error: any) => {
  //               console.error('Error updating note:', error);
  //               if (error.status === 400) {
  //                 console.error('Record not found. The specified ID might be incorrect.');
  //               }
  //               // Handle the error here (e.g., show an error message to the user)
  //             }
  //           );
  //         }
  //   }
  
  //   getImageUrl(filename: string): string {
  //     // Logic to construct the image URL based on the filename
  //     // For example, if images are stored in an 'assets/images' folder:
  //     return `assets/images/${filename}`;
  //   }
  sectionb() {
    const userId = localStorage.getItem('userId');

    if (!userId) {
      console.error('userId not found in local storage.');
      return;
    }
    console.log('Insurance:', this.Insurance);
  
 
    console.log('aidMedical:', this.mediAid);
      console.log('policy:', this.policy);
      console.log('expireDate:', this.expireDate);
      let secb = null;

  if (this.Insurance === 'Yes') {
    secb = {
      Do_you_have_health_insurance: this.Insurance,
      Medical_Aid: this.mediAid,
      Policy_Card: this.policy,
      Expiry_Date: this.expireDate,
    };
  } else {
    secb = {
      Do_you_have_health_insurance: this.Insurance,
      Medical_Aid: null,
      Policy_Card: null,
      Expiry_Date: null,
    };
  }
      console.log(secb);
  
      this.nivishservice.updateUserRegB(userId,secb).subscribe(
        (data: any) => {
          if (data['Status'] === 200) {
            this.message = 'Updated B';
            this.sectionC();
          }
          console.log(this.message);
        }
      );
     
  }
  sectionc(form: NgForm) {
    this.sectioncForm = form;
    

    if (form.invalid) {
      this.errorsecC = 'Please fill in all the required details';
      Object.keys(form.controls).forEach(key => {
        form.controls[key].markAsTouched();
      });
    } else {
      this.errorsecC = ''; 
    }
  
    if (form.valid) {
      this.savesectionc(form.value);
    }
  }
  savesectionc(sectiondata:any){
    const userId = localStorage.getItem('userId');

    if (!userId) {
      console.error('userId not found in local storage.');
      return;
    }
    
    // const emailPattern = /^[a-zA-Z0-9._-]+@gmail\.com$/;
    // console.log('emailShm value:', this.emailShm);
    // const isEmailValid = emailPattern.test(this.emailShm);
    const phoneNumberPattern = /^[0-9]+$/;
    // const isPhoneNumberValid = phoneNumberPattern.test(this.motherPhNumber);

    // console.log('isEmailValid:', isEmailValid);

    interface secC{
      Email_Registered_With_School:string,
      Building_Name:string,
      Apartment_Villa_No:string,
      Street_No:string,
      Area:string,
      City:string,
      State:string,
      Country:string,
      Zip_Code:string,
      Emergency_MobileNumber_Registered_with_school:string,
      Alternate_MobileNumber:string,
      Belongs_To:string,
      
    }

    let secC: secC;
    if (this.motherPhNumber) {
     secC = {
      Building_Name: this.Apartment_Name || "",
      Apartment_Villa_No: this.apartmentNumber || "",
      Street_No: this.streetAddress || "",
      Area: this.areaC || "",
      City: this.SelectedcityId || "",
      State: this.SelectedstatesId || "",
      Country: this.SelectedCountryId || "",
      Zip_Code: this.postalCode || "",
      Emergency_MobileNumber_Registered_with_school: this.phNumber && phoneNumberPattern.test(this.phNumber) ? this.phNumber : "",
      Alternate_MobileNumber: this.motherPhNumber && phoneNumberPattern.test(this.motherPhNumber) ? this.motherPhNumber : "",
      Belongs_To: this.alternativeBelongsTo,
      Email_Registered_With_School: this.emailShm ||"kiran@gmail.com",
    };
  }else{
     secC = {
      Building_Name: this.Apartment_Name || "",
      Apartment_Villa_No: this.apartmentNumber || "",
      Street_No: this.streetAddress || "",
      Area: this.areaC || "",
      City: this.SelectedcityId || "",
      State: this.SelectedstatesId || "",
      Country: this.SelectedCountryId || "",
      Zip_Code: this.postalCode || "",
      Emergency_MobileNumber_Registered_with_school: this.phNumber && phoneNumberPattern.test(this.phNumber) ? this.phNumber : "",
      Alternate_MobileNumber: this.motherPhNumber && phoneNumberPattern.test(this.motherPhNumber) ? this.motherPhNumber : "",
      Belongs_To: '',
      Email_Registered_With_School: this.emailShm ||"kiran@gmail.com",
  }
}
    
    console.log('secC:', secC.Email_Registered_With_School,this.emailShm,secC);
    this.nivishservice.updateUserRegC(userId,secC).subscribe(
      (data:any)=>{
        if(data['Status']===200){
          this.message="updated C";
          this.sectionD();
        }
        console.log(this.message);
      }
      )
  }

  sectiond(form: NgForm) {
    this.sectiondForm = form;
  
    if (form.invalid) {
      this.errorsecD = 'Please fill in all the required details';
      Object.keys(form.controls).forEach(key => {
        form.controls[key].markAsTouched();
      });
    } else {
      this.errorsecD = '';
    }
    if (form.valid) {
      this.savesectiond(form.value);
    }
  }
  

  
  
  savesectiond(formdata:any){
    const userId = localStorage.getItem('userId');

    if (!userId) {
      console.error('userId not found in local storage.');
      return;
    }

    interface secd{
      Primary_Contact:String,
      Primary_Contact_Belongs_To:string,
      Primary_Contact_Belongs_To_Other:string,
      Primary_Contact_Full_Name:string,
      Secondary_Contact:string,
      Secondary_Contact_Belongs_To:string,
      Secondary_Contact_Belongs_To_Other:string,
      Secondary_Contact_Full_Name:string,
      Family_Doctor_Name:string,
      Doctor_Contact_Number:string,



    }
    const phoneNumberPattern = /^[0-9]+$/;
    let secd: secd;

    if (this.mobile_2) {
      secd = {
        Primary_Contact: this.mobile_1 || "",
        Primary_Contact_Belongs_To: this.mobile1_belongs || "",
        Primary_Contact_Belongs_To_Other: this.mobil1eothers || "",
        Primary_Contact_Full_Name: this.mobilebelong_Fn || "",
        Secondary_Contact: this.mobile_2 || "",
        Secondary_Contact_Belongs_To: this.mobile2_belongs,
        Secondary_Contact_Belongs_To_Other: this.mobil2eothers,
        Secondary_Contact_Full_Name: this.mobile2belong_Fn,
        Family_Doctor_Name: this.familyDocName || "",
        Doctor_Contact_Number: phoneNumberPattern.test(this.docContactNumber) ? this.docContactNumber : "",
      };
    } else {
      secd = {
        Primary_Contact: this.mobile_1 || "",
        Primary_Contact_Belongs_To: this.mobile1_belongs || "",
        Primary_Contact_Belongs_To_Other: this.mobil1eothers || "",
        Primary_Contact_Full_Name: this.mobilebelong_Fn || "",
        Secondary_Contact: this.mobile_2 || null,
        Secondary_Contact_Belongs_To: '', 
        Secondary_Contact_Belongs_To_Other: '', 
        Secondary_Contact_Full_Name: "",
        Family_Doctor_Name: this.familyDocName || "",
        Doctor_Contact_Number: phoneNumberPattern.test(this.docContactNumber) ? this.docContactNumber : "",
      };
    }

   if (!this.mobile_1 || !this.mobile1_belongs || !this.mobilebelong_Fn || !this.mobile2belong_Fn || !this.familyDocName || !this.docContactNumber   ) {
    this.invalidDetailssectiond = "Please fill in all mandatory fields.";
  } else {
    this.  invalidDetailssectiond= null; // Clear the error message
  }

   console.log(secd);
   this.nivishservice.updateUserRegD(userId,secd).subscribe(
    (data:any)=>{
      if(data['Status']===200){
      this.message="updated d";
      this.sectionE();
    }
    console.log(this.message,data);
    }
    
    )
  }
  sectione() {
    const userId = localStorage.getItem('userId');

if (!userId) {
  console.error('userId not found in local storage.');
  return;
}
const selectedActivities = this.activities
  .filter((activity) => activity.selected)
  .map((activity) => activity.name);
  this.nextButtonClicked = true;

const activities_others: string[] = [];

for (const activity of this.activities) {
  if (activity.selected && activity.name === 'Other' && activity.othersInput) {
    activities_others.push(activity.othersInput);
  }
}
const hasEmptyOther = this.activities.some(activity => activity.selected && activity.name === 'Other' && !activity.othersInput);
 if (hasEmptyOther) {
      this.showOtherWarning = true;
      return;
    }

    // Reset warning if there are no empty 'Other' inputs
    this.showOtherWarning = false;
interface SecE {
  What_typeof_recreational_activity_doesthestudentenjoy?: string | null;
  Which_ofthe_following_activities_doesthestudentenjoy?: string | null;
  Activites_Other?: string | null;
  Isthe_student_memberofasports_teamatschool?: string | null;
  Isthe_student_memberofasports_teamatschool_Yes?: string | null;
  Do_you_have_pets_at_home?: string | null;
  Do_you_have_pets_at_home_Yes_Animal?: string | null;
  Do_you_have_pets_at_home_Yes_Duration?: string | null;
}

const secE: SecE = {};

secE.What_typeof_recreational_activity_doesthestudentenjoy = this.healthInsurance || null;

secE.Which_ofthe_following_activities_doesthestudentenjoy = selectedActivities.length > 0 ? selectedActivities.join(',') : null;

secE.Activites_Other = activities_others.length > 0 ? activities_others.join(',') : null;

secE.Isthe_student_memberofasports_teamatschool = this.sports || null;

secE.Isthe_student_memberofasports_teamatschool_Yes = this.sportsPlay || null;

secE.Do_you_have_pets_at_home = this.pets || null;

if (this.petsArray.length > 0) {
  secE.Do_you_have_pets_at_home_Yes_Animal = this.petsArray.map(pet => pet.animal||'').join(',') || null;
  secE.Do_you_have_pets_at_home_Yes_Duration = this.petsArray.map(pet => pet.duration||'').join(',') || null;
}


console.log(secE);

this.nivishservice.updateUserRegE(userId, secE).subscribe(
  (data: any) => {
    if (data.Status === 200) {
      this.message = 'updated E';
      this.sectionF();
    }
    console.log(this.message);
  }
);



  }
  showOtherError: boolean = false;
  showOtherMealError: boolean = false;


resetOtherMealValidation() {
    this.showOtherMealError = false;
}

 
  resetOtherValidation() {
      this.showOtherError = false;
  }
 
  sectionf(){
    const userId = localStorage.getItem('userId');
    if (this.Typeofmeal === 'Other' && !this.othertypeofmeal) {
      this.showOtherError = true;
      return;
  } else {
      this.showOtherError = false;
  }

  // Validate for the "Other" meal source
  if (this.wheredoesitcomefrom === 'Other' && !this.otherMealatSchool) {
      this.showOtherMealError = true;
      return;
  } else {
      this.showOtherMealError = false;
  }
    

    if (!userId) {
      console.error('userId not found in local storage.');
      return;
    }

    const selectedFood = this.Intolerants
    .filter((Intolerant) => Intolerant.selected)
    .map((Intolerant) => Intolerant.name);

  console.log('Selected Food:', selectedFood);

  // const hasOtherChecked = selectedFood.includes('Other');
  // const IntolerantEmptyOther = hasOtherChecked && !this.otherIntolerant;

  // if (IntolerantEmptyOther) {
  //   this.showIntolerantWarning = true;
  //   return;
  // }

  // // Reset warning if there are no empty 'Other' inputs
  // this.showIntolerantWarning = false;

  this.showIntolerantWarning = this.Intolerants.some(Intolerant =>
    Intolerant.selected && Intolerant.name === 'Other' && !this.otherIntolerant);

  if (this.showIntolerantWarning) {
    this.showIntolerantWarning = true;
    return; // Don't proceed if there's a warning
  }
  this.showIntolerantWarning = false;
  // ... existing code to update user registration ...
  
  

  interface SecF {
  Usual_numberof_mealsday?: string | null;
  Usual_numberof_snacksday?: string | null;
  What_is_the_students_average_fluid_intake?: string | null;
  Type_of_meal?: string | null;
  Type_of_meal_Other?: string | null;
  Does_the_student_have_regular_meal_at_school?: string | null;
  Student_If_Yes_where_does_it_come_from?: string | null;
  Student_If_Other?: string | null;
  Is_the_student_intolerant_to_any_food_group?: string | null;
  Food_Group_Other?: string | null;
  Does_the_student_have_breakfast_regularly?: string | null;
}

const secF: SecF = {
  Usual_numberof_mealsday: this.mealsPerDay || null,
  Usual_numberof_snacksday: this.UsualNumberofSnacksPerDay || null,
  What_is_the_students_average_fluid_intake: this.fluidIntake || null,
  Type_of_meal: this.Typeofmeal || null,
  Type_of_meal_Other: this.Typeofmeal === 'Other' ? this.othertypeofmeal || null : null,
  Does_the_student_have_regular_meal_at_school: this.regularmealatschool || null,
  Student_If_Yes_where_does_it_come_from: this.wheredoesitcomefrom || null,
  Student_If_Other: this.otherMealatSchool || null,
  Is_the_student_intolerant_to_any_food_group: selectedFood.length > 0 ? selectedFood.join(',') : null,
  Food_Group_Other: this.otherIntolerant||null,

  Does_the_student_have_breakfast_regularly: this.breakfastRegular || null,
};
  
    console.log(secF);
    this.nivishservice.updateUserRegF(userId,secF).subscribe(
      (data:any)=>{
        if(data['Status']===200){
          this.message='updated F';
          this.sectionG();
        }
        console.log(this.message)
        console.log(data,"sectionFupdate")

      }
      
      )
    }
  

  


  sectiong(){
    const userId = localStorage.getItem('userId');

    if (!userId) {
      console.error('userId not found in local storage.');
      return;
    }
     
    const selectedsleeping = this.sleeping
      .filter((sleep) => sleep.selected)
      .map((sleep) => sleep.name);
   

      const sleep_Others: string[] = [];


      for (const sleep of this.sleeping) {
        if (sleep.selected && sleep.name === 'Other' && sleep.otherssleep) {
          sleep_Others.push(sleep.otherssleep);
        }
      }
      const sleepEmptyOther = this.sleeping.some(sleep => sleep.selected && sleep.name === 'Other' && !sleep.otherssleep);
      if (sleepEmptyOther) {
           this.showsleepWarning = true;
           return;
         }
     
         // Reset warning if there are no empty 'Other' inputs
         this.showsleepWarning = false;
      // interface SecG {
      //   On_an_average_isthe_student_freshandrelaxed_night_sleep?: string;
      //   Does_the_student_have_any_sleep_related_issues?: string;
      //   Does_the_student_have_any_sleep_related_issues_Yes:string;
      //   Sleep_related_issues_Other?: string;
      //   What_is_the_students_average_length_of_sleep_per_night?: string;
      //   What_is_the_students_nap_cycle_during_day_Nap?: string;
      // }
     
      
      
      const secG={
      
        On_an_average_isthe_student_freshandrelaxed_night_sleep: this.StudentFreshandRelaxedAfterniNghtsSleep,
    
        Does_the_student_have_any_sleep_related_issues:this.sleepIssue,
        Does_the_student_have_any_sleep_related_issues_Yes:selectedsleeping.join(','),

        Sleep_related_issues_Other: sleep_Others.join(',')||null,
      
        What_is_the_students_average_length_of_sleep_per_night:this.AverageLengthOfSleepPerNight,
      
      
        What_is_the_students_nap_cycle_during_day_Nap:this.napTimes.map(nap => nap.napLabel).join(','),
      };
      
    console.log(secG);
    this.nivishservice.updateUserRegG(userId,secG).subscribe(
      (data:any)=>{
        if(data['Status']===200){
          this.message='updated G';
          this.sectionH();
        }
        console.log(this.message)

      }
    
      )
  
  }
  
  

  sectionL(){
    const userId = localStorage.getItem('userId');
    if(!userId){
      console.log('userId not found in local storage.')
      return;
    }

    const selectedmedical = this.medicalproblem
    .filter((medical) => medical.selected)
    .map((medical) => medical.name);
    const allMedications = [this.currentMedication, ...this.medications];
    const combinedMedications = allMedications.join(',');
   

    const selectedcurrentmedical = this.currentproblem
    .filter((current) => current.selected)
    .map((current) => current.name);
  
   

    interface secL {
      Does_the_student_have_any_medicalissue_In_The_Past: string;
      Does_the_student_have_any_medicalissue_In_The_Past_Yes: string; 
      Does_the_student_have_any_medicalissue_Currently: string;
      Does_the_student_have_any_medicalissue_Currently_Yes:string;
      Past_Medication:string;
      Current_Medication: string;
      Any_Known_Allergies: string;
      Any_Known_Allergies_yes: string;      
    }
    
    const secL:secL={
      Does_the_student_have_any_medicalissue_In_The_Past:this.medicalIssue,
      Does_the_student_have_any_medicalissue_In_The_Past_Yes:selectedmedical.join(','),
      Does_the_student_have_any_medicalissue_Currently:this.medicalIssueCurrently,
      Does_the_student_have_any_medicalissue_Currently_Yes:selectedcurrentmedical.join(','),
      Past_Medication:this.pastmedications.map(pastmed => pastmed.pastMedLabel).join(','),
      Current_Medication:this.currentMedications.map(currentMed=>currentMed.currentMedLabel).join(','),
      Any_Known_Allergies: this.allergies,
        Any_Known_Allergies_yes: this.allergies === 'Yes' ? this.allergiesList.map(allergy => allergy.allergyLabel).join(',') : '',      
    }
    console.log('HTTP Request Payload:', secL);
    console.log('allergy1:', this.allergiesList);
    console.log(secL);
    this.nivishservice.updateUserRegl(userId,secL).subscribe(
      (data:any)=>{
        if(data['Status']===200){
          this.message='updated L';
          console.log('Response from Backend:', data);
          this.sectionM();
        }
        console.log(this.message)
        console.log(data)

      }
    
      )
  }

sectioni(){
  const userId = localStorage.getItem('userId');
  if(!userId){
    console.log('userId not found in local storage.')
    return;
  }
  interface SecI {
    What_is_student_general_opinion_of_school?: string;
    The_student_goes_to_school?: string;
    How_would_you_rate_student_overall_attendance_at_school?: string;
  }
  
  const secI: SecI = {};
  
  if (this.StudentGeneralOpinioofSchool) {
    secI.What_is_student_general_opinion_of_school = this.StudentGeneralOpinioofSchool;
  }
  
  if (this.studentgoestoschool) {
    secI.The_student_goes_to_school = this.studentgoestoschool;
  }
  
  if (this.selectedNumber) {
    secI.How_would_you_rate_student_overall_attendance_at_school = this.selectedNumber;
  }
  
  console.log(secI);
  this.nivishservice.updateUserRegI(userId,secI).subscribe(
    (data:any)=>{
      if(data['Status']===200){
        this.message='updated I';
        this.sectionJ();
      }
      console.log(this.message)
      console.log(data)

    }
  
    )
}
sectionj() {
  const userId = localStorage.getItem('userId');
  if (!userId) {
    console.log('userId not found in local storage.');
    return;
  }

  const selectedrespected = this.respected
    .filter((respect) => respect.selected)
    .map((respect) => respect.name);

  const selectedperformancing = this.performancing
    .filter((performance) => performance.selected)
    .map((performance) => performance.name);

  const selecteddissatisified = this.dissatisified
    .filter((disatisfy) => disatisfy.selected)
    .map((disatisfy) => disatisfy.name);

  const disatisfyOther = this.dissatisified
    .find((disatisfy) => disatisfy.selected && disatisfy.name === 'Other');
    const disatifyEmptyOther = this.dissatisified.some(disatisfy => disatisfy.selected && disatisfy.name === 'Other' && !disatisfy.othersDissatification);
    if (disatifyEmptyOther) {
         this.showDisatisfyWarning = true;
         return;
       }
   
       // Reset warning if there are no empty 'Other' inputs
       this.showDisatisfyWarning = false;
    interface SecJ {
      Do_you_have_concerns_following_respect_tothe_student?: string | null;
      How_would_you_rate_the_student_overall_physical_health?: string | null;
      Isthe_students_school_performance_affected_by_any_following?: string | null;
      Isthe_student_significantly_dissatisfied_byany_following?: string | null;
      Student_dissatisfied_Other?: string | null;
    }

  const secJ: SecJ = {};

secJ.Do_you_have_concerns_following_respect_tothe_student = selectedrespected.length > 0 ? selectedrespected.join(',') : null;
secJ.How_would_you_rate_the_student_overall_physical_health = this.selectedNumberHealth || null;
secJ.Isthe_students_school_performance_affected_by_any_following = selectedperformancing.length > 0 ? selectedperformancing.join(',') : null;
secJ.Isthe_student_significantly_dissatisfied_byany_following = selecteddissatisified.length > 0 ? selecteddissatisified.join(',') : null;

const disatisfyOtherText = disatisfyOther && disatisfyOther.othersDissatification ? disatisfyOther.othersDissatification.trim() : null;
secJ.Student_dissatisfied_Other = disatisfyOtherText || null;
  
console.log(secJ);

  this.nivishservice.updateUserRegJ(userId, secJ).subscribe(
    (data: any) => {
      if (data['Status'] === 200) {
        this.message = 'updated J';
        this.sectionK();
      }
      console.log(this.message);
      console.log(data);
    },
    (error) => {
      console.error('Error:', error);
      // Handle the error here (e.g., show an error message to the user)
    }
  );
}

showOtherPersonalityError: boolean = false;
showOtherReactionError: boolean = false;
showOtherRelationshipError: boolean = false;

// Other class properties and methods...

resetOtherPersonalityValidation() {
  this.showOtherPersonalityError = false;
}

resetOtherReactionValidation() {
  this.showOtherReactionError = false;
}

resetOtherRelationshipValidation() {
  this.showOtherRelationshipError = false;
}

 
  sectionh(){
    const userId = localStorage.getItem('userId');
    if (this.SocialPersonalityOfTheStudent === 'Other' && !this.studentPersonalityInformationothers) {
      this.showOtherPersonalityError = true;
      return;
    } else {
      this.showOtherPersonalityError = false;
    }

    if (this.studentsreactiontochange === 'Other' && !this.studentsreactiontochangeothers) {
      this.showOtherReactionError = true;
      return;
    } else {
      this.showOtherReactionError = false;
    }

    if (this.relationshipStudent === 'Other' && !this.relationshipStudentothers) {
      this.showOtherRelationshipError = true;
      return;
    } else {
      this.showOtherRelationshipError = false;
    }
    if(!userId){
      console.log('userId not found in local storage.')
      return;
      
  }
  interface SecH {
    How_would_you_describe_the_social_personality_ofthe_student?: string;
    Social_personality_Other?: string;
    Specify_if_the_student_has_any_irrational_fears_phobias?: string;
    How_would_you_rate_the_students_self_image_self_worth?: string;
    How_would_you_rate_students_cooperation_in_housedhold_chores?: string;
    How_would_you_best_describe_the_student_reaction_to_change?: string;
    Student_reaction_Other?: string;
    How_would_you_describe_student_rs_with_other_students?: string;
    How_would_you_describe_student_rs_with_other_students_Other?: string;
    Does_the_student_have_any_significantly_close_friends?: string;
    student_close_friends_yes_how_many_girls?: string;
    student_close_friends_yes_how_many_boys?: string;
  }
  
  const secH: SecH = {};
  
  
  if (this.SocialPersonalityOfTheStudent) {
    secH.How_would_you_describe_the_social_personality_ofthe_student = this.SocialPersonalityOfTheStudent;

    if (this.SocialPersonalityOfTheStudent === 'Other') {
        secH.Social_personality_Other = this.studentPersonalityInformationothers || null; 
    }
}
  
  if (this.AnyIrrationalFearsOrPhobias) {
    secH.Specify_if_the_student_has_any_irrational_fears_phobias = this.AnyIrrationalFearsOrPhobias;
  }
  
  if (this.StudentSelfImageSelfWorth) {
    secH.How_would_you_rate_the_students_self_image_self_worth = this.StudentSelfImageSelfWorth;
  }
  
  if (this.selectedNumberrate) {
    secH.How_would_you_rate_students_cooperation_in_housedhold_chores = this.selectedNumberrate;
  }
  
  if (this.studentsreactiontochange) {
    secH.How_would_you_best_describe_the_student_reaction_to_change = this.studentsreactiontochange;
    secH.Student_reaction_Other = this.studentPersonalreactioInformationothers;
  }
  
  if (this.relationshipStudent) {
    secH.How_would_you_describe_student_rs_with_other_students = this.relationshipStudent;

    if (this.relationshipStudent === 'Other') {
        secH.How_would_you_describe_student_rs_with_other_students_Other = this.relationshipStudentothers || null; 
    }
}
  
  if (this.significantlyclosefriends) {
    secH.Does_the_student_have_any_significantly_close_friends = this.significantlyclosefriends;
    secH.student_close_friends_yes_how_many_girls = this.girlsCount;
    secH.student_close_friends_yes_how_many_boys = this.boysCount;
  }
  
  
console.log(secH);
this.nivishservice.updateUserRegH(userId, secH).subscribe(
  (data: any) => {
    if (data['Status'] === 200) {
      this.message = 'updated H';
      this.sectionI();
    }
    console.log(this.message);
    console.log(data);
  },
  (error) => {
    console.error('Error:', error);
  }
);




}

sectionk(){
  const userId = localStorage.getItem('userId');
  if(!userId){
    console.log('userId not found in local storage.')
    return;
}
const selectedillness = this.illness
.filter((ill) => ill.selected)
.map((ill) => ill.name);

const others_Illness: string[] = [];
for (const ill of this.illness) {
if (ill.selected && ill.name === 'Other' && ill.othersIllness) {
  others_Illness.push(ill.othersIllness);
}
}
const illEmptyOther = this.illness.some(ill => ill.selected && ill.name === 'Other' && !ill.othersIllness);
if (illEmptyOther) {
     this.showillWarning = true;
     return;
   }

   // Reset warning if there are no empty 'Other' inputs
   this.showillWarning = false;  
interface SecK {
  Any_Ongoing_Illnesscondition_membersofthe_students_family?: string |null;
  Any_students_family_Other?: string|null;
}

const secK: SecK = {};

if (selectedillness.length > 0) {
  secK.Any_Ongoing_Illnesscondition_membersofthe_students_family = selectedillness.length >0? selectedillness.join(',') : null;
}

if (others_Illness.length > 0) {
  secK.Any_students_family_Other =others_Illness.length >0?  others_Illness.join(',') : null;
}

    console.log(secK,);
    this.nivishservice.updateUserRegK(userId,secK).subscribe(
      (data:any)=>{
        if(data['Status']=200){
          this.message ='updated K'
          this.sectionl();
        }
        console.log(this.message)
        console.log(data)
      })
  }

  sectionm(){
    const userId = localStorage.getItem('userId');
    if(!userId){
      console.log('userId not found in local storage.')
      return;
  }
  interface secM{
    BCG_Dose0: string,
    BCG_Dose1: string,
    BCG_Dose2: string,
    BCG_Dose3: string,
    BCG_Dose4: string,
    Chicken_Pox_Dose0: string,
    Chicken_Pox_Dose1:string;
    Chicken_Pox_Dose2:string;
    Chicken_Pox_Dose3:string;
    Chicken_Pox_Dose4:string;
    Cholera_Dose0:string;
    Cholera_Dose1:string;
    Cholera_Dose2:string;
    Cholera_Dose3:string;
    Cholera_Dose4:string;
    COVID_Vaccination_Dose0:string;
    COVID_Vaccination_Dose1:string;
    COVID_Vaccination_Dose2:string;
    COVID_Vaccination_Dose3:string;
    COVID_Vaccination_Dose4:string;
    DPT_Dose0:string;
    DPT_Dose1:string;
    DPT_Dose2:string;
    DPT_Dose3:string;
    DPT_Dose4:string;
    DT_Dose0:string;
    DT_Dose1:string;
    DT_Dose2:string;
    DT_Dose3:string;
    DT_Dose4:string;
    HepatitisA_Dose0:string;
    HepatitisA_Dose1:string;
    HepatitisA_Dose2:string;
    HepatitisA_Dose3:string;
    HepatitisA_Dose4:string;
    HepatitisB_Dose0:string;
    HepatitisB_Dose1:string;
    HepatitisB_Dose2:string;
    HepatitisB_Dose3:string;
    HepatitisB_Dose4:string;
    HPV_Dose0:string;
    HPV_Dose1:string;
    HPV_Dose2:string;
    HPV_Dose3:string;
    HPV_Dose4:string;
    Influenza_HIB_Dose0:string;
    Influenza_HIB_Dose1:string;
    Influenza_HIB_Dose2:string;
    Influenza_HIB_Dose3:string;
    Influenza_HIB_Dose4:string;
    Influenza_Viral_Dose0:string;
    Influenza_Viral_Dose1:string;
    Influenza_Viral_Dose2:string;
    Influenza_Viral_Dose3:string;
    Influenza_Viral_Dose4:string;
    MMR_Dose0:string;
    MMR_Dose1:string;
    MMR_Dose2:string;
    MMR_Dose3:string;
    MMR_Dose4:string;
    Mumps_Measles_Dose0:string; 
    Mumps_Measles_Dose1:string;
    Mumps_Measles_Dose2:string;
    Mumps_Measles_Dose3:string;
    Mumps_Measles_Dose4:string;
    Oral_Polio_Dose0:string;
    Oral_Polio_Dose1:string;
    Oral_Polio_Dose2:string;
    Oral_Polio_Dose3:string;
    Oral_Polio_Dose4:string;
    RotaVirus_Dose0:string;
    RotaVirus_Dose1:string;
    RotaVirus_Dose2:string;
    RotaVirus_Dose3:string;
    RotaVirus_Dose4:string;
    Tetanus_Toxoid_Dose0:string;
    Tetanus_Toxoid_Dose1:string;
    Tetanus_Toxoid_Dose2:string;
    Tetanus_Toxoid_Dose3:string;
    Tetanus_Toxoid_Dose4:string;
    Typhoid_Dose0:string;
    Typhoid_Dose1:string;
    Typhoid_Dose2:string;
    Typhoid_Dose3:string;
    Typhoid_Dose4:string;
    Yellow_Fever_Dose0:string;
    Yellow_Fever_Dose1:string;
    Yellow_Fever_Dose2:string;
    Yellow_Fever_Dose3:string;
    Yellow_Fever_Dose4:string;
    
  }

const secM:secM={
  BCG_Dose0: this.bcg0,
  BCG_Dose1: this.bcg1,
  BCG_Dose2: this.bcg2,
  BCG_Dose3: this.bcg3,
  BCG_Dose4: this.bcg4,
  Chicken_Pox_Dose0: this.chickenPox0,
  Chicken_Pox_Dose1:this.chickenPox1,
  Chicken_Pox_Dose2:this.chickenPox2,
  Chicken_Pox_Dose3:this.chickenPox3,
  Chicken_Pox_Dose4:this.chickenPox4,
  Cholera_Dose0:this.cholera0,
  Cholera_Dose1:this.cholera1,
  Cholera_Dose2:this.cholera2,
  Cholera_Dose3:this.cholera3,
  Cholera_Dose4:this.cholera4,
  COVID_Vaccination_Dose0:this.COVID_Dose0,
  COVID_Vaccination_Dose1:this.COVID_Dose1,
  COVID_Vaccination_Dose2:this.COVID_Dose2,
  COVID_Vaccination_Dose3:this.COVID_Dose3,
  COVID_Vaccination_Dose4:this.COVID_Dose4,
  DPT_Dose0:this.dpt0,
  DPT_Dose1:this.dpt1,
  DPT_Dose2:this.dpt2,
  DPT_Dose3:this.dpt3,
  DPT_Dose4:this.dpt4,
  DT_Dose0:this.dt0,
  DT_Dose1:this.dt1,
  DT_Dose2:this.dt2,
  DT_Dose3:this.dt3,
  DT_Dose4:this.dt4,
  HepatitisA_Dose0:this.hepatitis0,
  HepatitisA_Dose1:this.hepatitis1,
  HepatitisA_Dose2:this.hepatitis2,
  HepatitisA_Dose3:this.hepatitis3,
  HepatitisA_Dose4:this.hepatitis4,
  HepatitisB_Dose0:this.hepatitisb0,
  HepatitisB_Dose1:this.hepatitisb1,
  HepatitisB_Dose2:this.hepatitisb2,
  HepatitisB_Dose3:this.hepatitisb3,
  HepatitisB_Dose4:this.hepatitisb4,
  HPV_Dose0:this.hpv0,
  HPV_Dose1:this.hpv1,
  HPV_Dose2:this.hpv2,
  HPV_Dose3:this.hpv3,
  HPV_Dose4:this.hpv4,
  Influenza_HIB_Dose0:this.influenza0,
  Influenza_HIB_Dose1:this.influenza1,
  Influenza_HIB_Dose2:this.influenza2,
  Influenza_HIB_Dose3:this.influenza3,
  Influenza_HIB_Dose4:this.influenza4,
  Influenza_Viral_Dose0:this.influenzaV0,
  Influenza_Viral_Dose1:this.influenzaV1,
  Influenza_Viral_Dose2:this.influenzaV2,
  Influenza_Viral_Dose3:this.influenzaV3,
  Influenza_Viral_Dose4:this.influenzaV4,
  MMR_Dose0:this.mmr0,
  MMR_Dose1:this.mmr1,
  MMR_Dose2:this.mmr2,
  MMR_Dose3:this.mmr3,
  MMR_Dose4:this.mmr4,
  Mumps_Measles_Dose0:this.mumps0, 
  Mumps_Measles_Dose1:this.mumps1, 
  Mumps_Measles_Dose2:this.mumps2, 
  Mumps_Measles_Dose3:this.mumps3, 
  Mumps_Measles_Dose4:this.mumps4, 
  Oral_Polio_Dose0:this.oralP0,
  Oral_Polio_Dose1:this.oralP1,
  Oral_Polio_Dose2:this.oralP2,
  Oral_Polio_Dose3:this.oralP3,
  Oral_Polio_Dose4:this.oralP4,
  RotaVirus_Dose0:this.rotaVirus0,
  RotaVirus_Dose1:this.rotaVirus1,
  RotaVirus_Dose2:this.rotaVirus2,
  RotaVirus_Dose3:this.rotaVirus3,
  RotaVirus_Dose4:this.rotaVirus4,
  Tetanus_Toxoid_Dose0:this.teatnus0,
  Tetanus_Toxoid_Dose1:this.teatnus1,
  Tetanus_Toxoid_Dose2:this.teatnus2,
  Tetanus_Toxoid_Dose3:this.teatnus3,
  Tetanus_Toxoid_Dose4:this.teatnus4,
  Typhoid_Dose0:this.typhoid0,
  Typhoid_Dose1:this.typhoid1,
  Typhoid_Dose2:this.typhoid2,
  Typhoid_Dose3:this.typhoid3,
  Typhoid_Dose4:this.typhoid4,
  Yellow_Fever_Dose0:this.yellow0,
  Yellow_Fever_Dose1:this.yellow1,
  Yellow_Fever_Dose2:this.yellow2,
  Yellow_Fever_Dose3:this.yellow3,
  Yellow_Fever_Dose4:this.yellow4,
  
}



console.log(secM);
this.nivishservice.updateUserRegM(userId,secM).subscribe(
  (data: any) => {
    if (data['Status'] === 200) {
      this.message = 'updated M';
      this.Consent();
    }
    console.log(this.message);
    console.log(data);
  },
  (error) => {
    console.error('Error:', error);
    // Handle the error here (e.g., show an error message to the user)
  }
);

}




submitForm() {
  
  this.notedata(); // Call the notedata() function
}




notedata(){
 const userId = localStorage.getItem('userId');
  if (!userId) {
    console.log('userId not found in local storage.');
    return;
  }
  this.infoseekId=userId
// interface note {
//   InfoseekId:this.infoseekId;
//   Last_date_signed_copy_of_form:string;
//   type_your_name:string;
//   gaurdian_of:string;
//   of_class:string;
//   Signature:string;
//   Submitted_date:string;
//   place:string;
// }
const noteSignFormData = new FormData();
noteSignFormData.append('InfoseekId', userId);
noteSignFormData.append('Last_date_signed_copy_of_form',this.notedates)
noteSignFormData.append('gaurdian_of',this.studentname)
noteSignFormData.append('type_your_name',this.parentsname)
noteSignFormData.append('of_class',this.typeclass)
noteSignFormData.append('Signature',this.signature)
noteSignFormData.append('Submitted_date',this.notedate)
noteSignFormData.append('place',this.noteplace)
noteSignFormData.append('upload_sign', this.selectedUserProfile);  // Terms_and_conditions: this.policies
noteSignFormData.append('Accepted', this.policies);  // Terms_and_conditions: this.policies

 

console.log(noteSignFormData);
this.nivishservice.infosekpost(noteSignFormData).subscribe(
  (data: any) => {
    if (data['Status'] === 200) {
      this.message = 'note';
      this.routes.navigate(['/infoseek']);  

      // this.routes.navigate(['/infoseek']);

    
    }
    console.log(this.message);
    console.log(data);
  },
  (error: any) => {
    console.error('Error:', error);
    // Handle the error here (e.g., show an error message to the user)
  }
);
}


onStudentPhotoSelected(event: any) {
  const file: File = event.target.files[0];

  if (file) {
    this.studentPhoto = file;
    this.fileTypeError = false;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.studentPhoto = e.target.result;

      this.studentPhotoImageUrl = e.target.result;
    };
    reader.readAsDataURL(file);
  } else {
    this.fileTypeError = true;
    this.studentPhoto = '/assets/images/profilepicture.png';

    this.studentPhotoImageUrl = '/assets/images/profilepicture.png';
  }
}
onProfilePictureSelecte(event: any) {
  const file: File = event.target.files[0];

  if (file) {
    this.studentPhoto = file;
    this.fileTypeError = false;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.selectedImage = e.target.result;
      // Update digitalSignImageUrl with the selected image URL
      this.studentPhotoImageUrl = e.target.result;
    };
    reader.readAsDataURL(file);
  } else {
    this.fileTypeError = true;
    this.selectedImage = '/assets/images/profilepicture.png';
    // Set digitalSignImageUrl to a default image URL
    this.studentPhotoImageUrl = '/assets/images/profilepicture.png';
  }
}



onNoteSubmit(event: Event) {
  event.preventDefault(); // Prevent default form submission behavior

  // Rest of your form submission logic
}
onProfilePictureSelected(event: any) {
  const file: File = event.target.files[0];

  if (file) {
    this.studentPhoto = file;
    this.fileTypeError = false;
    this.selectedUserProfile=file;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.selectedImage = e.target.result;
      // Update digitalSignImageUrl with the selected image URL
      this.digitalSignImageUrl = e.target.result;
    };
    reader.readAsDataURL(file);
  } else {
    this.fileTypeError = true;
    this.selectedImage = '/assets/images/profilepicture.png';
    // Set digitalSignImageUrl to a default image URL
    this.digitalSignImageUrl = '/assets/images/profilepicture.png';
  }
}

// onDigitalSignSelected(event: any) {
//   const selectedFile = event.target.files[0];
//   if (selectedFile) {
//     // Handle the digital sign file selection
//     const reader = new FileReader();
//     reader.onload = (e: any) => {
//       this.digitalSignImageUrl = e.target.result;
//     };
//     reader.readAsDataURL(selectedFile);
//   }
// }




// onStudentPhotoSelected(event: any) {
//   const selectedFile = event.target.files[0];
//   if (selectedFile) {
//     // Handle the student photo file selection
//     const reader = new FileReader();
//     reader.onload = (e: any) => {
//       this.studentPhotoImageUrl = e.target.result;
//     };
//     reader.readAsDataURL(selectedFile);
//   }
// }
// onFileSelected(event: any): void {
//   const file: File | null = event.target.files && event.target.files[0];
//   if (file) {
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       this.imageUrl = e.target?.result as string | ArrayBuffer | null;
//     };
//     reader.readAsDataURL(file);
//   }
  
// }
fetchCountry() {
  this.nivishservice.getallcountry().subscribe(response => {
    if (response && response.Result) {
      this.CountryList = response.Result;
      // console.log(this.CountryList); 

    }
  });
}

validateNumber(event: any) {
  const inputValue = event.target.value;
  if (inputValue && isNaN(inputValue)) {
    this.showMealsError = true;
  } else {
    this.showMealsError = false;
  }
}

validatesnacks(event:any){
  const inputValue = event.target.value;
  if (inputValue && isNaN(inputValue)) {
    this.showsnacksError = true;
  } else {
    this.showsnacksError = false;
  }
}

validatefluid(event:any){
  const inputValue = event.target.value;
  if (inputValue && isNaN(inputValue)) {
    this.showsfluidError = true;
  } else {
    this.showsfluidError = false;
  }
}
onCountryChange(event: any) {
  this.SelectedCountryId = event;
  this.getStatesForCountry(this.SelectedCountryId);
}



getStatesForCountry(selectedCountryId: any) {
  this.nivishservice.getstate(selectedCountryId).subscribe(
    (response: any) => {
      if (response && response.Result) {
        this.statesList = response.Result;
      }
    },
    (error: any) => {
      // Handle errors if any
    }
  );
}
onstateChange(event:any){
  this.SelectedstatesId=event;
  this.getallcitys(this.SelectedstatesId)


}
toggleFullNameInput() {
  this.showFullName = true;
}
oncityChange(event:any){

}
getallcitys(SelectedstatesId: number) {
 
 
  this.nivishservice.getallcitys(SelectedstatesId).subscribe(response => {
    if (response && response.Result) {
      this.citylist = response.Result.map((item: any) => item.City);
      this.citysId = response.Result.map((item: any) => item.id);
    }
  });
  

}
addNap() {
  this.napTimes = [...this.napTimes, { napLabel: '' }];
}

removeNap(index: number) {
  this.napTimes.splice(index, 1);
}
addPastMed() {
  this.pastmedications.push({ pastMedLabel: '' });
}

removePastMed(index: number) {
  this.pastmedications.splice(index, 1);
}
removeCurrentMed(index:number){
  this.currentMedications.splice(index,1)
}
addCurrentMed(){
  this.currentMedications.push({currentMedLabel:''});
}
removeAllergiesInput(index:number){
  this.allergiesList.splice(index,1)
}
addAllergiesInput() {
  this.allergiesList.push({allergyLabel:''});
}

// getInfoiseekMasterId(selectedUserId: any) {
//   this.nivishservice.InfoseekMasterId(selectedUserId).subscribe(
//     (data: any) => {
//       console.log('API Response:', data);
//       const InfoseekMasterId = data.Result[0];
//       const extractStudentFirstName = (data: any) => {
//         return data['student_first_name'] || data['studentfistname'];
//       };
//        this.studentFirstName = extractStudentFirstName(InfoseekMasterId);
//        this.dateofBirth = InfoseekMasterId['Date_of_Birth'];
//        this.studentMiddleName1 = InfoseekMasterId['Student_Middle_Name_1'];
//        this.studentMiddleName2 = InfoseekMasterId['Student_Middle_Name_2'];
//        this.studentLastName = InfoseekMasterId['Student_Last_Name'];
//        this.gender =InfoseekMasterId['Gender'];
//        this.blood= InfoseekMasterId ['Blood_Group'];
//        this.Rhfactor = InfoseekMasterId ['RH_Factor'];
//        this.fathersFirstName = InfoseekMasterId['Fathers_First_Name'];
//        this.fathersLastName = InfoseekMasterId['Fathers_Last_Name'];
//        this.mothersFirstName = InfoseekMasterId['Mothers_First_Name'];
//        this.mothersLastName = InfoseekMasterId ['Mothers_Last_Name'];
//        this.emailShm = InfoseekMasterId ['Parent_Email']
//        this.ethnicity = InfoseekMasterId ['Mothers_Ethnic_Origin']
//        this.fatherethnicity = InfoseekMasterId ['Fathers_Ethnic_Origin']
//        this.mediAid=InfoseekMasterId['Medical_Aid'];

      

//       console.log("Date_of_Birth:", this.dateofBirth);
//       console.log(  this.studentFirstName,this.dateofBirth,"narayana")
//     },
//     (error: any) => {
//       console.error(error);
//     }
//   );



//   }
  getInfoiseekMasterId() {
    const userId = localStorage.getItem('userId');

   
      // Access other stored values here
   

    this.nivishservice.InfoseekMasterId(userId).subscribe(
      (data: any) => {
        const InfoseekMasterId = data.Result[0];
        // const extractStudentFirstName = (data: any) => {
        //   return data['student_first_name'] || data['studentfistname'];
        // };
        //  this.studentFirstName = extractStudentFirstName(InfoseekMasterId);
        this.studentPhotoImageUrl = InfoseekMasterId['upload_photo'];

        if (this.studentPhotoImageUrl) {
          const filename = new URL(this.studentPhotoImageUrl).pathname.split('/').pop();
          this.studentPhotofilename = filename; // Store filename for display
          console.log( this.studentPhotofilename,"image name");
        } else {
          console.warn('studentPhotoImageUrl is null or undefined.');
          this.studentPhotofilename = ''; // Clear placeholder if URL is unavailable
        }
         this.studentPersonalInformation = InfoseekMasterId['following_information_providedby']
         this.studentPersonalInformationothers = InfoseekMasterId['If_Other']
         this.studentFirstName = InfoseekMasterId['Student_FirstName'] || InfoseekMasterId['studentfistname']
         this.studentMiddleName1 = InfoseekMasterId['Student_MiddleName1'];
         this.studentMiddleName2 = InfoseekMasterId['Student_MiddleName2'];
         this.studentLastName = InfoseekMasterId['Student_LastName'];
         this.gender =InfoseekMasterId['Gender'];
         this.dateofBirth = InfoseekMasterId['Student_DOB'];
        this.dateofBirth = InfoseekMasterId && InfoseekMasterId['Student_DOB'];
         this.blood= InfoseekMasterId['BloodGroup'];
         this.Rhfactor = InfoseekMasterId['Rh_Factor'];
         this.noofSisters = InfoseekMasterId['Number_Of_Sisters'];
         this.noOfBrothers = InfoseekMasterId['Number_Of_Brothers'];
         this.mothersFirstName = InfoseekMasterId['Mothers_FirstName'];
         this.motherMiddleName1 = InfoseekMasterId['Mothers_MiddleName1'];
         this.mothersMiddleName2 = InfoseekMasterId['Mothers_MiddleName2'];
         this.mothersLastName = InfoseekMasterId ['Mothers_LastName'];
         this.ethnicity = InfoseekMasterId ['Mothers_Ethnicity'];
         this.motherOthersInput = InfoseekMasterId['motherOthersInput'];
         this.fathersFirstName = InfoseekMasterId['Fathers_FirstName'];
         this.fatherMiddleName1 = InfoseekMasterId['Fathers_MiddleName1'];
         this.fatherMiddleName2 = InfoseekMasterId['Fathers_MiddleName2'];
         this.fathersLastName = InfoseekMasterId['Fathers_LastName'];
         this.fatherethnicity = InfoseekMasterId ['Fathers_Ethnicity'];
         this.fatherOthersInput = InfoseekMasterId['Fathers_Ethnicity_If_Other'];
         this.Insurance = InfoseekMasterId['Do_you_have_health_insurance'];
         this.mediAid=InfoseekMasterId['Medical_Aid'];
         this.policy = InfoseekMasterId['Policy_Card'];
         this.expireDate = InfoseekMasterId['Expiry_Date'];
         this.Apartment_Name = InfoseekMasterId['Building_Name'];
         this.apartmentNumber = InfoseekMasterId['Apartment_Villa_No'];
         this.streetAddress = InfoseekMasterId['Street_No'];
         this.areaC = InfoseekMasterId['Area'];
         const SelectedCountryId=InfoseekMasterId['Country'];
         this.SelectedCountryId=InfoseekMasterId['Country'];
         this.fetchCountry()
         this.getStatesForCountry(SelectedCountryId);
         this.SelectedstatesId = InfoseekMasterId['State'];
         this.SelectedcityId=InfoseekMasterId['City']
         this.phNumber=InfoseekMasterId['Emergency_MobileNumber_Registered_with_school'];
         this.motherPhNumber=InfoseekMasterId['Alternate_MobileNumber'];
         this.alternativeBelongsTo=InfoseekMasterId['Belongs_To'];
         this.postalCode = InfoseekMasterId['Zip_Code'];
         this.emailShm = InfoseekMasterId ['Email_Registered_With_School'];
         this.mobile_1 = InfoseekMasterId['Primary_Contact'];
         this.mobil1eothers=InfoseekMasterId['Primary_Contact_Belongs_To_Other']
         this.mobile1_belongs=InfoseekMasterId['Primary_Contact_Belongs_To'];
         this.mobilebelong_Fn=InfoseekMasterId['Primary_Contact_Full_Name'];
         this.mobile_2=InfoseekMasterId['Secondary_Contact'];
         this.mobile2_belongs=InfoseekMasterId['Secondary_Contact_Belongs_To'];
         this.mobil2eothers=InfoseekMasterId['Secondary_Contact_Belongs_To_Other'];
         this.mobile2belong_Fn=InfoseekMasterId['Secondary_Contact_Full_Name'];
         console.log(this.mobile2belong_Fn,"hh")
         this.familyDocName=InfoseekMasterId['Family_Doctor_Name'];
         this.docContactNumber=InfoseekMasterId['Doctor_Contact_Number'];
         this.healthInsurance=InfoseekMasterId['What_typeof_recreational_activity_doesthestudentenjoy'];
         this.otherIntolerant=InfoseekMasterId['Food_Group_Other'];
         console.log( this.otherIntolerant,'minute');

         // Assuming the API response has a property named 'selectedActivities'
// Assuming the API response has a property named 'Which_ofthe_following_activities_doesthestudentenjoy'
// Assuming the API response has a property named 'Which_ofthe_following_activities_doesthestudentenjoy'
// Assuming the API response has a property named 'Which_ofthe_following_activities_doesthestudentenjoy'
        const selectedActivitiesString = InfoseekMasterId['Which_ofthe_following_activities_doesthestudentenjoy'];

        if (selectedActivitiesString) {
          const selectedActivitiesArray = selectedActivitiesString.split(',');

          if (selectedActivitiesArray.length > 0) {

            this.activities.forEach(activity => {
              const isSelected = selectedActivitiesArray.includes(activity.name);

              // console.log('Activity Name:', activity.name);
              // console.log('Is Selected:', isSelected);
              // this.selectedActivitiesString = selectedActivitiesString;
              activity.selected = isSelected;
              if (activity.name === 'Other') {
                activity.othersInput = InfoseekMasterId['Activites_Other'];
              }
            });
          } else {
            // Handle case when the selected activities array is empty
          }
        } else {
          // Handle case when the selected activities data is missing or empty
        }
        const concerns=InfoseekMasterId['Do_you_have_concerns_following_respect_tothe_student'];
        if(concerns){
          const someConcerns = concerns.split(',');

          if (concerns.length > 0) {

            this.respected.forEach(respect => {
              const isSelected = someConcerns.includes(respect.name);

              // console.log('Activity Name:', respect.name);
              // console.log('Is Selected:', isSelected);
              // this.selectedActivitiesString = selectedActivitiesString;
              respect.selected = isSelected;
              // if (respect.name === 'Other') {
              //   respect.othersInput = InfoseekMasterId['Activites_Other'];
              // }
            });}}

            const performances=InfoseekMasterId['Isthe_students_school_performance_affected_by_any_following'];
            if(performances){
              const someperformances = performances.split(',');
    
              if (performances.length > 0) {
                console.log('Selected activities array has data.');
    
                this.performancing.forEach(performance => {
                  const isSelected = someperformances.includes(performance.name);
    
                  // console.log('Activity Name:', performance.name);
                  // console.log('Is Selected:', isSelected);
                  // this.selectedActivitiesString = selectedActivitiesString;
                  performance.selected = isSelected;
                  // if (respect.name === 'Other') {
                  //   respect.othersInput = InfoseekMasterId['Activites_Other'];
                  // }
                });}}

                const disatisfaction=InfoseekMasterId['Isthe_student_significantly_dissatisfied_byany_following'];
                if(disatisfaction){
                  const somedisatisfaction = disatisfaction.split(',');
        
                  if (disatisfaction.length > 0) {
        
                    this.dissatisified.forEach(disatisfy => {
                      const isSelected = somedisatisfaction.includes(disatisfy.name);
        
                      // console.log('disatisfy Name:', disatisfy.name);
                      // console.log('Is Selected:', isSelected);
                      // this.selectedActivitiesString = selectedActivitiesString;
                      disatisfy.selected = isSelected;
                      if (disatisfy.name === 'Other') {
                        disatisfy.othersDissatification = InfoseekMasterId['Student_dissatisfied_Other'];
                      }
                    });}}

                    const illnessing=InfoseekMasterId['Any_Ongoing_Illnesscondition_membersofthe_students_family'];
                    if(illnessing){
                      const someillness = illnessing.split(',');
            
                      if (illnessing.length > 0) {
            
                        this.illness.forEach(ill => {
                          const isSelected = someillness.includes(ill.name);
            
                          // console.log('disatisfy Name:', ill.name);
                          // console.log('Is Selected:', isSelected);
                          // this.selectedActivitiesString = selectedActivitiesString;
                          ill.selected = isSelected;
                          if (ill.name === 'Other') {
                            ill.othersIllness = InfoseekMasterId['Any_students_family_Other'];
                          }
                        });}}

                        const sleepIssue=InfoseekMasterId['Does_the_student_have_any_sleep_related_issues_Yes'];
                        if(sleepIssue){
                          const somesleepIssue = sleepIssue.split(',');
                
                          if (sleepIssue.length > 0) {
                
                            this.sleeping.forEach(sleep => {
                              const isSelected = somesleepIssue.includes(sleep.name);
                
                              // console.log('disatisfy Name:', sleep.name);
                              // console.log('Is Selected:', isSelected);
                              // this.selectedActivitiesString = selectedActivitiesString;
                              sleep.selected = isSelected;
                              if (sleep.name === 'Other') {
                                sleep.otherssleep = InfoseekMasterId['Sleep_related_issues_Other'];
                              }
                            });}}

                            const currentmedical=InfoseekMasterId['Does_the_student_have_any_medicalissue_Currently_Yes'];
                        if(currentmedical){
                          const somecurrentmedical = currentmedical.split(',');
                
                          if (currentmedical.length > 0) {
                          
                            this.currentproblem.forEach(current => {
                              const isSelected = somecurrentmedical.includes(current.name);
                
                              // console.log('disatisfy Name:', current.name);
                              // console.log('Is Selected:', isSelected);
                              // this.selectedActivitiesString = selectedActivitiesString;
                              current.selected = isSelected;
                              // if (medical.name === 'Other') {
                              //   medical.othersIllness = InfoseekMasterId['Any_students_family_Other'];
                              // }
                            });}}

                            const Pastmedical=InfoseekMasterId['Does_the_student_have_any_medicalissue_In_The_Past_Yes'];
                        if(Pastmedical){
                          const somePastmedical = Pastmedical.split(',');
                
                          if (Pastmedical.length > 0) {
                           
                
                            this.medicalproblem.forEach(medical => {
                              const isSelected = somePastmedical.includes(medical.name);
                
                              // console.log('disatisfy Name:', medical.name);
                              // console.log('Is Selected:', isSelected);
                              // this.selectedActivitiesString = selectedActivitiesString;
                              medical.selected = isSelected;
                              // if (medical.name === 'Other') {
                              //   medical.othersIllness = InfoseekMasterId['Any_students_family_Other'];
                              // }
                            });}}

                            const intoleranting=InfoseekMasterId['Is_the_student_intolerant_to_any_food_group'];
                            if(intoleranting){
                              const someintoleranting = intoleranting.split(',');
                    
                              if (intoleranting.length > 0) {
                               
                    
                                this.Intolerants.forEach(Intolerant => {
                                  const isSelected = someintoleranting.includes(Intolerant.name);
                    
                                  // console.log('disatisfy Name:', Intolerant.name);
                                  // console.log('Is Selected:', isSelected);
                                  // this.selectedActivitiesString = selectedActivitiesString;
                                  Intolerant.selected = isSelected;

                                  // if (Intolerant.name === 'Other') {
                                  //   Intolerant.othersIntolerant = InfoseekMasterId['Food_Group_Other'];
                                  // }
                                });}}

                                const hasPets = InfoseekMasterId['Do_you_have_pets_at_home'];

                                if (hasPets === 'Yes') {
                                  const petInfoString = InfoseekMasterId['Do_you_have_pets_at_home_Yes_Animal'];
                                  const petDurationString = InfoseekMasterId['Do_you_have_pets_at_home_Yes_Duration'];
                                
                                  const petInfoArray = petInfoString.split(',').map((petInfo: string) => petInfo.trim());
                                  const petDurationArray = petDurationString.split(',')
                                    .map((petDuration: string) => {
                                      const parsedDuration = parseInt(petDuration.trim());
                                      return isNaN(parsedDuration) ? 0 : parsedDuration; // Default to 0 if NaN
                                    });
                                
                                  const pets = [];
                                
                                  for (let i = 0; i < petInfoArray.length; i++) {
                                    const petObject = {
                                      animal: petInfoArray[i],
                                      duration: petDurationArray[i],
                                    };
                                    pets.push(petObject);
                                  }
                                
                                  this.petsArray = pets;
                                } else {
                                  this.petsArray = [];
                                }
                                
                              const napTimesString = InfoseekMasterId['What_is_the_students_nap_cycle_during_day_Nap'];

                              // Split the nap times string into individual nap times
                              const napTimesArray = napTimesString.split(',');
                            
                              // Clear existing nap times array
                              this.napTimes = [];
                            
                              // Process nap times and add to nap times array
                              for (const napTime of napTimesArray) {
                                const napLabel = napTime.trim(); // Trim the nap time value
                            
                                const napObject = {
                                  napLabel: napLabel,
                                };
                                this.napTimes.push(napObject);
                              }
                              

                              const pastMedInputString = InfoseekMasterId['Past_Medication'];

                              // Split the nap times string into individual nap times
                              const pastMedInputArray = pastMedInputString.split(',');
                            
                              // Clear existing nap times array
                              this.pastmedications = [];
                            
                              // Process nap times and add to nap times array
                              for (const pastmedication of pastMedInputArray) {
                                const pastMedLabel = pastmedication.trim(); // Trim the nap time value
                            
                                const pmedInputObject = {
                                  pastMedLabel: pastMedLabel,
                                };
                                this.pastmedications.push(pmedInputObject);
                              }

                              const currentMedInputString = InfoseekMasterId['Current_Medication'];

                              // Split the nap times string into individual nap times
                              const currentMedInputArray = currentMedInputString.split(',');
                            
                              // Clear existing nap times array
                              this.currentMedications = [];
                            
                              // Process nap times and add to nap times array
                              for (const currentMedication of currentMedInputArray) {
                                const currentMedLabel = currentMedication.trim(); // Trim the nap time value
                            
                                const cmedInputObject = {
                                  currentMedLabel: currentMedLabel,
                                };
                                this.currentMedications.push(cmedInputObject);
                              }
                              
                              const allergyInputString = InfoseekMasterId['Any_Known_Allergies_yes'];

                              // Split the nap times string into individual nap times
                              if(allergyInputString){
                              const allergyInputArray = allergyInputString.split(',');
                            
                              // Clear existing nap times array
                              this.allergiesList = [];
                            
                              // Process nap times and add to nap times array
                              for (const allergyie of allergyInputArray) {
                                const allergyLabel = allergyie.trim(); // Trim the nap time value
                            
                                const allergyinInputObject = {
                                  allergyLabel: allergyLabel,
                                };
                                this.allergiesList.push(allergyinInputObject);
                              }
                            }

        //  this.selected = InfoseekMasterId['Which_ofthe_following_activities_doesthestudentenjoy'];
         this.sports=InfoseekMasterId['Isthe_student_memberofasports_teamatschool'];
         this.sportsPlay=InfoseekMasterId['Isthe_student_memberofasports_teamatschool_Yes'];
         this.pets=InfoseekMasterId['Do_you_have_pets_at_home'];
         this.mealsPerDay=InfoseekMasterId['Usual_numberof_mealsday'];
         this.UsualNumberofSnacksPerDay=InfoseekMasterId['Usual_numberof_snacksday'];
         this.fluidIntake=InfoseekMasterId['What_is_the_students_average_fluid_intake'];
         this.Typeofmeal=InfoseekMasterId['Type_of_meal'];
         this.othertypeofmeal=InfoseekMasterId['Type_of_meal_Other'];
         this.breakfastRegular=InfoseekMasterId['Does_the_student_have_breakfast_regularly'];
         this.regularmealatschool=InfoseekMasterId['Does_the_student_have_regular_meal_at_school'];
         this.wheredoesitcomefrom=InfoseekMasterId['Student_If_Yes_where_does_it_come_from'];
         this.otherMealatSchool=InfoseekMasterId['Student_If_Other'];
         this.StudentFreshandRelaxedAfterniNghtsSleep=InfoseekMasterId['On_an_average_isthe_student_freshandrelaxed_night_sleep'];
         this.sleepIssue=InfoseekMasterId['Does_the_student_have_any_sleep_related_issues']
        //  this.nap.napLabel=InfoseekMasterId['What_is_the_students_nap_cycle_during_day_Nap']
         this.AverageLengthOfSleepPerNight=InfoseekMasterId['What_is_the_students_average_length_of_sleep_per_night'];
         this.studentPersonalityInformationothers=InfoseekMasterId['Social_personality_Other'];
         this.SocialPersonalityOfTheStudent=InfoseekMasterId['How_would_you_describe_the_social_personality_ofthe_student'];
         this.StudentSelfImageSelfWorth=InfoseekMasterId['How_would_you_rate_the_students_self_image_self_worth'];
         this.selectedNumberrate=InfoseekMasterId['How_would_you_rate_students_cooperation_in_housedhold_chores'];
         this.studentsreactiontochange=InfoseekMasterId['How_would_you_best_describe_the_student_reaction_to_change'];
         this.studentPersonalreactioInformationothers=InfoseekMasterId['Student_reaction_Other'];
         this.AnyIrrationalFearsOrPhobias=InfoseekMasterId['Specify_if_the_student_has_any_irrational_fears_phobias'];
         this.relationshipStudent=InfoseekMasterId['How_would_you_describe_student_rs_with_other_students'];
         this.relationshipStudentothers=InfoseekMasterId['How_would_you_describe_student_rs_with_other_students_Other'];
         this.significantlyclosefriends=InfoseekMasterId['Does_the_student_have_any_significantly_close_friends'];
         this.girlsCount=InfoseekMasterId['student_close_friends_yes_how_many_girls'];
         this.boysCount=InfoseekMasterId['student_close_friends_yes_how_many_boys'];        
         this.StudentGeneralOpinioofSchool=InfoseekMasterId['What_is_student_general_opinion_of_school'];
         this.studentgoestoschool=InfoseekMasterId['The_student_goes_to_school'];
         this.selectedNumber=InfoseekMasterId['How_would_you_rate_student_overall_attendance_at_school'];
         this.selectedNumberHealth=InfoseekMasterId['How_would_you_rate_the_student_overall_physical_health'];
         this.medicalIssue=InfoseekMasterId['Does_the_student_have_any_medicalissue_In_The_Past'];
         this.pastMedication=InfoseekMasterId['Past_Medication'];
         this.medicalIssueCurrently=InfoseekMasterId['Does_the_student_have_any_medicalissue_Currently'];
         this.currentMedication=InfoseekMasterId['Current_Medication'];

        //  this.medications[i]=InfoseekMasterId[''];
        this.allergies=InfoseekMasterId['Any_Known_Allergies'];
        // this.allergy1[i]=InfoseekMasterId[''];
        this.bcg0=InfoseekMasterId['BCG_Dose0'];
        this.bcg1=InfoseekMasterId['BCG_Dose1'];
        this.bcg2=InfoseekMasterId['BCG_Dose2'];
        this.bcg3=InfoseekMasterId['BCG_Dose3'];
        this.bcg4=InfoseekMasterId['BCG_Dose4'];
        this.chickenPox0=InfoseekMasterId['Chicken_Pox_Dose0'];
        this.chickenPox1=InfoseekMasterId['Chicken_Pox_Dose1'];
        this.chickenPox2=InfoseekMasterId['Chicken_Pox_Dose2'];
        this.chickenPox3=InfoseekMasterId['Chicken_Pox_Dose3'];
        this.chickenPox4=InfoseekMasterId['Chicken_Pox_Dose4'];
        this.cholera0=InfoseekMasterId['Cholera_Dose0'];
        this.cholera1=InfoseekMasterId['Cholera_Dose1'];
        this.cholera2=InfoseekMasterId['Cholera_Dose2'];
        this.cholera3=InfoseekMasterId['Cholera_Dose3'];
        this.cholera4=InfoseekMasterId['Cholera_Dose4'];
        this.covid0=InfoseekMasterId['COVID_Vaccination_Dose0'];
        this.covid1=InfoseekMasterId['COVID_Vaccination_Dose1'];
        this.covid2=InfoseekMasterId['COVID_Vaccination_Dose2'];
        this.covid3=InfoseekMasterId['COVID_Vaccination_Dose3'];
        this.covid4=InfoseekMasterId['COVID_Vaccination_Dose4'];
        this.dpt0=InfoseekMasterId['DPT_Dose0'];
        this.dpt1=InfoseekMasterId['DPT_Dose1'];
        this.dpt2=InfoseekMasterId['DPT_Dose2'];
        this.dpt3=InfoseekMasterId['DPT_Dose3'];
        this.dpt4=InfoseekMasterId['DPT_Dose4'];
        this.dt0=InfoseekMasterId['DT_Dose0'];
        this.dt1=InfoseekMasterId['DT_Dose1'];
        this.dt2=InfoseekMasterId['DT_Dose2'];
        this.dt3=InfoseekMasterId['DT_Dose3'];
        this.dt4=InfoseekMasterId['DT_Dose4'];
        this.hepatitis0=InfoseekMasterId['HepatitisA_Dose0'];
        this.hepatitis1=InfoseekMasterId['HepatitisA_Dose1'];
        this.hepatitis2=InfoseekMasterId['HepatitisA_Dose2'];
        this.hepatitis3=InfoseekMasterId['HepatitisA_Dose3'];
        this.hepatitis4=InfoseekMasterId['HepatitisA_Dose4'];
        this.hepatitisb0=InfoseekMasterId['HepatitisB_Dose0'];
        this.hepatitisb1=InfoseekMasterId['HepatitisB_Dose1'];
        this.hepatitisb2=InfoseekMasterId['HepatitisB_Dose2'];
        this.hepatitisb3=InfoseekMasterId['HepatitisB_Dose3'];
        this.hepatitisb4=InfoseekMasterId['HepatitisB_Dose4'];
        this.hpv0=InfoseekMasterId['HPV_Dose0'];
        this.hpv1=InfoseekMasterId['HPV_Dose1'];
        this.hpv2=InfoseekMasterId['HPV_Dose2'];
        this.hpv3=InfoseekMasterId['HPV_Dose3'];
        this.hpv4=InfoseekMasterId['HPV_Dose4'];
        this.influenza0=InfoseekMasterId['Influenza_HIB_Dose0'];
        this.influenza1=InfoseekMasterId['Influenza_HIB_Dose1'];
        this.influenza2=InfoseekMasterId['Influenza_HIB_Dose2'];
        this.influenza3=InfoseekMasterId['Influenza_HIB_Dose3'];
        this.influenza4=InfoseekMasterId['Influenza_HIB_Dose4'];
        this.influenzaV0=InfoseekMasterId['Influenza_Viral_Dose0'];
        this.influenzaV1=InfoseekMasterId['Influenza_Viral_Dose1'];
        this.influenzaV2=InfoseekMasterId['Influenza_Viral_Dose2'];
        this.influenzaV3=InfoseekMasterId['Influenza_Viral_Dose3'];
        this.influenzaV4=InfoseekMasterId['Influenza_Viral_Dose4'];
        this.mmr0=InfoseekMasterId['MMR_Dose0'];
        this.mmr1=InfoseekMasterId['MMR_Dose1'];
        this.mmr2=InfoseekMasterId['MMR_Dose2'];
        this.mmr3=InfoseekMasterId['MMR_Dose3'];
        this.mmr4=InfoseekMasterId['MMR_Dose4'];
        this.mumps0=InfoseekMasterId['Mumps_Measles_Dose0'];
        this.mumps1=InfoseekMasterId['Mumps_Measles_Dose1'];
        this.mumps2=InfoseekMasterId['Mumps_Measles_Dose2'];
        this.mumps3=InfoseekMasterId['Mumps_Measles_Dose3'];
        this.mumps4=InfoseekMasterId['Mumps_Measles_Dose4'];
        this.oralP0=InfoseekMasterId['Oral_Polio_Dose0'];
        this.oralP1=InfoseekMasterId['Oral_Polio_Dose1'];
        this.oralP2=InfoseekMasterId['Oral_Polio_Dose2'];
        this.oralP3=InfoseekMasterId['Oral_Polio_Dose3'];
        this.oralP4=InfoseekMasterId['Oral_Polio_Dose4'];
        this.rotaVirus0=InfoseekMasterId['RotaVirus_Dose0'];
        this.rotaVirus1=InfoseekMasterId['RotaVirus_Dose1'];
        this.rotaVirus2=InfoseekMasterId['RotaVirus_Dose2'];
        this.rotaVirus3=InfoseekMasterId['RotaVirus_Dose3'];
        this.rotaVirus4=InfoseekMasterId['RotaVirus_Dose4'];
        this.teatnus0=InfoseekMasterId['Tetanus_Toxoid_Dose0'];
        this.teatnus1=InfoseekMasterId['Tetanus_Toxoid_Dose1'];
        this.teatnus2=InfoseekMasterId['Tetanus_Toxoid_Dose2'];
        this.teatnus3=InfoseekMasterId['Tetanus_Toxoid_Dose3'];
        this.teatnus4=InfoseekMasterId['Tetanus_Toxoid_Dose4'];
        this.typhoid0=InfoseekMasterId['Typhoid_Dose0'];
        this.typhoid1=InfoseekMasterId['Typhoid_Dose1'];
        this.typhoid2=InfoseekMasterId['Typhoid_Dose2'];
        this.typhoid3=InfoseekMasterId['Typhoid_Dose3'];
        this.typhoid4=InfoseekMasterId['Typhoid_Dose4'];
        this.yellow0=InfoseekMasterId['Yellow_Fever_Dose0'];
        this.yellow1=InfoseekMasterId['Yellow_Fever_Dose1'];
        this.yellow2=InfoseekMasterId['Yellow_Fever_Dose2'];
        this.yellow3=InfoseekMasterId['Yellow_Fever_Dose3'];
        this.yellow4=InfoseekMasterId['Yellow_Fever_Dose4'];
        this.fetchCountry()
        this.getStatesForCountry(this.SelectedCountryId);
        this.getallcitys(this.SelectedstatesId)

  
       
      },
      (error: any) => {
        console.error(error);
      }
    );
    }
    updateFullName(value: string): void {
     
      
      if (this.mobile1_belongs === 'Mother') {
        const mothernm=sessionStorage.getItem('mothersFirstName');
        console.log(mothernm,"mothername");
        this.mobilebelong_Fn = mothernm; // Replace with the actual default name
      } else if (this.mobile1_belongs === 'Father') {
        const fathernm=sessionStorage.getItem('fathersFirstName');
      console.log(fathernm,'fathernm');
        this.mobilebelong_Fn =fathernm ; // Replace with the actual default name
      } else if (this.mobile1_belongs === 'Guardian') {
        this.mobilebelong_Fn = 'Default Guardian Name'; // Replace with the actual default name
      } else if (this.mobile1_belongs === 'Other') {
        // Do nothing or set a default value for other
      }
    }
    
    // In your component class


    

  // getallinfoseek(){

  //   this.nivishservice.getidcard().subscribe(
  //     (data:any)=>{

  //     })
  // }

  // onNextButtonClick() {
  //   // Set the flag to true when the button is clicked
  //   this.nextButtonClicked = true;
  // }

  // showErrorMessages(ill: any): boolean {
  //   // Check if the "Other" checkbox is selected, and if the "Next" button has been clicked
  //   return ill.selected && ill.name === 'Other' && !ill.othersIllness && this.nextButtonClicked;
    
    
  // }
  // showErrorMessagess(disatisfy: any): boolean {
  //   // Check if the "Other" checkbox is selected, and if the "Next" button has been clicked
  //   return disatisfy.selected && disatisfy.name === 'Other' && !disatisfy.othersDissatification && this.nextButtonClicked;
  // }
  
}







  
  
  
  

