import { Component } from '@angular/core';
import { NivishService } from '../nivish.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-id-card',
  templateUrl: './id-card.component.html',
  styleUrls: ['./id-card.component.css']
})
export class IdCardComponent {
  displayName='profile';
  Providerdetails: any;
  provideFullName: any;
  provideDateOfBirth: any;
  provideMobileNumber: any;
  provideEmail: any;
  provideGender: any;
  idCardDetails: any; // Property to hold ID card details
  message: any;
  Niv: any;
  Upload_Your_Photo: any;
  Hcp_qrcode: any;
  dataLoaded = false;
  // provideEmail:any;
  // uinValue: any;
  // studentDOB: any;
  // studentFirstName: any;
  // MobileNumber: any;
  // gender: any;
  // profile: any;
  // qrcode: any;

  constructor(private nivishservice:NivishService){

  }
  ngOnInit() {
    this.getIdCard();
  }
  getIdCard() {
    const updRegId = localStorage.getItem('updRegId');
    console.log(updRegId);
  
    this.nivishservice.gethcpidcard(updRegId).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          console.log(data);
          const result = data['Result'];
  
          if (result) {
            this.provideFullName = result['FullName'] || '';
            console.log(this.provideFullName, "no");
  
            this.provideDateOfBirth = result['Date_of_Birth'] || '';
            this.provideMobileNumber = result['Registered_MobileNumber'] || '';
            this.provideEmail = result['Registered_Email'] || '';
            this.provideGender = result['Gender'] || '';
            this.Niv = result['NIV'] || '';
            this.Upload_Your_Photo = result['Upload_Your_Photo'] || '';
            this.Hcp_qrcode = result['Hcp_qrcode'] || '';
          }
        }
      },
      (error: any) => {
        console.error('Error in gethcpidcard:', error);
        // Handle the error as needed
      }
    );
  }
  public async downloadIdCard() {
    const data = document.getElementById('downloadidcard');
    if (!data) {
      console.error('Element not found');
      return;
    }
  
    console.log('HTML Content:', data.innerHTML);
  
    try {
      const rect = data.getBoundingClientRect();
  
      if (rect.width === 0 || rect.height === 0) {
        data.style.minHeight = '900px'; 
        console.warn('Element has zero dimensions. Applied minimum height.');
  
        const newRect = data.getBoundingClientRect();
        console.log('New element dimensions:', newRect);
  
        if (newRect.width === 0 || newRect.height === 0) {
          throw new Error('Element is not visible or has zero dimensions even after applying minimum height');
        }
      }
  
      // Capture the canvas
      const canvas = await html2canvas(data, {
        useCORS: true,
        scale: 2,
        backgroundColor: null
      });
      console.log('Canvas:', canvas);
  
      const contentDataURL = canvas.toDataURL('image/png');
      console.log('Canvas Data URL:', contentDataURL);
  
      if (!contentDataURL.startsWith('data:image/png')) {
        throw new Error('Failed to capture canvas content');
      }
  
      const pdfWidth = 140; 
      const pdfHeight = 100; 
      const imgWidth = pdfWidth; 
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      const pdf = new jsPDF('l', 'mm', [pdfWidth, pdfHeight]);
      const position = 0;
  
      if (imgHeight > pdfHeight) {
        const numPages = Math.ceil(imgHeight / pdfHeight);
        for (let i = 0; i < numPages; i++) {
          pdf.addImage(contentDataURL, 'PNG', 0, -i * pdfHeight, imgWidth, imgHeight);
          if (i < numPages - 1) {
            pdf.addPage();
          }
        }
      } else {
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      }
  
      // Add profile picture
      const profilePictureSize = 15; 
      const profilePictureUrl = this.Upload_Your_Photo || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAU...';
      if (profilePictureUrl) {
        pdf.addImage(profilePictureUrl, 'PNG', 52, 7, profilePictureSize, profilePictureSize);
        console.log('Added profile picture to PDF');
      }
  
      // Add QR code image
      const qrCodeSize = 15; 
      const qrCodeUrl = this.Hcp_qrcode || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAU...'; 
      if (qrCodeUrl) {
        pdf.addImage(qrCodeUrl, 'PNG', 78, 18, qrCodeSize, qrCodeSize);
        console.log('Added QR code image to PDF');
      }
  
      pdf.save('Infoseek_ID_Card.pdf');
      console.log('PDF saved successfully');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  }
}   

  // getprovideridcard() {
  //   const userId = localStorage.getItem('userId');
  //   if (!userId) {
  //     console.log('userId not found in local storage.');
  //     return;
  //   }

  //   this.nivishservice.getprovideridcard(userId).subscribe(
  //     (data: any) => {
  //       if (data['Status'] === 200) {
  //         const result = data['Result'];
        
  //         if (result) {
  //           this.uinValue = result['NIV'];
  //           console.log('UIN:', this.uinValue);
            
  //           this.studentDOB = result['Date_of_Birth'];
  //           console.log('Date_of_Birth:', this.studentDOB);
            
  //           this.studentFirstName = result['FullName'];
  //           console.log('FullName:', this.studentFirstName);
        
  //           this.gender = result['Gender'];
  //           console.log('Gender:', this.gender);

  //           this.provideEmail = result['GenRegistered_Emailder'];
  //           console.log('Registered_Email:', this.provideEmail);
        
  //           this.MobileNumber = result['Registered_MobileNumber'];
  //           console.log('Registered_MobileNumber:', this.MobileNumber);

  //           this.profile = result['Upload_Your_Photo']
  //           console.log('profilephoto:', this.profile);

  //           this.qrcode = result ['Hcp_qrcode']
  //           console.log('qrcode:',this.qrcode)
     

  //           this.message = 'Get idcard';
  //         }
  //       }
  //       console.log(this.message);
  //       console.log(data);
  //     },
  //     (error) => {
  //       console.error('Error:', error);
  //       // Handle the error here (e.g., show an error message to the user)
  //     }
  //   );
  // }



//   idCardDetails: any;
// message: any;
// provideEmail: any;
// uinValue: any;
// studentDOB: any;
// studentFirstName: any;
// MobileNumber: any;
// gender: any;
// profile: any;
// qrcode: any;

// constructor(private nivishservice: NivishService) {}

// ngOnInit() {
//   this.getprovideridcard();
// }

// getprovideridcard() {
//   const userId = localStorage.getItem('userId');
//   if (!userId) {
//     console.log('userId not found in local storage.');
//     return;
//   }

//   this.nivishservice.getprovideridcard(userId).subscribe(
//     (data: any) => {
//       if (data['Status'] === 200) {
//         const result = data['Result'];

//         if (result) {
//           this.uinValue = result['NIV'];
//           console.log('UIN:', this.uinValue);

//           this.studentDOB = result['Date_of_Birth'];
//           console.log('Date_of_Birth:', this.studentDOB);

//           this.studentFirstName = result['FullName'];
//           console.log('FullName:', this.studentFirstName);

//           this.gender = result['Gender'];
//           console.log('Gender:', this.gender);

//           this.provideEmail = result['GenRegistered_Email'];
//           console.log('Registered_Email:', this.provideEmail);

//           this.MobileNumber = result['Registered_MobileNumber'];
//           console.log('Registered_MobileNumber:', this.MobileNumber);

//           this.profile = result['Upload_Your_Photo'];
//           console.log('profilephoto:', this.profile);

//           this.qrcode = result['Hcp_qrcode'];
//           console.log('qrcode:', this.qrcode);

//           this.message = 'Get idcard';
//         }
//       }
//       console.log(this.message);
//       console.log(data);
//     },
//     (error) => {
//       console.error('Error:', error);

//     }
//   );
// }


