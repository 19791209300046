import { Component, Input } from '@angular/core';
import { NivishService } from '../nivish.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-hcp-id-card',
  templateUrl: './hcp-id-card.component.html',
  styleUrls: ['./hcp-id-card.component.css']
})
export class HCPIdCardComponent {
  displayName='profile';
  Providerdetails: any;
  FullName: any;
  Date_of_Birth: any;
  Registered_MobileNumber: any;
  Registered_Email: any;
  Gender: any;
  Upload_Your_Photo: any;
  Hcp_qrcode: any;
  Message: any;
  NIV: any;
  @Input() stepForm!: FormGroup;



  constructor(private nivishservice:NivishService){

  }
  // ngOnInit(){
  //   this.getIdCard();
  // }

  // getIdCard(){
  //   const verId = localStorage.getItem('verid');


  //   this.nivishservice.providerIdCardGetById(verId).subscribe(
  //     (data:any)=>{
  //       if(data['Status']===200){

  //         console.log(data)
  //         this.Providerdetails = data;
  //         console.log(this.Providerdetails,"swaroop")
  //         if (this.Providerdetails && this.Providerdetails.Result && this.Providerdetails.Result.length > 0) {
  //           this.provideFullName = this.Providerdetails.Result[0].FullName || '';
  //           this.provideDateOfBirth = this.Providerdetails.Result[0].Date_of_Birth || '';
  //           this.provideMobileNumber = this.Providerdetails.Result[0].Registered_MobileNumber || '';
  //           this.provideEmail = this.Providerdetails.Result[0].Registered_Email || '';
  //           this.provideGender = this.Providerdetails.Result[0].Gender|| '';
  //         }

    
  //     }
  //     })
  // }



  ngOnInit(){
    this.getidcard()
  }
  getidcard() {
    const verId = localStorage.getItem('verid');
    console.log(verId, 'prudhvi');
  
    if (!verId) {
      console.log('userId not found in local storage.');
      return;
    }
  
    this.nivishservice.gethcpidcard(verId).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          const result = data.Result;
          console.log(result, '123');
  
          this.NIV = result['NIV'];
          console.log('UIN:', this.NIV);
  
          this.Date_of_Birth = result['Date_of_Birth'];
          console.log('Date of Birth:', this.Date_of_Birth);
  
          this.FullName = result['FullName'];
          console.log('Full Name:', this.FullName);
  
          this.Gender = result['Gender'];
          console.log('Gender:', this.Gender);
  
          this.Registered_MobileNumber = result['Registered_MobileNumber'];
          console.log('Mobile Number:', this.Registered_MobileNumber);
  
          this.Registered_Email = result['Registered_Email'];
          console.log('Email:', this.Registered_Email);
  
          this.Upload_Your_Photo = result['Upload_Your_Photo'];
          console.log('Profile Photo:', this.Upload_Your_Photo);
  
          this.Hcp_qrcode = result['Hcp_qrcode'];
          console.log('QR Code:', this.Hcp_qrcode);
  
          this.Message = 'Get idcard';
        }
        console.log(this.Message);
        console.log(data);
      },
      (error) => {
        console.error('Error:', error);
        // Handle the error here (e.g., show an error message to the user)
      }
    );
  }
  public async downloadIdCard() {
    const data = document.getElementById('downloadidcard');
    if (!data) {
      console.error('Element not found');
      return;
    }
  
    console.log('HTML Content:', data.innerHTML);
  
    try {
      const rect = data.getBoundingClientRect();
  
      if (rect.width === 0 || rect.height === 0) {
        data.style.minHeight = '900px'; 
        console.warn('Element has zero dimensions. Applied minimum height.');
  
        const newRect = data.getBoundingClientRect();
        console.log('New element dimensions:', newRect);
  
        if (newRect.width === 0 || newRect.height === 0) {
          throw new Error('Element is not visible or has zero dimensions even after applying minimum height');
        }
      }
  
      // Capture the canvas
      const canvas = await html2canvas(data, {
        useCORS: true,
        scale: 2,
        backgroundColor: null
      });
      console.log('Canvas:', canvas);
  
      const contentDataURL = canvas.toDataURL('image/png');
      console.log('Canvas Data URL:', contentDataURL);
  
      if (!contentDataURL.startsWith('data:image/png')) {
        throw new Error('Failed to capture canvas content');
      }
  
      const pdfWidth = 140; 
      const pdfHeight = 100; 
      const imgWidth = pdfWidth; 
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
      const pdf = new jsPDF('l', 'mm', [pdfWidth, pdfHeight]);
      const position = 0;
  
      if (imgHeight > pdfHeight) {
        const numPages = Math.ceil(imgHeight / pdfHeight);
        for (let i = 0; i < numPages; i++) {
          pdf.addImage(contentDataURL, 'PNG', 0, -i * pdfHeight, imgWidth, imgHeight);
          if (i < numPages - 1) {
            pdf.addPage();
          }
        }
      } else {
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      }
  
      // Add profile picture
      const profilePictureSize = 15; 
      const profilePictureUrl = this.Upload_Your_Photo || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAU...';
      if (profilePictureUrl) {
        pdf.addImage(profilePictureUrl, 'PNG', 52, 7, profilePictureSize, profilePictureSize);
        console.log('Added profile picture to PDF');
      }
  
      // Add QR code image
      const qrCodeSize = 15; 
      const qrCodeUrl = this.Hcp_qrcode || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAU...'; 
      if (qrCodeUrl) {
        pdf.addImage(qrCodeUrl, 'PNG', 78, 18, qrCodeSize, qrCodeSize);
        console.log('Added QR code image to PDF');
      }
  
      pdf.save('Infoseek_ID_Card.pdf');
      console.log('PDF saved successfully');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  }
}  