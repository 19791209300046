<div class="head">
    <header>
      <div style="display:flex;justify-content: space-between;">
        <img src="assets/images/NIVISH-SchoolHealthProgram_whitebg 1.svg">
       
 


      </div>
    </header>
    <div style="margin-top: 1%;margin-right: 5%;text-align: center;" *ngIf="isLoggedIn()">
     
        <mat-icon (click)="logout()">power_settings_new</mat-icon>
        <div style="color:#fff">Logout</div>
    </div>
</div>