<!-- <div class="main">
    <div class="sub-div">
        <ng-template [ngIf]="displayName==='profile'">>

    <div class="card-container_1">
        <div class="redcard" >
            <div class="top" >
                <p style=" font-size: 38px;margin-bottom: 30px;margin-left: 50px; ">Thank You !<span style="margin-left: 10px;font-size: 20px;">Registration Successful</span></p>
                <div>
                    <img style="width: 40px; margin-top: -120px;  margin-left: -550px;" src="assets/images/thumb.png">
                </div>
                
            </div>
            <div class="whitecardproperties" ></div>
                <div class="whitecard" >
                    <div class="a">
                        <div class="data" >
                            <p style="text-align: center;" >{{FullName}}</p>
                            <p style="text-align: center;font-size: 18px;" >{{Registered_MobileNumber}}</p>
                            <p style="word-break: break-all;font-size: 18px;">{{Registered_Email}}</p>
                        </div>
                    </div>
                    <img style="width: 120px; height: 120px; left: 150px; top: 55px; position: absolute; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); border-radius: 9999px; border: 10px #E9D0CC solid" [src]="Upload_Your_Photo || 'https://via.placeholder.com/240x240'" />
                    

                    <div class="b">
                        <div  style="top: -120px; position: relative; background: white;  ">
                            <div class="c" >
                                <div class="d" >
                                    <p class="e" >{{NIV}}</p>
                                    <div class="f" style="margin-bottom: -10px;" >
                                        <p >DOB :</p>
                                        <p >{{Date_of_Birth}}</p>
                                    </div>
                                    <div class="f" >
                                        <p >Gender :</p>
                                        <p >{{Gender}}</p>
                                    </div>
                                </div>                      
                            </div>
                            
                        </div>
                    </div>
                    <img style="width: 140px; height: 140px; left: 450px; margin-top: 250px; position: absolute; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);border-radius: 20px;  border: 5px #E9D0CC solid" [src]="Hcp_qrcode || 'https://via.placeholder.com/240x240'" />

                    <img style="width: 90px; margin-top: 10px; margin-left: 300px;" src="assets/images/image 3122.png">
                   
                </div>
            </div>
            <div style="position: absolute; left: 590px; top: 650px;">
                <button style="margin-right: 10px; width: 150px; height: 40px;background-color: transparent;"class="rounded-input">Share</button>
                <button style="background-color: #8F1402; color: white; width: 150px; height: 40px;"class="rounded-input">Download</button>
            </div>
            <p class="description" >Novel Implementation of a Vision for an Integrated System of Healthcare</p>
            
        </div>                
       
    

            

        
        

   
</ng-template>
</div>
</div> -->

<div class="main">
    <div class="sub-div">
        <ng-template [ngIf]="displayName==='profile'">>

    <div class="card-container_1">
        <div class="redcard" >
            <div class="top" >
                <p style=" font-size: 38px;margin-bottom: 30px; ">Thank You !</p>
                <span style="margin-left: -10px;font-size: 15px;">Registration Successful</span>
                <div>
                    <img style="width: 70px; margin-top: -100px;  margin-left: -400px;" src="assets/images/thumb.png">
                </div>
               
            </div>

            <div class="whitecardproperties" ></div>
            <div id="downloadidcard">

                <div class="whitecard" >
                    <div class="a">
                        <div class="data" >
                        
                            <h1>{{FullName}}</h1>
                            <p>{{Registered_MobileNumber}}</p>
                            <!-- <p>{{Registered_Email}}</p> -->
                            <p >
                                <ng-container *ngIf="Registered_Email.length <= 24">
                                  {{ Registered_Email }}
                                </ng-container>
                                <ng-container *ngIf="Registered_Email.length > 24">
                                  {{ Registered_Email.substring(0, 24) }}<br>{{ Registered_Email.substring(24) }}
                                </ng-container>
                              </p>
                        </div>
                    </div>
                    
                        <img style="width: 150px; height: 150px; left: 80px; top: 90px; position: absolute; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); border-radius: 9999px; border: 5px #E9D0CC solid" [src]="Upload_Your_Photo || 'https://via.placeholder.com/240x240'" />
                    <div class="b">
                        <div  style="top: -120px; position: relative; background: white;  ">
                            
                            <div class="c" >
                                <div class="data" >

                                   
                                        
                                    <!-- <p style="text-align: center;font-size: 20px;font-weight: 800;" >{{NIV}}</p> 
                                   
                                    <div class="f" style="font-size: 14px;" >
                                        <p >DOB :</p>
                                <p >{{Date_of_Birth}}</p><br>
                                </div>
                                <div class="f" style="font-size: 14px;margin-bottom:10px ;" >
                                        <p >Gender :</p>
                                        <p >{{Gender}}</p> -->
                                    <!-- </div> -->
                                    <h1>{{NIV}}</h1>
                                    <p>DOB : {{Date_of_Birth}}</p>
                                    <p>Gender : {{Gender}}</p>
                                </div>  

                            </div>


                        </div>


                    </div>
                    <img style="width: 150px; height: 150px; left: 360px; margin-top: 210px; position: absolute; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);border-radius: 10px; border: 1px #E9D0CC solid" [src]="Hcp_qrcode || 'https://via.placeholder.com/240x240'" />

                    <img style="width: 110px; margin-top: 10px; margin-left: 110px;" src="assets/images/idlogo.svg">
                    <img style="width: 110px; margin-top: 380px; margin-left: 170px;" src="assets/images/idlogo.svg">

                </div>
        </div>
            </div>

            <div style="position: absolute; left: 602px; top: 670px;">
                <button style="margin-right: 10px; width: 150px; height: 40px;background-color: transparent;"class="rounded-input">Share</button>
                <button style="background-color: #8F1402; color: white; width: 150px; height: 40px;"class="rounded-input"  (click)="downloadIdCard()">Download</button>
            </div>
            <p class="description" >Novel Implementation of a Vision for an Integrated System of Healthcare</p>
        </div>  
                  
</ng-template>
</div>
</div>