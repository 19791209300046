import { Component } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

import { Router,ActivatedRoute, } from '@angular/router';

@Component({
  selector: 'app-review-doctor-second',
  templateUrl: './review-doctor-second.component.html',
  styleUrls: ['./review-doctor-second.component.css']
})
export class ReviewDoctorSecondComponent {

  displayName='medicalcamp';
  showTextField: boolean = false;
comment: string = '';
dropdown: any;
showdoctorprofile: any;
personDateOfBirth: any;
personMobileNumber: any;
personEmail: any;
selectedGender: any;
personFullName: any;
user: any;
license: any;
showFilter: boolean = false;
selectedCheckboxes: string[] = [];
showUIN: any;





toggleCheckbox(checkboxValue: string) {
  const index = this.selectedCheckboxes.indexOf(checkboxValue);
  if (index === -1) {
    this.selectedCheckboxes.push(checkboxValue);
  } else {
    this.selectedCheckboxes.splice(index, 1);
  }
}


removeCheckbox(checkboxValue: string) {
  const index = this.selectedCheckboxes.indexOf(checkboxValue);
  if (index !== -1) {
    this.selectedCheckboxes.splice(index, 1);
  }
}

applyFilter() {
  // Add logic to apply the filter based on the selectedCheckboxes array
  console.log('Selected Checkboxes:', this.selectedCheckboxes);
  // Add your display or filtering logic here
}
  

  toggleTextField() {
    this.showTextField = !this.showTextField;
  }

  saveComment() {
    // Logic to save the comment
  }

  cancelComment() {
    this.showTextField = false; // Close the input field
  }
  
  constructor(private routes:Router){}

  showconformation: boolean=false;
  isPopupOpen = false;
  isProfileOpen = false;
  isOpen = false;
  isOpen_1: boolean = false;

  openPopup_1() {
    this.isOpen_1 = true;
  }

  closePopup_1() {
    this.isOpen_1 = false;
  }


  openPopup() {
    this.isPopupOpen = true;
  }
  
  closePopup() {
    this.isPopupOpen = false;
  }
  Popup() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false
  }
  openProfile() {
    this.isProfileOpen = true;
  }

 
  onRowClickeing(row:any){
    this.displayName='campreview';
  }
  goBack(){
    this.routes.navigate(['/reviewdoctor']);
  }
  goBack1(){
    this.displayName='medicalcamp';
  }
  doctorreview(){
  this.displayName='doctorreview'
 } 
 closemedicalcamp(){
  this.displayName='medicalcamp'
 }
 opencampreview(){
  this.displayName='campreview'
 }
 popupis(){
  this.displayName='popupis'
}
closepopup3(){
  this.isProfileOpen=false
}


 

 toggleFilter(): void {
  this.showFilter = !this.showFilter;
  
  // this.showFilterCard = !this.showFilterCard;
  // console.log(this.filterCaste)

}


}

