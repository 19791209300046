<div class="main">
   
    <div class="section" style="display:flex;">
        <div class="abc">
    <!-- <div class="header">
        <h1 >Register Your Kid</h1></div> -->
    <div class="scroll-bar">
        
        <div class="scroll-content">
            <div class="round">
                <div class="circle"[ngClass]="{'highlighted': sectionCompletion.sectionA}">
            <img src="assets/images/student.png" style="margin-left: 10px;" ></div></div>
            <p (click)="sectionA()" [ngClass]="{'underline': sectionCompletion.sectionA}">A. Student Personal Information</p><br>  
            <div class="healthinformation">
                <div class="circle"[ngClass]="{'highlighted':   sectionCompletion.sectionB}">
            <img src="assets/images/health (2).png" style="margin-left: 10px;"></div></div>
            <p (click)="sectionB()" [ngClass]="{'underline': sectionCompletion.sectionB}" >B. Health Insurance Information</p><br>
            <div class="address">
                <div class="circle" [ngClass]="{'highlighted': sectionCompletion.sectionC}">
            <img src="assets/images/address.png" style="margin-left: 10px;" ></div></div>
            <p (click)="sectionC()" [ngClass]="{'underline': sectionCompletion.sectionC}">C. Address Information</p><br>

            <div class="emergency">
                <div class="circle" [ngClass]="{'highlighted':sectionCompletion.sectionD}" >

            <img src="assets/images/danger.png"  style="margin-left: 10px;" ></div></div>

            <p (click)='sectionD()'[ngClass]="{'underline':sectionCompletion.sectionD}">D. Emergency Contact</p><br>

            <div class="lifestyle" >
                <div class="circle" [ngClass]="{'highlighted': sectionCompletion.sectionE}">
            <img src="assets/images/lifestyle.png"  style="margin-left: 10px;"></div></div>
            <p (click)="sectionE ()"[ngClass]="{'underline': sectionCompletion.sectionE}">E. Basic Lifestyle</p><br>
            <div class="habbits">
                <div class="circle" [ngClass]="{'highlighted': sectionCompletion.sectionF}">
            <img src="assets/images/habbits.png" style="margin-left: 10px;" ></div></div> 
            <p (click)= "sectionF ()" [ngClass]="{'underline':sectionCompletion.sectionF}">F. Dietary Habits</p><br>
            <div class="sleep"> 
                <div class="circle" [ngClass]="{'highlighted': sectionCompletion.sectionG}">
            <img src="assets/images/sleep (2).png" style="margin-left: 10px;"></div></div>
            <p (click)="sectionG ()" [ngClass]="{'underline':sectionCompletion.sectionG}">G. Sleep Pattern & Quality</p><br>
            <div class="Personality">
                <div class="circle" [ngClass]="{'highlighted':sectionCompletion.sectionH}">
                    <img src="assets/images/personality (2).png" style="margin-left: 10px;" ></div></div>
            <p (click)="sectionH()" [ngClass]="{'underline':sectionCompletion.sectionH}">H. Personality & Social Interaction</p><br>
            <div class="school">
                <div class="circle" [ngClass]="{'highlighted': sectionCompletion.sectionI}">
                    <img src="assets/images/school (2).png" style="margin-left: 10px;"></div></div>
            <p (click)="sectionI ()" [ngClass]="{'underline':sectionCompletion.sectionI}">I. Life At School</p><br>
            <div class="general">
                <div class="circle" [ngClass]="{'highlighted':sectionCompletion.sectionJ}">
                    <img src="assets/images/general.png" style="margin-left: 10px;"> </div></div>
            <p (click)="sectionJ ()" [ngClass]="{'underline':sectionCompletion.sectionJ}">J. General History</p><br>
            <div class="family ">
                <div class="circle" [ngClass]="{'highlighted': sectionCompletion.sectionK}">
                    <img src="assets/images/family.png" style="margin-left: 10px;"></div></div>
            <p (click)="sectionK ()" [ngClass]="{'underline':sectionCompletion.sectionK}">K. Family History</p><br>
            <div class="medical">
                <div class="circle" [ngClass]="{'highlighted': sectionCompletion.sectionL}">
                    <img src="assets/images/medical.png" style="margin-left: 10px;"></div></div>
            <p (click)="sectionl()" [ngClass]="{'underline':sectionCompletion.sectionL}">L. Medical History</p><br>
            <div class="immunization">
                <div class="circle" [ngClass]="{'highlighted':sectionCompletion.sectionM}">
                    <img src="assets/images/immunization (2).png" style="margin-left: 10px;"></div></div>
            <p (click)="sectionM ()" [ngClass]="{'underline':sectionCompletion.sectionM}">M. Immunization/Vaccination</p><br>   
            <div class="Consent">
                <div class="circle" [ngClass]="{'highlighted': sectionCompletion.Consent}">
                    <img src="assets/images/consent.svg" style="margin-left: 10px;"></div></div>
            <p (click)="Consent()" [ngClass]="{'underline':sectionCompletion.Consent}"> Consent</p><br>  
        </div>
        
    </div>
</div>              
    <div class="sidebar">
        <ng-template [ngIf]="displayName==='sectionA'">
                <form #sectionaForm="ngForm" (ngSubmit)="sectiona(sectionaForm)"  enctype="multipart/form-data">
            <div class="card-container">
               <div style="display: flex;justify-content: space-between;">
                <h1 class="ba" style="color: #8F1402;">A. Student Personal Information</h1>
                 <div class="profile" *ngIf="studentPhoto">

                  <label for="myFileInput">
                    <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo"  class="imageicon">
                    <div *ngIf="!studentPhotoImageUrl" class="box-text">
                      <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                    </div>
                  </label>
                </div>
              </div>
              <ng-container *ngIf="errorsecA !== '' || picerror">
                <div style="color: red; margin-top: 10px; margin-left: 80px;">{{ errorsecA || picerror }}</div>
            </ng-container>
                <div class="scroll-bar_2">
                <label class="first" style="color:#8F1402;margin-left:75px;">The following information is provided by<span style="color: #FF0000;"> * </span><span>:</span></label>
                <div class="radio-buttons-container">
                    <input type="radio" id="mother"name="mother" [(ngModel)]="studentPersonalInformation" value="Mother" required> 
                    <label for="mother" style="padding-left: 10px;color: #000000;">Mother</label> 
                    <input type="radio" id="Father" name="Father" [(ngModel)]="studentPersonalInformation" class="any" value="Father" style="margin-left: 90px;" required> 
                    <label for="Father" style="padding-left: 10px;color: #000000;">Father</label>
                    <input type="radio" id="Guardian" name="Guardian" [(ngModel)]="studentPersonalInformation" value="Guardian" style="margin-left: 90px;" required> 
                    <label for="Guardian" style="padding-left: 10px;color: #000000;">Guardian</label>
                    <input type="radio" id="Other" name="Other" [(ngModel)]="studentPersonalInformation" value="Other" style="margin-left: 90px;" required> 
                    <label for="Other" style="padding-left: 10px;color: #000000;">Other</label>
                   
               <div class="d">
                <input type="text" [(ngModel)]="studentPersonalInformationothers" name="studentPersonalInformation" *ngIf="studentPersonalInformation === 'Other'" placeholder="If Others,Specify here" class="rounded-input" style= "margin-left: -80px;width: 83%;"  required>
            </div>
            <div class="firstname" style="margin-top: -10px;">
                <label for="studentPhoto" class="sub">Student Photo upload <span style="color: #FF0000;">  *  </span></label><br>
                <!-- <div class="photocard" style="margin-top: 10px;">
                  <input class="choose-file-btn" id="myFileInput" type="file" accept="image/*" id="studentPhotoInput"  name="studentPhoto" value="{{ studentPhotofilename }}" (change)="onStudentPhotoSelected($event)" style="display:hidden;" required>
                  <label >{{ studentPhotofilename || "no file choosen" || studentPhoto }}</label>
                  <div>{{errorupload}}</div>
                </div> -->
                <div class="custom-file-input">
                  <input type="file" id="myFileInput" name="studentPhoto" accept="image/*"  (change)="onProfilePictureSelecte($event)" style="display:none">
                  <label for="myFileInput" class="uploadButton"><p></p>Upload</label>
                  <span class="image-path">{{ studentPhoto?.name || studentPhotofilename || 'No file choosen' }}</span>
                 
                </div>
                <!-- <div *ngIf="picerror">
                  <p style="color: red; margin-top: 10px; margin-left: 50px;">{{ picerror }}</p>
                </div> -->

              </div>
                <div class="firstname" >
                <label for="studentfirstname" class="sub">Student First Name<span style="color: #FF0000;">  *  </span></label><br>
                <input type="text" name="studentfirstname" id="studentfirstname" [(ngModel)]="studentFirstName" placeholder="Type name" size="80" style="background: #f9f9f9;" class="rounded" required></div>
                <div class="middlename" style="margin-top: 10px;">
                <label for="studentmiddlename1" class="sub" style="margin-top: 5px;" >Student Middle Name 1</label><br>
                <input type="text" name="studentmiddlename1" id="studentmiddlename1" [(ngModel)]="studentMiddleName1" placeholder="Type middle name" size="40" style="margin-top: 15px; width: 37%; margin-top: 30px;margin-left: -150px;background: #f9f9f9"class="rounded"><br>
                <label for="studentmiddlename2" class="sub" style="margin-top: 5px;">Student Middle Name 2</label><br>
                <input type="text" name="studentmiddlename2"  id="studentmiddlename2" [(ngModel)]="studentMiddleName2" placeholder="Type middle name" size="40" style="margin-top: 15px; width: 37%;margin-top: 30px; margin-left: -145px;background: #f9f9f9"class="rounded"><br>
            </div>
                 
                <label for="studentlastname" class="sub">Student Last Name<span style="color: #FF0000;">  *  </span></label><br>
                <input type="text" name="studentlastname" id="studentlastname" [(ngModel)]="studentLastName" placeholder="Type name" size="80" style="background: #f9f9f9;" class="rounded" required>
            </div>
            <div class="radio-buttons-container">
                <label for="gender" class="sub" >Gender<span style="color: #FF0000;">  *  </span></label>
            
                <div style="display: flex;margin-top: 10px; ">
                  <input type="radio" id="male" name="male" [(ngModel)]="gender" value="Male" required>
                  <label for="male" style="color: #000;margin-left: 10px;margin-top: -3px;">Male</label>
              
                  <input type="radio" id="female" name="female" [(ngModel)]="gender" value="Female" style="margin-left: 70px;gap:10px;">
                  <label for="female" style="color: #000;margin-left: 10px;margin-top: -3px;">Female</label>
                </div>
              </div>
              
              <div class="DOB">
                <label for="DOB#" class="sub">DOB<span style="color: #FF0000;">  *  </span></label><br>
                <input
                  type="date"
                  id="DOB"
                  name="DOB#"
                  [(ngModel)]="dateofBirth"
                  (ngModelChange)="onDateOfBirthChange($event)"
                  placeholder="DD-MMM-YYYY"
                  size="40"
                  style="margin-bottom: 20px; width: 37%; margin-top: 50px; margin-left: -40px;background: #f9f9f9;"
                  class="rounded"
                  bsDatepicker
                  required
                /><br>
              
                <label for="Age#" class="sub">Age (in years)</label><br>
                <input
                  type="text"
                  id="Age"
                  [value]="calculateAge()"
                  name="age"
                  size="40"
                  style="margin-bottom: 20px; width: 38%; margin-top: 50px; margin-left: -85px; background: #f9f9f9;;"
                  class="rounded"
                  readonly
                />
              </div>
              

            <label class="sub" style="margin-left: 80px; margin-top: -10px;">Blood Group<span style="color: #FF0000;">  *  </span></label>
            <div class="radio-buttons-container">
                

                <input type="radio" id="A" name="A" [(ngModel)]="blood" value="A" checked> 
                <label for="A" style="padding-left: 10px;color:#000000">A </label> 
                <input type="radio" id="B" name="B" [(ngModel)]="blood" value="B" style="margin-left: 90px;" > 
                <label for="B" style="padding-left: 10px;color:#000000">B</label>
                <input type="radio" id="B" name="B" [(ngModel)]="blood" value="AB" style="margin-left: 90px;" > 
                <label for="AB" style="padding-left: 10px;color:#000000">AB</label>
                <input type="radio" id="O" name="O" [(ngModel)]="blood" value="O" style="margin-left: 90px;" > 
                <label for="O" style="padding-left: 10px;color:#000000">O</label>
                <input type="radio" id="NotKnown" name="NotKnown" [(ngModel)]="blood" value="Not Known" style="margin-left: 90px;" > 
                <label for="O" style="padding-left: 10px;color:#000000">Not Known</label>
            </div>
            <div class="radio-buttons-container">
                <label class="sub">Rh Factor<span style="color: #FF0000;">  *  </span></label>
            <div>
                <input type="radio" id="Rhpositive" name="Rhpositive" [(ngModel)]="Rhfactor" value="Rh Positive" checked> 
                <label for="Rhpositive" style="padding-left: 10px;color: #000000; margin-top: 10px;" >Rh Positive </label> 
                <input type="radio" id="Rhnegative" name="Rhnegative" [(ngModel)]="Rhfactor" value="Rh Negative" style="margin-left: 90px;" > 
                <label for="Rhnegative" style="padding-left: 10px; color: #000;">Rh Negitive</label>
                <input type="radio" id="Not Known" name="Not Known" [(ngModel)]="Rhfactor" value="Not Known" style="margin-left: 90px;" > 
                <label for="Rhnegative" style="padding-left: 10px; color: #000;">Not Known</label>
            </div>
            </div><br>
            <div class="relation">
                <!-- <p class="c" style="color:#000000;width:500px;margin-left: 30px;">DOB*</p> -->
                <!-- <label for="NumberOfSisters" class="sub" style="margin-top: 5px;">Number of Sisters </label>
                <br> -->
                <!-- <input type="number" id="NumberOfSisters" name="NumberOfSisters" [(ngModel)]="noofSisters"
                       placeholder="Type here" size="40" style="margin-bottom: 20px; width: 37%; margin-top: 30px; margin-left: -120px; background: #f9f9f9"
                       class="rounded" required ><br> -->
                       
                    <label for="NumberOfSisters" class="sub" style="margin-top: 5px;">Number of Sisters</label><br>
                    <input type="number" id="NumberOfSisters" name="NumberOfSisters" [(ngModel)]="noofSisters" [min]="0" placeholder="Type here" size="40" style="margin-bottom: 20px; width: 37%; margin-top: 30px;margin-left: -120px;background: #f9f9f9"class="rounded" (keypress)="validateInput($event)"><br>
                    <label for="NumberOfBrothers" class="sub" style="margin-top: 5px;">Number of Brothers</label><br>
                    <input type="number" id ="NumberOfBrothers"  [(ngModel)]="noOfBrothers" name="NumberOfBrothers" [min]="0" placeholder="Type here" size="40" style="margin-bottom: 20px; width: 37%;margin-top: 30px; margin-left: -122px;background: #f9f9f9"class="rounded" (keypress)="validateInput($event)"><br>

                    

                </div>
                <div class="integerError">
                    <div *ngIf="(noofSisters !== null && noofSisters < 0) || (noOfBrothers !== null && noOfBrothers < 0)" style="color: red; margin-top: -10px;">
                        {{ 'Please enter a non-negative integer for Number of Sisters and Number of Brothers.' }}
                      </div>
                </div>
                <div class="relation" style="margin-top: -5px;" >
                    <!-- <p class="c" style="color:#000000;width:500px;margin-left: 30px;">DOB*</p> -->
                        <label for="mothersFirstName" class="sub" style="margin-top: 5px;">Mother First Name <span style="color: #FF0000;">  *  </span></label><br>
                        <input type="text" id="mothersFirstName"  [(ngModel)]="mothersFirstName" name="mothersFirstName" placeholder="Type name" size="40" style="margin-bottom: 20px; width: 74%; margin-top: 30px;margin-left: -130px;background: #f9f9f9"class="rounded" required><br>
                        
                    </div> 
                    <div class="relation" style="margin-top: -10px;">
                        <!-- <p class="c" style="color:#000000;width:500px;margin-left: 30px;">DOB*</p> -->
            
                            <label for="motherMiddleName1" class="sub" style="margin-top: 5px;margin-left:0px;"> Mother Middle Name 1  <span style="color: #FF0000;">*</span></label><br>
                            <input type="text" id="motherMiddleName1" name="motherMiddleName1" [(ngModel)]="motherMiddleName1"  placeholder="Type here" size="40" style="margin-bottom: 20px; width:280px; margin-top: 30px;margin-left: -160px;background: #f9f9f9"class="rounded" required><br>
                            <label for="mothersMiddleName2" class="sub" style="margin-top: 5px;margin-left: 0px;">Mother Middle Name 2  <span style="color: #FF0000;">*</span></label><br>
                            <input type="text" id ="mothersMiddleName2"  [(ngModel)]="mothersMiddleName2" name="mothersMiddleName2" placeholder="Type here" size="40" style="margin-bottom: 20px; width: 37%;margin-top: 30px; margin-left: -150px;background: #f9f9f9"class="rounded" required><br>
                        </div>
                <div class="relation" style="margin-top: -10px;">
                    <!-- <p class="c" style="color:#000000;width:500px;margin-left: 30px;">DOB*</p> -->
                        <label for="mothersLastName" class="sub" style="margin-top: 5px;">Mother Last Name <span style="color: #FF0000;">*</span></label><br>
                        <input type="text" id="mothersLastName"  [(ngModel)]="mothersLastName" name="mothersLastName" placeholder="Type name" size="40" style="margin-bottom: 20px; width: 74%; margin-top: 30px;margin-left: -130px;background: #f9f9f9"class="rounded" required><br>
                        
                    </div>
                    
                    <div class="drop-down-container" style="margin-top: -20px;">
                        <label class="sub">Mother Ethnicity<span style="color: #FF0000;">  *  </span></label>
                        <div style="margin-top: -5px;">
                            <select [(ngModel)]="ethnicity" name="motherEthnicity" style="width: 75%; margin-left: -5px; margin-top: 10px;background: #f9f9f9" class="rounded" required>
                                <option value="Asian/Indian">Asian/Indian</option>
                                <option value="Black African">Black African</option>
                                <option value="Arabs">Arabs</option>
                                <option value="Other">Other</option>
                            </select>
                            <input type="text" name="motherOther" [(ngModel)]="motherOthersInput" placeholder="If Others,Specify here" *ngIf="ethnicity === 'Other'" size="80" style="margin-left: -7px; margin-top: 10px; width: 75%;background: #f9f9f9" class="rounded" required>
                        </div>
                    
                    </div><br>
                    <div class="relation" style="margin-top: -20px;"style="margin-top: -10px;">
                        <!-- <p class="c" style="color:#000000;width:500px;margin-left: 30px;">DOB*</p> -->
            
                            <label for="father'sFirstName" class="sub" style="margin-top: 10px;" >Father First Name <span style="color: #FF0000;">  *  </span></label><br>
                            <input type="text" id="father'sFirstName" [(ngModel)]="fathersFirstName" name="father'sFirstName" placeholder="Type here" size="40" style="margin-bottom: 20px; width: 74%; margin-top: 30px;margin-left: -130px;background: #f9f9f9"class="rounded" required><br>
                           
                        </div>
                        <div class="relation"style="margin-top: -10px;">
                            <label for="fatherMiddleName1" class="sub" style="margin-top: 5px;">Father Middle Name 1</label><br>
                                <input type="text" id ="fatherMiddleName1" name="fatherMiddleName1" [(ngModel)]="fatherMiddleName1" placeholder="Type name" size="40" style="margin-bottom: 20px; width: 37%;margin-top: 30px; margin-left: -150px;background: #f9f9f9"class="rounded"><br>
                                <label for="fatherMiddleName2" class="sub" style="margin-top: 5px;margin-left:25px;">Father Middle Name 2</label><br>
                                <input type="text" id ="fatherMiddleName2" name="fatherMiddleName2" [(ngModel)]="fatherMiddleName2" placeholder="Type name" size="40" style="margin-bottom: 20px; width: 36%;margin-top: 30px; margin-left: -160px;background: #f9f9f9"class="rounded"><br>
                        </div>
                        <div class="relation"style="margin-top: -10px;">
                            <!-- <p class="c" style="color:#000000;width:500px;margin-left: 30px;">DOB*</p> -->
                
                                <label for="father'sLastName" class="sub" style="margin-top: 5px;">Father Last Name <span style="color: #FF0000;">  *  </span></label><br>
                                <input type="text" id="father'sLastName" [(ngModel)]="fathersLastName" name="father'sLastName" placeholder="Type here" size="40" style="margin-bottom: 20px; width: 74%; margin-top: 30px;margin-left: -130px;background: #f9f9f9"class="rounded" required><br>
                               
                            </div>
                        <div class="drop-down-container"style="margin-top: -20px;">
                            <label class="sub" >Father Ethnicity<span style="color: #FF0000;">  *  </span></label>
                            <div style="margin-top: -5px;">
                                <select [(ngModel)]="fatherethnicity" name="motherEthnicity" style="width: 75%; margin-left: -7px;background: #f9f9f9" class="rounded" required>
                                    <option value="Asian/Indian">Asian/Indian</option>
                                    <option value="Black African">Black African</option>
                                    <option value="Arabs">Arabs</option>
                                    <option value="Other">Other</option>
                                </select>
                                <input type="text" name="motherOther" [(ngModel)]="fatherOthersInput" placeholder="If Others,Specify here" *ngIf="fatherethnicity === 'Other'" size="80" style="margin-left: -7px; margin-top: 10px; width: 75%;background: #f9f9f9" class="rounded" required>
                            </div>
                        </div>
                        <br>
        </div>  
    </div>

        <div class="row" style="text-align: end; margin-top: 10px;">
            <span class="col">
                <button type="button" class="btn btn-secondary" 
                    style="width: 140px; height: Hug(70px);margin-left: 600px; background-color: #D4D4D4; color: #000000; margin-top: 10px;"
                    (click)="sectionA()">Previous</button>
            </span>
            
            <span class="col">
                <button type="submit" class="btn btn-primary"
                    style="width: 130px; height: Hug(70px); border-radius: 8px; background-color: #8F1402; margin-top: 10px; margin-right: 100px;">Next</button>
            </span>
        </div>
    </form>
    </ng-template>
    <!-- ----------------------sectionB-------------------------------------------------------------------------------------------------------- -->
        
        <ng-template [ngIf]="displayName==='sectionB'">
            <form (ngSubmit)="sectionb()" #insuranceForm="ngForm">
                <div class="card-container">
                  <div style="display: flex;justify-content: space-between;">
                    <h1  class="ba" style="color: #8F1402">B. Health Insurance Information</h1>
                    <div class="profile" *ngIf="studentPhoto">

                      <label for="myFileInput">
                        <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo" class="imageicon" >
                        <div *ngIf="!studentPhotoImageUrl" class="box-text">
                          <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                        </div>
                      </label>
                    </div>
                  </div>
                    <label class="first" style="margin-left: 80px;">Does the Student have health insurance?</label>
                    <div class="radio-buttons-container">
                        <input type="radio" id="Yes" [(ngModel)]="Insurance" name="Insurance" value="Yes" checked>
                        <label for="Yes" style="padding-left: 10px;">Yes</label>
                        <input type="radio" id="No" [(ngModel)]="Insurance" name="Insurance" value="No" style="margin-left: 90px;">
                        <label for="No" style="padding-left: 10px;">No</label>
                    </div>
                
                    <div *ngIf="Insurance === 'Yes'" class="d">
                        <label for="medical_Aid" class="subh">Health Insurer <span style="color: #FF0000;">   *   </span></label><br>
                        <input type="text" id="medical_Aid" name="medical_Aid" [(ngModel)]="mediAid" placeholder="Type here" size="80" style="margin-bottom: 20px; background: #f9f9f9" class="rounded" required><br>
                
                        <label for="policy/card#" class="subh">Policy # <span style="color: #FF0000;">   *   </span></label><br>
                        <input type="text" name="policy/card#" [(ngModel)]="policy" placeholder="Type here" size="80" style="background: #f9f9f9;" class="rounded" required><br>
                
                
                        <label for="Expire date" class="subh" style="margin-top: 10px;">Date of Expiry  <span style="color: #FF0000;">   *   </span> </label><br>
                        <input type="date" id="Expire date" name="Expire date" [(ngModel)]="expireDate" placeholder=" DD-MMM-YYYY" size="80" style="margin-bottom: 20px; background: #f9f9f9" class="rounded" required><br>
                    </div>

        </div>  
        
        <div class="row" style="text-align: end;">
            <span class="col">
                <button type="button" class="btn btn-secondary" 
                    style="width: 140px; height: Hug(70px);margin-left: 600px; background-color: #D4D4D4; color: #000000; margin-top: 20px;"
                    (click)="sectionA()">Previous</button>
            </span>
            
            <span class="col">
                <button type="submit" class="btn btn-primary" [disabled]="!insuranceForm.valid"
                    style="width: 130px; height: Hug(70px); margin-right:100px; border-radius: 8px; background-color: #8F1402; margin-top: 20px;"
                    >Next</button>
            </span>
        </div>
        </form>
    </ng-template>
    <!-- -----------------------------------------       secC      -------------------------------------------- -->

    <ng-template [ngIf]="displayName==='sectionC'">
        <form #sectioncForm="ngForm" (ngSubmit)="sectionc(sectioncForm)">
            <div class="card-container">
            <div style="display: flex;justify-content: space-between;">
            <h1 class="ba" style="color: #8F1402"> C. Address Information</h1>
            <div class="profile" *ngIf="studentPhoto">

              <label for="myFileInput">
                <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo" class="imageicon" >
                <div *ngIf="!studentPhotoImageUrl" class="box-text">
                  <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                </div>
              </label>
            </div>
            </div>
            <ng-container *ngIf="errorsecC !== ''">
                <div style="color: red; margin-top: 10px; margin-left: 80px;">{{ errorsecC }}</div>
            </ng-container>

            <div class="scroll-bar_2">
              <div class="scroll-content_6">
            
           <div class="e">
            <label for="apartmentName"  class="one">Building Name <span class="text-danger">  *  </span></label><br>
            <input type="text" id="apartmentName" name="apartmentName" [(ngModel)]="Apartment_Name" placeholder="Type here" size="60"  style="margin-top: 10px;margin-bottom: 20px;"class="rounded-input" required><br>
    
            <label for="apartmentNumber"  class="subh">Apartment/Villa # <span class="text-danger">  *  </span></label><br>
            <input type="text" id="apartmentNumber" name="apartmentNumber" [(ngModel)]="apartmentNumber" placeholder="Type here" size="60"  style="margin-bottom: 20px;margin-top: 10px;"class="rounded-input" required ><br>

            <label for="Street Address"  class="subh">Street  <span class="text-danger">  *  </span></label><br>
            <input type="text" id="Street Address" name="Street Address" [(ngModel)]="streetAddress" placeholder="Type here" size="60"  style="margin-bottom: 20px; margin-top: 10px;"class="rounded-input" required><br>
            <label for="areaC" class="subh">Area <span class="text-danger">  *  </span></label><br>
            <input type="text" id="areaC" name="areaC" [(ngModel)]="areaC" placeholder="Type here" size="60"  style="margin-bottom: 20px; margin-top: 10px;"class="rounded-input" required>
            <label for="Country"  class="subh" style="margin-top: 00px;">Country<span class="text-danger">  *  </span> <i class="fas fa-map-marker-alt"></i> </label><br>
            <select name="Country" id="Country" class="form-control rounded" [(ngModel)]="SelectedCountryId" (ngModelChange)="onCountryChange($event)" style="width: 100%; background: #f9f9f9;" required>
                <option [value]="country" *ngFor="let country of CountryList; let i = index">{{ country }}</option>
              </select>
              
            <label for="states"  class="subh" style="margin-top: 20px;">State <span class="text-danger">  *  </span> <i class="fas fa-map-marker-alt"></i></label><br>
            <div style="margin-top: 0px;">
                <select name="State" id="State" class="form-control rounded" [(ngModel)]="SelectedstatesId" style="width: 100%; background: #f9f9f9;" required>
                    <option [value]="state" *ngFor="let state of statesList; let i = index">{{ state }}</option>
                  </select>
            </div>
            <label for="city"  class="subh" style="margin-top: 20px;">City<span class="text-danger">  *  </span> <i class="fas fa-map-marker-alt"></i></label><br>
            <div style="margin-top: 5px;">
                <input type="text" name="state" id="state" class="form-control" [(ngModel)]="SelectedcityId" recitysquired (ngModelChange)="oncityChange($event)" class="rounded" style="width: 100%;background: #f9f9f9" required>
                
            </div> 
    
            <label for="ZIP/Postal Code *"  class="subh" style="margin-top: 20px;">ZIP Code  <span class="text-danger">  *  </span></label><br>
            <input type="text" id="ZIP/Postal Code" name="ZIP/Postal Code" [(ngModel)]="postalCode" placeholder="Type here" size="60"  style="margin-bottom: 20px; margin-top: 10px;" class="rounded-input" required><br>
    
            <label for="Phone Number(Landline)"  class="subh">Emergency Mobile number registered with School </label><br>
            <input type="text" name="Phone Number(Landline)" [(ngModel)]="phNumber" placeholder="Type here" size="60"  style="margin-bottom: 20px; margin-top: 10px;"  class="rounded-input" maxlength="15">
            <div *ngIf="phNumber && !isValidMobileNumber(phNumber)" style="color: red; margin-top: 0px;">
                Please enter a valid mobile number (up to 15 digits).
              </div><br>
            <label for="Mother's Phone Number"  class="subh">Alternative Mobile # </label><br>
            <input type="text" id="Mother's Phone Number" name="Mother's Phone Number" [(ngModel)]="motherPhNumber" placeholder="Type here" size="60"  style="margin-bottom: 20px; margin-top: 0px;" class="rounded-input" maxlength="15"><br>
            <div *ngIf="motherPhNumber && !isValidMobileNumber(motherPhNumber)" style="color: red; margin-top: 0px;">
                Please enter a valid mobile number (up to 15 digits).
              </div>
            <label for="alternative#"  class="subh" *ngIf="motherPhNumber">Belongs to  <span style="color: #FF0000;">   *   </span></label>
            <div class="radio-buttons-container" style="margin-left: 2px;" *ngIf="motherPhNumber">
                
                <input type="radio" id="Mothernum"   name="alternative#" [(ngModel)]="alternativeBelongsTo" value="Mother" checked> 
                <label for="Yes" style="padding-left: 10px;color: #000000;" >Mother</label> 
                <input type="radio" id="Fathernum" name="Fathernum" [(ngModel)]="alternativeBelongsTo" value="Father" style="margin-left: 90px;" > 
                <label for="No" style="padding-left: 10px;color: #000000;">Father</label>
                <input type="radio" id="otherNum" name="alternative#" [(ngModel)]="alternativeBelongsTo" value="Guardian" style="margin-left: 90px;" > 
                <label for="No" style="padding-left: 10px;color: #000000;">Guardian</label>
              
            </div>
    
            
    
            <label for="Email(For SHM login)"  class="subh" style="margin-top: 10px;">Email registered with School <span class="text-danger">*</span></label><br>
            <input type="text" id="Email(For SHM login)" name="Email(For SHM login)" [(ngModel)]="emailShm" placeholder="Type here" size="60" style="margin-top: 10px;"  class="rounded-input" [readOnly]="true" required><br>
        </div>
    </div>
    
    
        </div>
    </div>

        
        <div class="row" style="text-align: end;">
            <span class="col">
                <button type="button" class="btn btn-secondary" 
                    style="width: 140px; height: Hug(70px);margin-left: 600px; background-color: #D4D4D4; color: #000000; margin-top: 20px;"
                    (click)="sectionB()">Previous</button>
            </span>
            
            <span class="col">
                <button type="submit" class="btn btn-primary"
                    style="width: 130px; height: Hug(70px);margin-left: -100px; border-radius: 8px; background-color: #8F1402; margin-top: 20px; margin-right: 100px;"
                    >Next</button>
            </span>
        </div>
    
    
    
</form>
    
    </ng-template>

    <!-- ------------------------------------------- secE----------------------------------------------------- -->

    <ng-template [ngIf]="displayName==='sectionE'">
        <form (ngSubmit)="sectione()" >
        <div class="card-container">
          <div style="display: flex;justify-content: space-between;">
            <h1 class="smallheadings"> E. Basic Lifestyle</h1> 
            <div class="profile" *ngIf="studentPhoto">

              <label for="myFileInput">
                <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo" class="imageicon" >
                <div *ngIf="!studentPhotoImageUrl" class="box-text">
                  <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                </div>
              </label>
            </div>
            </div>
            <div class="scroll-bar_2">
                <div class="scroll-content_7">
            
           <div class="e">
           
            <label class="first"  style="color:#000000;margin-left: -10px;font-weight: 500;">What kind of Recreational Activity does the Student enjoy the most?</label>
            <div class="radio-buttons-container" style="margin-left: -4px; color:black;margin-top: 10px;" >
                <input type="radio" id="Outdoor" name="healthInsurance" [(ngModel)]="healthInsurance" value="Outdoor" required>
                <label for="Outdoor" style="padding-left: 10px;">Outdoor</label>

                <input type="radio" id="Indoor" name="healthInsurance" [(ngModel)]="healthInsurance" value="Indoor" style="margin-left: 90px;">
                <label for="Indoor" style="padding-left: 10px;">Indoor</label>
                <input type="radio" id="Both" name="healthInsurance" [(ngModel)]="healthInsurance" value="Both" style="margin-left: 90px;">
                <label for="Both" style="padding-left: 10px;">Both</label>
                <input type="radio" id="Neither" name="healthInsurance" [(ngModel)]="healthInsurance" value="Neither" style="margin-left: 90px;">
                <label for="Neither" style="padding-left: 10px;">Neither</label>
            </div>
            <label  style="color:#000000; margin-left: -5px;margin-top: 20px;font-weight: 500; "  >Which of the following Activities does the Student enjoy?</label>
            <div class="row" style="color:#000000; width:700px">
                <ng-container *ngFor="let activity of activities; let i = index"  >
                  <div class="col-md-4">
                    <label>
                      <input type="checkbox" [(ngModel)]="activity.selected" id="{{activity.name}}" name="{{activity.name}}" style="margin-top: 20px;" > {{ activity.name }}
                    </label>
                    <input type="text" [(ngModel)]="activity.othersInput" name="activityOthers" *ngIf="activity.selected && activity.name === 'Other'" placeholder="Enter other activity"  class="rounded-input" style="width:600px" required>
                    <div *ngIf="showOtherWarning && activity.selected && activity.name === 'Other'" class="text-danger">Please fill in the Input</div>

                  </div> 
                 
                  
                  <!-- Create a new row after every 3 activities -->
                  <div *ngIf="(i + 1) % 3 === 0" class="w-100"></div>
                </ng-container>
              </div>
             
              <!-- <p>Selected Activities: {{ selectedActivitiesString }}</p> -->


                    <label  style="color:#000000;margin-left: -10px;font-weight: 500;margin-top: 20px;">Is the Student a member of any sports team at School?</label>
                    <div class="radio-buttons-container" style="margin-left: -4px; color: #000000;margin-top: 10px;">
                    <input type="radio" id="Yes" name="sports" [(ngModel)]="sports" value="Yes" >
                    <label for="Yes" style="padding-left: 10px;">Yes</label>

                    <input type="radio" id="No" name="sports" [(ngModel)]="sports" value="No" style="margin-left: 150px;">
                    <label for="No" style="padding-left: 10px;">No</label>
                    </div>

                    <input type="text" [(ngModel)]="sportsPlay" name="sportsYes" *ngIf="sports === 'Yes'" placeholder="If yes, which one" class="rounded-input" required>
                    <div *ngIf="nextButtonClicked && sports === 'Yes' && !sportsPlay" class="text-danger">Please specify the Sports</div>

               
<div>
    <label style="color: #000000; margin-left: -12px; font-weight: 500; margin-top: 20px;">Do you have pets at home?</label>
    <div class="radio-buttons-container" style="margin-left: -4px; color: #000000; margin-top: 10px;">
      <input type="radio" id="yes" name="pets" [(ngModel)]="pets" value="Yes" (change)="addFirstPetInput()">
      <label for="yes" style="padding-left: 10px;">Yes</label>
  
      <input type="radio" id="no" name="pets" [(ngModel)]="pets" value="No" style="margin-left: 150px;">
      <label for="no" style="padding-left: 10px;">No</label>
    </div>
  
    <div *ngIf="pets === 'Yes'">
        <div *ngFor="let pet of petsArray; let i = index; trackBy: trackByFn" class="input-container">
          <input type="text" [(ngModel)]="pet.animal" placeholder="Animal {{ i + 1 }}" name="animal{{i}}" class="pets-input">
          <input type="number" [(ngModel)]="pet.duration" placeholder="Duration (in Months)" [min]="0" name="durationAnimal{{i}}" class="pets-duration" (keypress)="validateInput($event)">
          <button class="removePet" type="button" (click)="addPet()">+</button>
          <button class="removePet" (click)="removePet(i)" type="button" *ngIf="petsArray.length > 1">-</button>
        </div>
      </div>
    </div>
  </div>
 </div>
</div>
 </div>
<div class="row" style="text-align: end;">
                            <span class="col">
                                <button type="button" class="btn btn-secondary" 
                                    style="width: 140px; height: Hug(70px);margin-left: 600px; background-color: #D4D4D4; color: #000000; margin-top: 20px;"
                                    (click)="sectionD()">Previous</button>
                            </span>
                            
                            <span class="col">
                                <button type="submit" class="btn btn-primary"
                                    style="width: 130px; height: Hug(70px);margin-right:100px; border-radius: 8px; background-color: #8F1402; margin-top: 20px;" 
                                   >Next</button>
                            </span>
                        </div>
    </form>
    </ng-template>
    <!-- <ng-template [ngIf]="displayName==='Consent'" >
        <form (ngSubmit)="submitForm()" enctype="multipart/form-data">
        <div class="card-container">
            <h1 class="b">Consent</h1>
            <div class="scroll-bar_3">
                <div class="scroll-content_5">
                    <div class="page" style="color: #000;">
                        
                        <p>Sign and put date in the last section of the page below the consent section in the space provided.</p><br>
                        <p>You will be notified of the receipt and a second confirmation will be sent via email.</p><br>
                        <p><b>Important:</b>Once saved or/and printed, you are advised to take utmost care of the InfoSeek form as the information contained therein is confidential. Neither the school nor the NIVISH takes responsibility of the printed form <input type="text" [(ngModel)]="notedates" name="notedates" 
                            class="rounded-input" 
                            [min]="minDate" [max]="notedates" style="max-width: 120px; margin-left:5px" 
                            readonly class="rounded-input"> </p><br>
                
                        <br>
                        <p><span class="text-danger">*</span>I <input type="text" name="parentsname" placeholder="Form Filled BY Full Name" style="  max-width: 120px;  " [(ngModel)]="parentsname" class="rounded-input" required> parents/Guardian of <input type="text" placeholder="Student Full Name" name="studentname" [(ngModel)]="studentname"style="  max-width: 120px;  "  class="rounded-input" required> of Class <input type="text" name="typeclass" placeholder="Type Class" [(ngModel)]="typeclass" style="  max-width: 120px;  "  class="rounded-input" required> <br>
                            do hearby give my consent to NIVISH to :<br> </p>
                            <ul class="consent">
                                <li>Create my child's/ ward's electronic health record.</li>
                                <li>Update the electronic health record with the information provided in the Infoseek questionnaire and with information gathered from the student/Parent/ school/ teacher from time to time.</li>
                                <li>Process and analyze the information/ data gathered and recorded through the school health management program for the benefit of the student / Parent , to better understand the health and wellness of the child.</li>
                              </ul>
                    </div> 
                        <p style="color: #000;"><span class="text-danger">*</span>Signature of                                  
                            <input type="radio" id="Mother" name="Mother" [(ngModel)]="signature" name="signature" [value]="'Mother'" style="margin-left: 30px;">
                            <label for="Mother" style="padding-left: 10px; color: black; margin-right: 20px;">Mother</label>
                            
                            <input type="radio" id="Father" name="Father" [(ngModel)]="signature" name="signature" [value]="'Father'">
                            <label for="Father" style="padding-left: 10px; color: black; margin-right: 20px;">Father</label>
                            
                            <input type="radio" id="Guardian" name="Guardian" [(ngModel)]="signature" name="signature" [value]="'Guardian'">
                            <label for="Guardian" style="padding-left: 10px; color: black;">Guardian</label>
                            
                        </p><br>
                        
                        <p style="color: #000;"><span class="text-danger">*</span>Upload your digital Sign</p>
                        <div class="sign">
                            <div class="image-upload-container">
                              <input
                                type="file"
                                accept="image/*"
                                id="digitalSignInput"
                                (change)="onProfilePictureSelected($event)"
                                style="display: none;"
                              />
                              <label for="digitalSignInput" class="image-box">
                                <img
                                  *ngIf="digitalSignImageUrl"
                                  [src]="digitalSignImageUrl"
                                  alt="Selected Digital Sign"
                                  class="preview-image"
                                />
                                <div  *ngIf="!digitalSignImageUrl" class="box-text">
                                  <img src="assets/images/imageupload.png"  style="margin-left: 0px;" alt="Upload Icon" class="upload-icon" />
                                </div>
                              </label>
                            </div>
                          </div>
                          
                          <div class="studentsign">
                            <p style="color: #000;"> <span class="text-danger">*</span>Upload the Student photo</p>
                          </div>
                          <div class="sign">
                          <div class="image-upload-container">
                            <input
                              type="file"
                              accept="image/*"
                              id="studentPhotoInput"
                              (change)="onStudentPhotoSelected($event)"
                              style="display: none;"
                            />
                            <label for="studentPhotoInput" class="image-box">
                              <img
                                *ngIf="studentPhotoImageUrl"
                                [src]="studentPhotoImageUrl"
                                alt="Selected Student Photo"
                                class="preview-image"
                              />
                              <div *ngIf="!studentPhotoImageUrl" class="box-text">
                                <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-icon" />
                              </div>
                            </label>
                          </div>
                        </div>
                        <p>
                            <p style="color: #000;">
                                <span class="text-danger">*</span>
                                Date
                                <input type="text" [(ngModel)]="notedate" name="submittedDt" 
                                       class="rounded-input" 
                                       [min]="minDate" [max]="maxDate" style="max-width: 120px; margin-left:5px" 
                                       readonly class="rounded-input">
                            </p>                                         
                        <br>
                         <p style="color: #000;"><span class="text-danger">*</span>Place   <input type="text" [(ngModel)]="noteplace" name="noteplace" placeholder="Type location"style="  max-width: 120px; margin-lrft:5px "  class="rounded-input" > 
                        </p>                            
                        <br>
                        <p style="color: #000;"><input type="checkbox" id="policies"   name="policies" value="policies"  [(ngModel)]="policies"><span class="text-danger">*</span>I have read the policies  and I provide my consent to NIVISH.
                        </p>                            
                        <br>
                   
                </div>
            </div>
        </div>
          
        
        <div class="row" style="text-align: end;">
            <ng-container >
                <button type="button" class="btn btn-secondary" 
                style="border-color: #cf3e3e;width: 120px;height: hug(70px);top: 973px; background-color: #D4D4D4;color:  #000000;margin-top:20px ;margin-left: 40px;">Preview</button>
            </ng-container>
            <ng-container class="col">
                <button type="button" class="btn btn-secondary" 
                    style="width: 140px; height: Hug(70px); background-color: #D4D4D4; color: #000000; margin-top: 20px;margin-left:450px;;"
                    (click)="sectionM()">Previous</button>
                    <span class="col">
                        <button type="submit" class="btn btn-primary"
                            style="width: 130px; height: Hug(70px);margin-right:100px; border-radius: 8px; background-color: #8F1402; margin-top: 20px;"
                            >Next</button>
                    </span>
            </ng-container>
            
            
        </div>
        </form>
    </ng-template> -->
    <ng-template [ngIf]="displayName==='Consent'">
        <form (ngSubmit)="submitForm()" enctype="multipart/form-data">
          <div class="card-container">
            <div style="display: flex;justify-content: space-between;">
            <h1 class="consentheading">Consent</h1>
            <div class="profile" *ngIf="studentPhoto">

              <label for="myFileInput">
                <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo" class="imageicon" >
                <div *ngIf="!studentPhotoImageUrl" class="box-text">
                  <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                </div>
              </label>
            </div>
          </div>
            <div class="scroll-bar_2">
              <div class="scroll-content_5">
                <div class="page" style="color: #000;">
                  <p>Sign and put date in the last section of the page below the consent section in the space provided.</p>
                  <br>
      
                  <p>You will be notified of the receipt and a second confirmation will be sent via email.</p>
                  <br>
      
                  <p>
                    <b>Important:</b> Once saved or/and printed, you are advised to take utmost care of the InfoSeek form as the information
                    contained therein is confidential. Neither the school nor the NIVISH takes responsibility of the printed form
                    <input type="text" [(ngModel)]="notedates" name="notedates" class="rounded-input" [min]="minDate" [max]="notedates" style="max-width: 120px; margin-left:5px" readonly class="rounded-input">
                  </p>
                  <br>
      
                  <p style="color: #000;"><span class="text-danger">  *  </span>I <input type="text" name="parentsname" placeholder="Form Filled BY Full Name" style="max-width: 200px;" [(ngModel)]="parentsname" class="rounded-input" required> Parents/Guardian of <input type="text" placeholder="Student Full Name" name="studentname" [(ngModel)]="studentname"style="max-width: 200px;" class="rounded-input" required> of Class <input type="text" name="typeclass" placeholder="Type Class" [(ngModel)]="typeclass" style="max-width: 200px;margin-top: 5px;" class="rounded-input" required>
                   
                    do hearby give my consent to NIVISH to:
                  </p>
      
                  <ol class="consent">
                    <li>Create my child's/ ward's electronic health record.</li>
                    <li>Update the electronic health record with the information provided in the Infoseek questionnaire and with information gathered from the Student/ Parent/ School/ Teacher from time to time.</li>
                    <li>Process and analyze the information/ data gathered and recorded through the school health management program for the benefit of the Student / Parent , to better understand the health and wellbeing of the child.</li>
                  </ol>
      
                  <p style="color: #000;"><span class="text-danger">  *  </span>Signature of 
                    <input type="radio" id="Mother" name="Mother" [(ngModel)]="signature" name="signature" [value]="'Mother'" style="margin-left: 30px;">
                    <label for="Mother" style="padding-left: 10px; color: black; margin-right: 20px;">Mother</label>
                    
                    <input type="radio" id="Father" name="Father" [(ngModel)]="signature" name="signature" [value]="'Father'">
                    <label for="Father" style="padding-left: 10px; color: black; margin-right: 20px;">Father</label>
                    
                    <input type="radio" id="Guardian" name="Guardian" [(ngModel)]="signature" name="signature" [value]="'Guardian'">
                    <label for="Guardian" style="padding-left: 10px; color: black;">Guardian</label></p>
                  <br>
      
                  <p style="color: #000;">
                    <span class="text-danger">  *  </span>Upload your digital Sign
                  </p>
      
                  <div class="sign" >
                    <div class="image-upload-container">
                      <input type="file" accept="image/*" id="digitalSignInput" name="selectedUserProfile" (change)="onProfilePictureSelected($event)" style="display: none;" required>
                      <label for="digitalSignInput" class="image-box">
                        <img *ngIf="digitalSignImageUrl" [src]="digitalSignImageUrl" alt="Selected Digital Sign" class="preview-image">
                        <div *ngIf="!digitalSignImageUrl" class="box-text">
                          <img src="assets/images/signature.png" alt="Upload Icon" class="upload-icon">
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
      
                
      
                
                      
                      <p style="color: #000;">
                        <span class="text-danger">  *  </span>Date
                        <input type="text" [(ngModel)]="notedate" name="submittedDt" class="rounded-input" [min]="minDate" [max]="maxDate" style="max-width: 120px; margin-left:18px;margin-top: 12px; " readonly class="rounded-input">
                      </p>
                      
                      <p style="color: #000;"><span class="text-danger">  *  </span>Place <input type="text" [(ngModel)]="noteplace" name="noteplace" placeholder="Type location" style="max-width: 120px; margin-left:5px " class="rounded-input"> </p>
                      
                      <p style="color: #000;">
                        <input type="checkbox" id="policies" name="policies" value="policies" [(ngModel)]="policies">
                        <span class="text-danger">  *  </span>I have read the policies and I provide my consent to NIVISH.
                      </p>
                      
                      
                      </div>
                      </div>
                      </div>
                      <div class="row" style="text-align: end;">
                        <ng-container>
                          <button type="button" class="btn btn-secondary" style="border-color: #cf3e3e;width: 120px;height: hug(70px);top: 973px; background-color: #D4D4D4;color:  #000000;margin-top:20px ;margin-left: 40px;">Preview</button>
                        </ng-container>
                      
                        <ng-container class="col">
                          <button type="button" class="btn btn-secondary" style="width: 140px; height: Hug(70px); background-color: #D4D4D4; color: #000000; margin-top: 20px;margin-left:450px;" (click)="sectionM()" >Previous</button>
                          <span class="col">
                            <button type="submit" class="btn btn-primary" style="width: 130px; height: Hug(70px);margin-right:100px; border-radius: 8px; background-color: #8F1402; margin-top: 20px;">Next</button>
                          </span>
                        </ng-container>
                      </div>
                      </form>
                      </ng-template>
                      
      

    <!-- -------------------------------------------  secD  ----------------------------------------------- -->
    <ng-template [ngIf]="displayName==='sectionD'">
        <form #sectiondForm="ngForm" (ngSubmit)="sectiond(sectiondForm)">
            <div class="card-container">
              <div style="display: flex;justify-content: space-between;">
          <h1 class="b" style="margin-left: 10px;">D. Emergency Contact as registered with School</h1>
          <div class="profile" *ngIf="studentPhoto">

            <label for="myFileInput">
              <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo" class="imageicon" >
              <div *ngIf="!studentPhotoImageUrl" class="box-text">
                <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
              </div>
            </label>
          </div>
          </div>
          <ng-container *ngIf="errorsecD !== ''">
            <div style="color: red; margin-top: 10px; margin-left: 80px;">{{ errorsecD }}</div>
        </ng-container>
          <div class="scroll-bar_2">
            <div class="scroll-content_3">
              
                  <div class="col">
                    <label for="Mobile" class="first">Primary Contact #<span class="text-danger">  *  </span></label><br> 
                    <input type="text" id="Mobile" name="Mobile" [(ngModel)]="mobile_1" placeholder="Type here"style="margin-bottom: 20px; max-width: 600px;margin-top:10px "  class="rounded-input" required maxlength="15">
                  </div>
                  <div *ngIf="mobile_1 && !isValidMobileNumber(mobile_1)" style="color: red; margin-top: 0px;">
                    Please enter a valid mobile number (up to 15 digits).
                  </div>
                  <div style="display: flex;">
                  <label class="subh">Primary Contact belongs to <span class="text-danger">  *  </span>  </label>
                  <div *ngIf="sectiondForm.submitted && sectiondForm.controls['Mother_1'].hasError('required') && sectiondForm.controls['Father_1'].hasError('required') && sectiondForm.controls['Guardian_1'].hasError('required') && sectiondForm.controls['Others_1'].hasError('required')">
                    <p style="color: red;">(Please choose one of the Below !)</p>
                  </div>
                </div>
                  <div class="radio-buttons-container_1">
                    <input type="radio" id="Mother_1" name="Mother_1" value="Mother" [(ngModel)]="mobile1_belongs" (change)="updateFullName('Mother')" required>
                    <label for="Mother_1" value="Mother" style="padding-left: 10px; color: black; margin-top: 10px;" >Mother</label>
                    <input type="radio" id="Father_1" name="Father_1" value="Father" style="margin-left: 90px;" [(ngModel)]="mobile1_belongs"  (change)="updateFullName('Father')"required>
                    <label for="Father_1" style="padding-left: 10px; color: black;">Father</label>
                    <input type="radio" id="Guardian_1" name="Guardian_1" value="Guardian" style="margin-left: 90px;" [(ngModel)]="mobile1_belongs" required>
                    <label for="Guardian_1" style="padding-left: 10px; color: black;">Guardian</label>
                    <input type="radio" id="Others_1" name="Others_1" value="Other" style="margin-left: 90px;" [(ngModel)]="mobile1_belongs" required>
                    <label for="Others_1" style="padding-left: 10px; color: black;">Other</label>
                    <input
                      type="text"
                      [(ngModel)]="mobil1eothers"
                      *ngIf="mobile1_belongs === 'Other'"
                      placeholder="If Others, Specify *"
                      class="rounded-input"
                      name="mobil1eothers"
                      style="margin-left: 0px; width: 81%; margin-top: 5px;"
                      required
                    >
                  
                    
                  
                  </div>
                  
                <div class="row">
                  <div class="col">
                    <label for="First_Name" class="subh" style="margin-top: 10px;" >Full Name <span class="text-danger">  *  </span></label><br>
                    <input type="text" id="First_Name" name="First_Name" [(ngModel)]="mobilebelong_Fn" placeholder="Type here" style="max-width: 600px; margin-bottom: 20px; margin-top: 10px;" class="rounded-input" required>
                  </div>
                 
                </div>
                  <!-- <div class="col">
                    <label for="Mobile2" class="subh">Secondary Contact</label><br>
                    <input type="text" id="Mobile2" name="Mobile2" [(ngModel)]="mobile_2" placeholder="Type here"style="margin-bottom: 20px; max-width: 1000px;max-width: 600px;margin-top: 10px;" class="rounded-input">
                  </div>
                  <div *ngIf="mobile_2">

                  <label class="subh">Secondary contact belongs to </label>

                  <div class="radio-buttons-container_1 ">
                    <input type="radio" id="SecondaryContact" name="SecondaryContact" value="Mother" [(ngModel)]="mobile2_belongs" >
                    <label for="Mother" style="padding-left: 10px; color: black; margin-top: 10px;" >Mother </label> 
                    <input type="radio" id="Father" name="Father" value="Father" style="margin-left: 90px;"[(ngModel)]="mobile2_belongs"  > 
                    <label for="Father" style="padding-left: 10px; color: black;">Father</label>
                    <input type="radio" id="Guardian" name="Guardian" value="Guardian" style="margin-left: 90px;"[(ngModel)]="mobile2_belongs"  > 
                    <label for="Guardian" style="padding-left: 10px; color: black;">Guardian</label>
                    <input type="radio" id="Others" name="Others" value="Other" style="margin-left: 90px;"[(ngModel)]="mobile2_belongs"  > 
                    <label for="Others" style="padding-left: 10px; color: black;">Other</label> 
                    <input type="text" [(ngModel)]="mobil2eothers" [ngModelOptions]="{standalone: true}" *ngIf="mobile2_belongs === 'Other'" placeholder="If yes, which one" class="rounded-input" style= "margin-left: 0px;width: 81%; margin-top: 5px;">

                </div>
                
                <div class="row">
                  <div class="col">
                    <label for="First_Name2" class="subh"  style="margin-top: 10px;">Full Name <span class="text-danger">*</span></label><br>
                    <input type="text" id="First_Name2" name="First_Name2" [(ngModel)]="mobile2belong_Fn" placeholder="Type here"style="max-width: 600px;margin-bottom: 20px;margin-top: 10px" class="rounded-input" required>
                  </div>
                </div>

                </div> -->
                <div class="col">
                    <label for="Mobile2" class="subh">Secondary Contact #</label><br>
                    <input type="text" id="Mobile2" name="Mobile2" [(ngModel)]="mobile_2" placeholder="Type here"style="margin-bottom: 20px; max-width: 1000px;max-width: 600px;margin-top: 10px;" class="rounded-input" maxlength="15">
                  </div>
                  <div *ngIf="mobile_2 && !isValidMobileNumber(mobile_2)" style="color: red; margin-top: 0px;">
                    Please enter a valid mobile number (up to 15 digits).
                  </div>
                  <div *ngIf="mobile_2">
                   
                  <label class="subh">Secondary Contact belongs to <span style="color: #FF0000;">   *   </span></label>

                  <div class="radio-buttons-container_1 ">
                    <input type="radio" id="SecondaryContact" name="SecondaryContact" value="Mother" [(ngModel)]="mobile2_belongs" >
                    <label for="Mother" style="padding-left: 10px; color: black; margin-top: 10px;" >Mother </label> 
                    <input type="radio" id="Father" name="Father" value="Father" style="margin-left: 90px;"[(ngModel)]="mobile2_belongs"  > 
                    <label for="Father" style="padding-left: 10px; color: black;">Father</label>
                    <input type="radio" id="Guardian" name="Guardian" value="Guardian" style="margin-left: 90px;"[(ngModel)]="mobile2_belongs"  > 
                    <label for="Guardian" style="padding-left: 10px; color: black;">Guardian</label>
                    <input type="radio" id="Others" name="Others" value="Other" style="margin-left: 90px;"[(ngModel)]="mobile2_belongs"  > 
                    <label for="Others" style="padding-left: 10px; color: black;">Other</label> 
                    <input type="text" [(ngModel)]="mobil2eothers" name="mobil2eothers" *ngIf="mobile2_belongs === 'Other'" placeholder="If Others,Specify" class="rounded-input" style= "margin-left: 0px;width: 81%; margin-top: 5px;" required>

                </div>
                
                <div class="row">
                  <div class="col">
                    <label for="First_Name2" class="subh"  style="margin-top: 10px;">Full Name <span class="text-danger">  *  </span></label><br>
                    <input type="text" id="First_Name2" name="First_Name2" [(ngModel)]="mobile2belong_Fn" placeholder="Type here"style="max-width: 600px;margin-bottom: 20px;margin-top: 10px" class="rounded-input" required>
                  </div>
                
                </div>
                  </div>
                  <div class="col">
                    <label for="Family_Doctor_Name" class="subh">Family Doctor Name </label><br>
                    <input type="text" id="Family_Doctor_Name" name="Family_Doctor_Name" [(ngModel)]="familyDocName" placeholder="Type here"  style="margin-bottom: 20px;max-width: 600px;margin-top: 10px" class="rounded-input" >
                  </div>
      
                  <div class="col">
                    <label for="Contact_Number" class="subh">Doctor Contact #
                        
                        
                        </label><br>
                    <input type="text" id="Contact_Number" [(ngModel)]="docContactNumber" name="Contact_Number" placeholder="Type here" style="max-width: 600px;margin-top: 10px" class="rounded-input" maxlength="15">
                  </div>
                  <div *ngIf="docContactNumber && !isValidMobileNumber(docContactNumber)" style="color: red; margin-top: 0px;">
                    Please enter a valid mobile number (up to 15 digits).
                  </div>
            </div>
          </div>
        
        </div>
        <div class="row" style="text-align: end;">
            <span class="col">
                <button type="button" class="btn btn-secondary" 
                    style="width: 140px; height: Hug(70px);margin-left: 600px; background-color: #D4D4D4; color: #000000; margin-top: 20px;"
                    (click)="sectionC()">Previous</button>
            </span>
            
            <span class="col">
                <button type="submit" class="btn btn-primary"
                    style="width: 130px; margin-right:100px;height: Hug(70px); border-radius: 8px; background-color: #8F1402; margin-top: 20px;"
                    >Next</button>
            </span>
        </div>
        </form>
      </ng-template>

      <ng-template [ngIf]="displayName==='sectionF'">
        <form (ngSubmit)="sectionf()">
        <div class="card-container">
          <div style="display: flex;justify-content: space-between;">
            <h1 class="smallheadings">F. Dietary Habits</h1>
            <div class="profile" *ngIf="studentPhoto">

              <label for="myFileInput">
                <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo" class="imageicon" >
                <div *ngIf="!studentPhotoImageUrl" class="box-text">
                  <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                </div>
              </label>
            </div>
          </div>
            <div class="scroll-bar_2">
                <div class="scroll-content_8">
                  
                    <div class="col">
                        <label class="first" for="meals" style="color: #000000;">Usual number of meals per Day (Eg: Breakfast, Lunch, Dinner)</label><br>
                        <input type="number" id="meals" name="meals" [(ngModel)]="mealsPerDay" (input)="validateNumber($event)" [min]="0" placeholder="Type here" style="max-width: 600px; margin-top: 10px" class="rounded-input" (keypress)="validateInput($event)">
                        <div *ngIf="showMealsError" style="color: red;">Please enter number only</div>
                      </div> 
                      <div class="row">
                        <div class="col">
                          <label class="sub" for="snacks/day" style="color: #000000; ">Usual number of snacks per Day </label><br>
                          <input type="number" id="snacks/day" name="snacks/day" [(ngModel)]="UsualNumberofSnacksPerDay" [min]="0" (input)="validatesnacks($event)" placeholder="Type here" style="max-width: 290px;margin-top: 10px" class="rounded-input" (keypress)="validateInput($event)">
                          <div *ngIf="showsnacksError" style="color: red;">Please enter number only</div>
                        </div>
                        <div class="col" style="margin-left: -175px;">
                          <label class="sub" for="avgFluid" style="color: #000000; "> Average total fluid intake per Day (in mL) </label><br>
                          <input type="number" id="avgFluid" name="avgFluid" [(ngModel)]=" fluidIntake" placeholder="Type here" [min]="0" (input)="validatefluid($event)" style="max-width: 300px;margin-top: 10px" class="rounded-input" (keypress)="validateInput($event)">
                          <div *ngIf="showsfluidError" style="color: red;">Please enter number only</div>

                        </div>
                      </div>
                      <div class="radio-buttons-container-1">
                        <label class="sub" >Type of meal</label>
                        <div style="display: flex; flex-wrap: wrap;margin-top: 10px;">
                            <div style="flex: 33.33%; padding-right: 10px; ">
                                <input type="radio" id="Vegetarian" name="Vegetarian" [(ngModel)]="Typeofmeal" value="Vegetarian" checked>
                                <label for="Vegetarian" style="color: #000000; margin-left: 10px;">Vegetarian</label>
                            </div>
                            <div style="flex: 33.33%; padding-right: 10px;margin-top: 0px;">
                                <input type="radio" id="Non-Vegetarian" name="Non-Vegetarian" [(ngModel)]="Typeofmeal" value="Non Vegetarian">
                                <label for="Non-Vegetarian" style="color: #000000;margin-left: 10px;">Non-Vegetarian</label>
                            </div>
                            <div style="flex: 33.33%; padding-right: 10px;">
                                <input type="radio" id="Vegan" name="Vegan" [(ngModel)]="Typeofmeal" value="Vegan">
                                <label for="Vegan" style="color: #000000;margin-left: 10px;">Vegan</label>
                            </div>
                        </div>
                        <div style="display: flex; flex-wrap: wrap; margin-top: 20px;">
                            <div style="flex: 33.33%; padding-right: 10px;">
                                <input type="radio" id="vegwdEgg" name="vegwdEgg" [(ngModel)]="Typeofmeal" value="Vegetarian with Eggs">
                                <label for="vegwdEgg" style="color: #000000; margin-left: 10px;">Vegetarian with Eggs</label>
                            </div>
                            <div style="flex: 33.33%; padding-right: 10px;">
                                <input type="radio" id="vegwdFish" name="vegwdFish" [(ngModel)]="Typeofmeal" value="Vegetarian with Fish">
                                <label for="vegwdFish" style="color: #000000;margin-left: 10px;">Vegetarian with Fish</label>
                            </div>
                            <div style="flex: 33.33%; padding-right: 10px;">
                                <input type="radio" id="Organic" name="Organic" [(ngModel)]="Typeofmeal" value="Organic">
                                <label for="Organic" style="color: #000000;margin-left: 10px;">Organic</label>
                            </div>
                        </div>
                       
                        <div style="display: flex; flex-wrap: wrap; margin-top: 20px;">
                            <div style="flex: 66.66%; padding-right: 10px;">
                                <input type="radio" id="Othersfood" name="Othersfood" [(ngModel)]="Typeofmeal" value="Other" (ngModelChange)="resetOtherValidation()">
                                <label for="Othersfood" style="color: #000000;margin-left: 10px;">Other</label>
                                
                                
                            </div>
                            <div class="d" style="flex: 33.33%;">
                                <input type="text" [(ngModel)]="othertypeofmeal" id="othermeal" name="othermeal" *ngIf="Typeofmeal === 'Other'" placeholder="If Others's, specify" class="rounded-input" style="margin-left: -80px;width: 90%;">
                            </div>
                        </div>
                        <div *ngIf="showOtherError" class="text-danger">Please fill in the input</div>

                    
                        
                        <label class="sub">Does the Student have breakfast regularly?   </label>
                        <div class="radio-buttons-container_1 " style="margin-top: 10px;">
                            <input type="radio" id="Yes"   name="Yes" value="Yes"[(ngModel)]="breakfastRegular" checked> 
                            <label for="Yes" style="padding-left: 10px; color: black;" >Yes </label> 
                            <input type="radio" id="No" name="No" value="No" style="margin-left: 90px;" [(ngModel)]="breakfastRegular" > 
                            <label for="No" style="padding-left: 10px; color: black;">No</label>      
                        </div><br>
                        <h2  style="color: #000000; font-size: medium; margin-bottom: 20px; " >Does the Student have regular meals at school?   </h2>
                        <div class="radio-buttons-container_1 " style="margin-top: -10px">
                            <input type="radio" id="Yes_1"   name="Yes_1" value="Yes"  [(ngModel)]="regularmealatschool" (change)="toggleMealOptions()" checked> 
                            <label for="Yes_1" style="padding-left: 10px; color: black;" >Yes </label> 
                            <input type="radio" id="No_1" name="No_1" value="No" style="margin-left: 90px;" [(ngModel)]="regularmealatschool" (change)="toggleMealOptions()"> 
                            <label for="No_1" style="padding-left: 10px; color: black;">No</label>      
                        </div><br>
                        <div *ngIf="regularmealatschool === 'Yes'">
                        <label class="sub"  >If Yes, where does it come from?<span style="color: #FF0000;">   *   </span>   </label>
                        <div class="radio-buttons-container-1 " style="margin-top: 10px;">
                            <div style="display: flex; flex-wrap: wrap;">
                                <div style="flex: 33.33%; padding-right: 10px;">
                                    <input type="radio" id="Home" name="Home" [(ngModel)]="wheredoesitcomefrom" value="Home" checked>
                                    <label for="Home" style="color: #000000; margin-left: 10px;">Home</label>
                                </div>
                                <div style="flex: 33.33%; padding-right: 10px;">
                                    <input type="radio" id="School" name="School" [(ngModel)]="wheredoesitcomefrom" value="School">
                                    <label for="School" style="color: #000000;margin-left: 10px;">School</label>
                                </div>
                                <div style="flex: 33.33%; padding-right: 10px;">
                                    <input type="radio" id="Hawkers/stalls" name="Hawkers/stalls" [(ngModel)]="wheredoesitcomefrom" value="Stalls/Hawkers">
                                    <label for="Hawkers/stalls" style="color: #000000;margin-left: 10px;">Hawkers/Stalls</label>
                                </div>
                            </div>
                            <div style="display: flex; flex-wrap: wrap; margin-top: 20px;">
                                <div style="flex: 33.33%; padding-right: 10px;">
                                    <input type="radio" id="Tuckshop" name="Tuckshop" [(ngModel)]="wheredoesitcomefrom" value="Tuck shop">
                                    <label for="Tuckshop" style="color: #000000; margin-left: 10px;">Tuck shop</label>
                                </div>
                               
                                <div style="flex: 66.66%; padding-right: 10px;">
                                    <input type="radio" id="wherecomefromOthers" name="wherecomefromOthers" [(ngModel)]="wheredoesitcomefrom" value="Other" (ngModelChange)="resetOtherMealValidation()">
                                    <label for="wherecomefromOthers" style="color: #000000;margin-left: 10px;">Other</label>
                                </div>

                                
                                <div class="d" style="flex: 33.33%;">
                                    <input type="text" [(ngModel)]="otherMealatSchool" id="othermealatschool" name="othermealatschool" *ngIf="wheredoesitcomefrom === 'Other'" placeholder="If others, specify" class="rounded-input" style="margin-left: -80px;width: 90%;" required>
                                </div>
                            </div>
                            <div *ngIf="showOtherMealError" class="text-danger">Please fill in the input</div>

                        </div>
                    </div>
           
                        
                        <label class="sub">Is the Student intolerant of any Food or Food group?</label>
                        <div class="row" style="color:#000000; width:700px">
                            <ng-container *ngFor="let Intolerant of Intolerants; let i = index" >
                              <div class="col-md-4">
                                <label>
                                  <input type="checkbox" id="{{Intolerant.name}}" [(ngModel)]="Intolerant.selected"  name="{{Intolerant.name}}" style="margin-top: 20px;"> {{ Intolerant.name }}
                                </label>
            
                              </div> 
                              
                              <div *ngIf="(i + 1) % 3 === 0" class="w-100"></div>

                            </ng-container>
                            
                          </div>
                          <div  style="color: #000000; width:700px">
                           
                            <div class="col-md-4">

                              <input type="text" [(ngModel)]="otherIntolerant" id="Other_input" name="Other_input" placeholder="Specify (Eggs,Milk,Sea food,Wheat/Gulten,Other)" class="rounded-input" style="width:600px;margin-left: 0px;margin-top: 10px;" required>
                              <!-- <div *ngIf="showOtherInput && !otherIntolerant" style="color: red; margin-top: 10px;">Please specify in the Other input.</div> -->
                              <!-- <div *ngIf="showIntolerantWarning && Intolerant.selected && Intolerant.name === 'Other'" class="text-danger">Please fill in the Input</div> -->
                              <div *ngIf="showIntolerantWarning" style="color: red; margin-top: 10px;">Please fill in the input.</div>


                            </div>
                          </div>
                          
      
                    </div>
                  </div>
                </div>
            </div>
            <div class="row" style="text-align: end;">
                <span class="col">
                    <button type="button" class="btn btn-secondary" 
                        style="width: 140px; height: Hug(70px);margin-left: 600px; background-color: #D4D4D4; color: #000000; margin-top: 20px;"
                        (click)="sectionE()">Previous</button>
                </span>
                
                <span class="col">
                    <button type="submit" class="btn btn-primary"
                        style="width: 130px;margin-right:100px; height: Hug(70px); border-radius: 8px; background-color: #8F1402; margin-top: 20px;"
                        >Next</button>
                </span>
            </div>
          </form>
           </ng-template> 
           <ng-template [ngIf]="displayName==='sectionG'">
            <form (ngSubmit)="sectiong()">
                <div class="card-container">
                  <div style="display: flex;justify-content: space-between;">
                <h1 class="ba">G. Sleep Pattern & Quality</h1>
                <div class="profile" *ngIf="studentPhoto">

                  <label for="myFileInput">
                    <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo" class="imageicon" >
                    <div *ngIf="!studentPhotoImageUrl" class="box-text">
                      <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                    </div>
                  </label>
                </div>
              </div>
                <div class="scroll-bar_2">
                    <div class="scroll-content_2">
                        <div class="e">
                        <div class="sub">
                            <label for="On an average is the student fresh and relaxed after night's sleep?" class="sub">On an average is the Student fresh and relaxed after Night's sleep? </label><br>
                            <input type="text" name="name"  [(ngModel)]="StudentFreshandRelaxedAfterniNghtsSleep" placeholder="Type here"style="margin-bottom: 20px;  max-width: 600px; margin-top:10px;"  class="rounded-input"> </div>
                          <label class="sub" style="margin-top: -10px;">Does the Student have any sleep related issues? (Tick all that are applicable)  </label>
                          <div class="radio-buttons-container_1" style="margin-left: 0px;margin-top: 5px;">
                            <input type="radio"  id="Yes" name="sleepIssue" [(ngModel)]="sleepIssue" value="Yes">
                            <label for="Yes" style="padding-left: 10px;color:#000000">Yes</label>
                          
                            <input type="radio" id="No" name="sleepIssue" [(ngModel)]="sleepIssue" value="No" style="margin-left: 150px;">
                            <label for="No" style="padding-left: 10px;color:#000000">No</label>
                          </div>
                          <div class="row" style="color:#000000; width:700px">
                            <div class="row" style="color: #000000; width: 750px ;margin-left: 15px; " *ngIf="sleepIssue === 'Yes'">

                            <ng-container *ngFor="let sleep of sleeping; let i = index">
                                <div class="col-md-4">
                                  <label>
                                    <input type="checkbox" [(ngModel)]="sleep.selected" [ngModelOptions]="{standalone: true}" style="margin-top: 20px;" required> {{ sleep.name }}
                                  </label>
                                  <input type="text" [(ngModel)]="sleep.otherssleep" [ngModelOptions]="{ standalone: true }" *ngIf="sleep.selected && sleep.name === 'Other'" placeholder="Enter other activity" class="rounded-input" style="width:600px;margin-left: -450px" required>
                                  

                                </div>
                                <div *ngIf="showsleepWarning && sleep.selected && sleep.name === 'Other'" class="text-danger">Please fill in the Input</div>
                                <!-- Create a new row after every 3 activities -->
                                <div *ngIf="(i + 1) % 3 === 0" class="w-100"></div>
                              </ng-container>
                          </div>
                          <br>
                              
    
                         <label class="sub" >What is the Student's average length of sleep per Night?   </label>
                         <div class="radio-buttons-container_1 " style="margin-top: 10px">
                            <input type="radio" id="Less than 8 hours"   name="Less than 8 hours" value="Less than 8 hours"[(ngModel)]="AverageLengthOfSleepPerNight" checked> 
                            <label for="Less than 8 hours" style="padding-left: 10px; color: black; margin-right: 100px;" >Less than 8 hours </label> 
                            <input type="radio" id="More than 8 hours"   name="Less than 8 hours" value="More than 8 hours"[(ngModel)]="AverageLengthOfSleepPerNight" checked> 
                            <label for="More than 8 hours" style="padding-left: 10px; color: black;" >More than 8 hours </label>
                        </div><br>
                        <div>
                            <label class="sub">What is the Student's nap cycle during the Day?</label>
                            <div *ngFor="let nap of napTimes; let i = index" class="nap-input" style="margin-top: 10px">
                              <label class="static-label sub" *ngIf="i === 0">Nap 1:</label>
                              <label class="static-label sub" *ngIf="i > 0">Nap {{ i + 1 }}:</label>
                              <input type="text" class="rounded-input" [(ngModel)]="nap.napLabel" name="napLabel{{ i }}" placeholder="Duration of nap (in Minutes)" style="width: 300px; margin-left: 10px;">
                              <button type="button" (click)="addNap()">+</button>
                              <button type="button" *ngIf="napTimes.length > 1" (click)="removeNap(i)">-</button>
                            </div>
                          </div>
                          

                           
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
                    <div class="row" style="text-align: end;">
                        <span class="col">
                            <button type="button" class="btn btn-secondary" 
                                style="width: 140px; height: Hug(70px);margin-left: 600px; background-color: #D4D4D4; color: #000000; margin-top: 20px;"
                                (click)="sectionF()">Previous</button>
                        </span>
                        
                        <span class="col">
                            <button type="submit" class="btn btn-primary"
                                style="width: 130px;margin-right:100px; height: Hug(70px); border-radius: 8px; background-color: #8F1402; margin-top: 20px;"
                                >Next</button>
                        </span>
                    </div>
               
            </form>
          </ng-template>
          
       <ng-template [ngIf]="displayName==='sectionH'">
            <form (ngSubmit)='sectionh()'>
            <div class="card-container">
              <div style="display: flex;justify-content: space-between;">
                <h1 class="ba">H. Personality & Social Interactions</h1>
                <div class="profile" *ngIf="studentPhoto">

                  <label for="myFileInput">
                    <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo" class="imageicon" >
                    <div *ngIf="!studentPhotoImageUrl" class="box-text">
                      <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                    </div>
                  </label>
                </div>
              </div>
                <div class="scroll-bar_2">
                    <div class="scroll-content_3">
                      <form>
                        <label class="first">How would you best describe the Social Personality of the Student?</label>
                        <div class="radio-buttons-container-1" style="margin-top: 10px;">
                            <div style="display: flex; flex-wrap: wrap;">
                                <div style="flex: 33.33%; padding-right: 10px;">
                                    <input type="radio" id="Aggressive" name="Aggressive" [(ngModel)]="SocialPersonalityOfTheStudent" value="Aggressive" checked>
                                    <label for="Aggressive" style="color: #000000; margin-left: 10px;">Aggressive</label>
                                </div>
                                <div style="flex: 33.33%; padding-right: 10px;">
                                    <input type="radio" id="Attentionseeking" name="Attentionseeking" [(ngModel)]="SocialPersonalityOfTheStudent" value="Attention seeking">
                                    <label for="Attentionseeking" style="color: #000000;margin-left: 10px;">Attention seeking</label>
                                </div>
                                <div style="flex: 33.33%; padding-right: 10px;">
                                    <input type="radio" id="Calm" name="Calm" [(ngModel)]="SocialPersonalityOfTheStudent" value="Calm">
                                    <label for="Calm" style="color: #000000;margin-left: 10px;">Calm</label>
                                </div>
                            </div>
                            <div style="display: flex; flex-wrap: wrap;margin-top: 30px;">
                                <div style="flex: 33.33%; padding-right: 10px;margin-top: -10px">
                                    <input type="radio" id="Gregarious" name="Gregarious" [(ngModel)]="SocialPersonalityOfTheStudent" value="Gregarious/Outgoing">
                                    <label for="Gregarious" style="color: #000000; margin-left: 10px;">Gregarious/Outgoing</label>
                                </div>
                                <div style="flex: 33.33%; padding-right: 10px;margin-top: -10px">
                                    <input type="radio" id="Happy" name="Happy" [(ngModel)]="SocialPersonalityOfTheStudent" value="Happy">
                                    <label for="Happy" style="color: #000000; margin-left: 10px;">Happy</label>
                                </div>
                                <div style="flex: 33.33%; padding-right: 10px;margin-top: -10px">
                                    <input type="radio" id="nervous" name="nervous" [(ngModel)]="SocialPersonalityOfTheStudent" value="nervous">
                                    <label for="nervous" style="color: #000000; margin-left: 10px;">Nervous</label>
                                </div>
                            </div>
                            <div style="display: flex; flex-wrap: wrap;margin-top: 20px">
                                <div style="flex: 33.33%; padding-right: 10px;">
                                    <input type="radio" id="Sad/ Morose" name="Sad/ Morose" [(ngModel)]="SocialPersonalityOfTheStudent" value="Sad/Morose">
                                    <label for="Sad/ Morose" style="color: #000000; margin-left: 10px;">Sad/Morose</label>
                                </div>
                                <div style="flex: 33.33%; padding-right: 10px;">
                                    <input type="radio" id="Sulking/Cranky" name="Sulking/Cranky" [(ngModel)]="SocialPersonalityOfTheStudent" value="Sulky/Cranky">
                                    <label for="Sulking/Cranky" style="color: #000000; margin-left: 10px;">Sulky/Cranky</label>
                                </div>
                                <div style="flex: 33.33%; padding-right: 10px;">
                                    <input type="radio" id="Withdrawn" name="Withdrawn" [(ngModel)]="SocialPersonalityOfTheStudent" value="Withdrawn">
                                    <label for="Withdrawn" style="color: #000000; margin-left: 10px;">Withdrawn</label>
                                </div>
                            </div>
                            <div style="display: flex; flex-wrap: wrap; margin-top: 20px;">
                                <div style="flex: 66.66%; padding-right: 10px;">
                                <input type="radio" id="personalityOthers" name="personalityOthers" [(ngModel)]="SocialPersonalityOfTheStudent" value="Other" (ngModelChange)="resetOtherPersonalityValidation()">
                                <label for="personalityOthers" style="color: #000000;margin-left: 10px;">Other</label>
                                
                                <div class="d" style="flex: 33.33%;">
                                <input type="text" [(ngModel)]="studentPersonalityInformationothers" name="personOthers" *ngIf="SocialPersonalityOfTheStudent === 'Other'" placeholder="If yes, which one" class="rounded-input" style="margin-left: -80px;width: 90%;">
                                </div>
                                </div>
                                </div>
                                <div *ngIf="showOtherPersonalityError" style="margin-top: 5px;margin-bottom: 5px;" class="text-danger">Please fill in the input</div>





                        
                          <label for="Specify if the Student has any irrational Fears or Phobias" class="subh">Specify if the Student has any irrational Fears or Phobias </label><br>
                          <input type="text" name="Specify if the Student has any irrational Fears or Phobias" id="Specify if the Student has any irrational Fears or Phobias" [(ngModel)]="AnyIrrationalFearsOrPhobias" placeholder="Type here" style="max-width: 600px; margin-bottom: 20px;margin-top: 10px" class="rounded-input"><br>
                        </div>
                        <div   class="radio-buttons-container_1 ">
                          <label class="subh">How would you rate the Student Self Image/Self Worth?   </label><br>
                          <input type="radio" id="Excellent"   name="Excellent" value="Excellent"  [(ngModel)]="StudentSelfImageSelfWorth"  (click)="toggleInput(false)" checked> 
                          <label for="Excellent" style="padding-right: 90px;padding-left: 10px; color: black;margin-top: px;" >Excellent </label>
                          <input type="radio" id="Good"   name="Excellent" value="Good" [(ngModel)]="StudentSelfImageSelfWorth"  (click)="toggleInput(false)" checked> 
                          <label for="Good" style="padding-right: 90px;padding-left: 10px; color: black;margin-top: 5px;" >Good </label>
                          <input type="radio" id="Average"   name="Excellent" value="Average" [(ngModel)]="StudentSelfImageSelfWorth"  (click)="toggleInput(false)" checked> 
                          <label for="Average" style="padding-right: 103px;padding-left: 10px; color: black;margin-top: 5px; " >Average </label> 
                          <input type="radio" id="Poor"   name="Excellent" value="Poor" [(ngModel)]="StudentSelfImageSelfWorth"  (click)="toggleInput(false)" checked> 
                          <label for="Poor" style="padding-right: 60px;padding-left: 10px; color: black; margin-top: 5px;" >Poor </label> 
                        </div>
                        <label for="customrange_rate" class="subh" style="margin-top: 15px;">How would you rate the Student Cooperation in various household chores</label>
                        <div class="range-bar-container" style="margin-left: 10px; margin-top: 10px;">
                            <input type="range" id="customrange_rate" name="customrange_rate" [(ngModel)]="selectedNumberrate" min="0" [max]="maxRange" class="range-slider">
                            <div class="number-container">
                              <div *ngFor="let number of sliderScaleValues" class="subh" >{{ number }}</div>
                            </div>
                          </div>
                        <div class="d-flex flex-row" >
                          <label  style="font-weight: 500;color: #000;font-style: Verdana;font-size: small;">Exceedingly Uncooperative</label>
                          <label  style="margin-left: 250px;font-weight: 500;color: #000;font-style: Verdana;font-size: small;">Exceedingly Cooperative</label>
                        </div>
                
       <label class="subh" style="margin-top: 15px;"  >How would you describe the Student reaction to Change? </label>
       <div class="radio-buttons-container-1" style="margin: 10px;">
        <div style="display: flex; flex-wrap: wrap;">
            <div style="flex: 33.33%; padding-right: 10px;">
                <input type="radio" id="Adjusting" name="Adjusting" [(ngModel)]="studentsreactiontochange" value="Adjusting" checked>
                <label for="Adjusting" style="color: #000000; margin-left: 10px;">Adjusting</label>
            </div>
            <div style="flex: 33.33%; padding-right: 10px;">
                <input type="radio" id="Cheerful" name="Cheerful" [(ngModel)]="studentsreactiontochange" value="Cheerful">
                <label for="Cheerful" style="color: #000000;margin-left: 10px;">Cheerful</label>
            </div>
            <div style="flex: 33.33%; padding-right: 10px;">
                <input type="radio" id="Fearful" name="Fearful" [(ngModel)]="studentsreactiontochange" value="Fearful">
                <label for="Fearful" style="color: #000000;margin-left: 10px;">Fearful</label>
            </div>
        </div>
        <div style="display: flex; flex-wrap: wrap;margin-top: 20px;">
            <div style="flex: 33.33%; padding-right: 10px;">
                <input type="radio" id="Nervous" name="Nervous" [(ngModel)]="studentsreactiontochange" value="Nervous">
                <label for="Nervous" style="color: #000000; margin-left: 10px;">Nervous</label>
            </div>
            <div style="flex: 33.33%; padding-right: 10px;">
                <input type="radio" id="Non-adjusting" name="Non-adjusting" [(ngModel)]="studentsreactiontochange" value="Non-Adjusting">
                <label for="Non-adjusting" style="color: #000000; margin-left: 10px;">Non-adjusting</label>
            </div>
            <div style="flex: 33.33%; padding-right: 10px;">
                <input type="radio" id="Resistant" name="Resistant" [(ngModel)]="studentsreactiontochange" value="Resistant">
                <label for="Resistant" style="color: #000000; margin-left: 10px;">Resistant</label>
            </div>
        </div>
        <div style="display: flex; flex-wrap: wrap;margin-top: 20px">
            <div style="flex: 33.33%; padding-right: 10px;">
                <input type="radio" id="Obstructive" name="Obstructive" [(ngModel)]="studentsreactiontochange" value="Obstructive">
                <label for="Obstructive" style="color: #000000; margin-left: 10px;">Obstructive</label>
            </div>
            <div style="flex: 33.33%; padding-right: 10px;">
                <input type="radio" id="Nothing_specific" name="Nothing_specific" [(ngModel)]="studentsreactiontochange" value="Nothing specific">
                <label for="Nothing_specific" style="color: #000000; margin-left: 10px;">Nothing specific</label>
            </div>
            <div style="flex: 33.33%; padding-right: 10px;">
                <input type="radio" id="reactionOthers" name="Others" [(ngModel)]="studentsreactiontochange" value="Other" (ngModelChange)="resetOtherReactionValidation()">
    <label for="reactionOthers" style="color: #000000; margin-left: 10px;">Other</label>
            </div>
        </div>
        
        <div class="d" style="flex: 33.33%;">
            <input type="text" [(ngModel)]="studentsreactiontochangeothers" name="studentsreactiontochangeothers" *ngIf="studentsreactiontochange === 'Other'" placeholder="If yes, which one" class="rounded-input" style="margin-left: -80px;width: 90%;" required>
        </div>
        <div *ngIf="showOtherReactionError"style="margin-top: 5px;margin-bottom: 5px;" class="text-danger">Please fill in the input</div>

    </div>  
            <label class="sub">How would you describe the Student's relationship with other Students?
            </label>
            <div class="radio-buttons-container-1" style="margin-top: 10px;">
                <div style="display: flex; flex-wrap: wrap;">
                    <div style="flex: 33.33%; padding-right: 10px;">
                        <input type="radio" id="relationshipAggressive" name="relationshipAggressive" [(ngModel)]="relationshipStudent" value="Aggressive" checked>
                        <label for="relationshipAggressive" style="color: #000000; margin-left: 10px;">Aggressive</label>
                    </div>
                    <div style="flex: 33.33%; padding-right: 10px;">
                        <input type="radio" id="Dependent" name="Dependent" [(ngModel)]="relationshipStudent" value="Dependent">
                        <label for="Dependent" style="color: #000000;margin-left: 10px;">Dependent</label>
                    </div>
                    <div style="flex: 33.33%; padding-right: 10px;">
                        <input type="radio" id="Dominating" name="Dominating" [(ngModel)]="relationshipStudent" value="Dominating">
                        <label for="Dominating" style="color: #000000;margin-left: 10px;">Dominating</label>
                    </div>
                </div>
                <div style="display: flex; flex-wrap: wrap;margin-top: 20px;">
                    <div style="flex: 33.33%; padding-right: 10px;">
                        <input type="radio" id="Friendly" name="Friendly" [(ngModel)]="relationshipStudent" value="Friendly">
                        <label for="Friendly" style="color: #000000; margin-left: 10px;">Friendly</label>
                    </div>
                    <div style="flex: 33.33%; padding-right: 10px;">
                        <input type="radio" id="Submissive" name="Submissive" [(ngModel)]="relationshipStudent" value="Submissive">
                        <label for="Submissive" style="color: #000000; margin-left: 10px;">Submissive</label>
                    </div>
                    <div style="flex: 33.33%; padding-right: 10px;">
                        <input type="radio" id="Clingy" name="Clingy" [(ngModel)]="relationshipStudent" value="Clingy">
                        <label for="Clingy" style="color: #000000; margin-left: 10px;">Clingy</label>
                    </div>
                </div>
                <div style="display: flex; flex-wrap: wrap;margin-top: 20px">
                    <div style="flex: 33.33%; padding-right: 10px;">
                        <input type="radio" id="Nothingspecific" name="Nothingspecific" [(ngModel)]="relationshipStudent" value="Nothing specific">
                        <label for="Nothingspecific" style="color: #000000; margin-left: 10px;">Nothing specific</label>
                    </div>
                    <div style="flex: 33.33%; padding-right: 10px;">
                        <input type="radio" id="relationOthers" name="relationOthers" [(ngModel)]="relationshipStudent" value="Other" (ngModelChange)="resetOtherRelationshipValidation()">
                        <label for="relationOthers" style="color: #000000; margin-left: 10px;">Other</label>
                    </div>
                    <div style="flex: 33.33%; padding-right: 10px;">
                    </div>
                </div>
            </div>
            <div class="d" style="flex: 33.33%;">
                <input type="text" id="relationStudent" [(ngModel)]="relationshipStudentothers" name="relationStudent" *ngIf="relationshipStudent === 'Other'" placeholder="If yes, which one" class="rounded-input" style="margin-left: -80px;width: 90%;" required>
            </div>
            <div *ngIf="showOtherRelationshipError"style="margin-top: 5px;margin-bottom: 5px;" class="text-danger">Please fill in the input</div>

               
            
                        <label class="sub">Does the Student have any significantly close friends?</label>
                        <div class="radio-buttons-container_1" style="margin-top: 10px;">
                          <input type="radio" id="Yes_1" name="Yes_1" value="Yes"  [(ngModel)]="significantlyclosefriends" checked> 
                          <label for="Yes_1" style="padding-left: 10px; color: black;margin-right: 90px;" >Yes</label> 
                          <input type="radio" id="No" name="No" value="No"  [(ngModel)]="significantlyclosefriends"> 
                          <label for="No" style="padding-left: 10px; color: black;">No</label>
                          <div>
                          <label class="subh" *ngIf="significantlyclosefriends === 'Yes'" style="margin-top: 10px;">Girls</label></div>
                          <input type="number" [(ngModel)]="girlsCount" name="girlscount"  *ngIf="significantlyclosefriends === 'Yes'" [min]="0" placeholder="How many Girls" class="rounded-input" style="max-width: 600px; margin-bottom: 10px;margin-top: 5px;" required (keypress)="validateInput($event)">
                            <div><label class="subh" *ngIf="significantlyclosefriends === 'Yes'">Boys</label></div>
                          <input type="number" [(ngModel)]="boysCount" name="boysscount" *ngIf="significantlyclosefriends === 'Yes'" [min]="0" placeholder="How many Boys" class="rounded-input" style="max-width: 600px;margin-top: 5px;" required (keypress)="validateInput($event)">
                        </div>
                        </form>   
                    </div>
                  </div>
              </div>
              <div class="row" style="text-align: end;">
                <span class="col">
                    <button type="button" class="btn btn-secondary" 
                        style="width: 140px; height: Hug(70px);margin-left: 600px; background-color: #D4D4D4; color: #000000; margin-top: 20px;"
                        (click)="sectionG()">Previous</button>
                </span>
                
                <span class="col">
                    <button type="submit" class="btn btn-primary"
                        style="width: 130px;margin-right:100px; height: Hug(70px); border-radius: 8px; background-color: #8F1402; margin-top: 20px;"
                        >Next</button>
                </span>
            </div>
            </form>
             </ng-template>
             <ng-template [ngIf]="displayName==='sectionI'">
                   <form (ngSubmit)="sectioni()">
                <div class="card-container" >
                  <div style="display: flex;justify-content: space-between;">
                    <h1 class="smallheadings" >I. Life At School</h1>  
                    <div class="profile" *ngIf="studentPhoto">

                      <label for="myFileInput">
                        <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo" class="imageicon" >
                        <div *ngIf="!studentPhotoImageUrl" class="box-text">
                          <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                        </div>
                      </label>
                    </div> 
                  </div>      
                    <div class="class_i">
                      
                          <label class="first"> What is the Student's general opinion of School?</label>
                          <div class="radio-buttons-container_1 " style="margin-top: 10px" >
                            <input type="radio" id="Positive"   name="Positive" value="Positive" [(ngModel)]="StudentGeneralOpinioofSchool"  (click)="toggleInput(false)" style="margin-left: 30px;" checked> 
                            <label for="Positive" style="padding-right: 103px;padding-left: 10px; color: black; margin-top: 5px;" >Positive </label> 
                            <input type="radio" id="Negative"   name="Positive" value="Negative" [(ngModel)]="StudentGeneralOpinioofSchool"  (click)="toggleInput(false)" style="margin-left: 30px;" checked> 
                            <label for="Negative" style="padding-right: 103px;padding-left: 10px; color: black; margin-top: 5px;" >Negative </label> 
                            <input type="radio" id="Neutral"   name="Positive" value="Neutral" [(ngModel)]="StudentGeneralOpinioofSchool"  (click)="toggleInput(false)" style="margin-left: 30px;" checked> 
                            <label for="Neutral" style="padding-right: 103px;padding-left: 10px; color: black; margin-top: 5px;" >Neutral </label>
                          </div><br>
                          <label class="subh">The Student goes to School</label>
                          <div class="radio-buttons-container_1 " style="margin-left: -10px; margin-top: 10px;">
                            <input type="radio" id="Willingly"   name="Willingly" value="Yes" [(ngModel)]="studentgoestoschool"  (click)="toggleInput(false)" style="margin-left: 36px;" checked> 
                            <label for="Willingly" style="padding-right: 103px;padding-left: 10px; color: black; margin-top: 5px;" >Yes </label> 
                            <input type="radio" id="Unwillingly"   name="Willingly" value="No" [(ngModel)]="studentgoestoschool"  (click)="toggleInput(false)" style="margin-left: 35px;" checked> 
                            <label for="Unwillingly" style="padding-right: 103px;padding-left: 10px; color: black; margin-top: 5px;" >No </label> 
                          </div><br>
        
                          <label for="customRange" class="subh"> How would you rate the Student's overall Attendance at School? </label>
                          <div class="range-bar-container" style="margin-left: 10px;margin-top: 10px;" >
                            <input type="range" name="customRange" id="customRange" [(ngModel)]="selectedNumber" min="0" [max]="maxRange" class="range-slider">
                            <div class="number-container">
                              <div *ngFor="let number of sliderScaleValues" class="subh" >{{ number }}</div>
                            </div>
                          </div>
                          <div class="d-flex flex-row" >
                            <label  style="font-weight: 500;color: #000;font-style: Verdana;font-size: small;">Very Irregular</label>
                            <label  style="font-weight: 500;color: #000;font-style: Verdana;font-size: small;margin-left: 400px;">Always Regular</label>
                          </div>
                     
                        </div>
                  </div>
                  <div class="row" style="text-align: end;">
                    <span class="col">
                        <button type="button" class="btn btn-secondary" 
                            style="width: 140px; height: Hug(70px);margin-left: 600px; background-color: #D4D4D4; color: #000000; margin-top: 20px;"
                            (click)="sectionH()">Previous</button>
                    </span>
                    
                    <span class="col">
                        <button type="submit" class="btn btn-primary"
                            style="width: 130px;margin-right:100px; height: Hug(70px); border-radius: 8px; background-color: #8F1402; margin-top: 20px;"
                            >Next</button>
                    </span>
                </div>
                </form>
                </ng-template> 

                <ng-template [ngIf]="displayName==='sectionJ'">
                    <form (ngSubmit) = 'sectionj()'>
                    <div class="card-container">
                      <div style="display: flex;justify-content: space-between;">
                        <h1 class="smallheadings">J. General History</h1>
                        <div class="profile" *ngIf="studentPhoto">

                          <label for="myFileInput">
                            <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo" class="imageicon" >
                            <div *ngIf="!studentPhotoImageUrl" class="box-text">
                              <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                            </div>
                          </label>
                        </div>
                      </div>
                        <div class="scroll-bar_2">
                          <div class="scroll-content_1"> 
                            <form>
                              <label class="first" >Do you have concerns with any of the following with respect to the Student? (Tick all applicable)</label>
                              <div class="row" style="color: #000000; width: 700px ;margin-left: 25px;" >
                                    <ng-container *ngFor="let respect of respected; let i = index">
                                    <div class="col-md-4">
                                        <label>
                                        <input type="checkbox" [(ngModel)]="respect.selected" id="{{respect.name}}" name="{{respect.name}}" style="margin-top: 20px;"> {{ respect.name }}
                                        </label>
                                    
                                    </div>
                                
                                    
                                    </ng-container>
                                </div>
                              <label for="customRange_2" class="sub">How would you rate Student's overall Physical Health? </label>
                              <div class="range-bar-container" style="margin-left: 10px;margin-top: 10px;" >
                                <input type="range" name="customRange_2" id="customRange_2" [(ngModel)]="selectedNumberHealth" min="0" [max]="maxRange" class="range-slider">
                                <div class="number-container">
                                  <div *ngFor="let number of sliderScaleValues" class="subh" >{{ number }}</div>
                                </div>
                              </div>
                              <div class="d-flex flex-row" >
                                <label  style="font-weight: 500;color: #000;font-style: Verdana;font-size: small;">Very Poor</label>
                                <label  style="font-weight: 500;color: #000;font-style: Verdana;font-size: small;margin-left: 420px;">Excellent</label>
                              </div>
                              <label class="sub">Is the Student's School Performance significantly affected by any of the following?</label>
                             
                              <div class="row" style="color: #000000; width: 700px ;margin-left: 25px;" >
                                <ng-container *ngFor="let performance of performancing; let i = index">
                                <div class="col-md-4">
                                    <label>
                                    <input type="checkbox" [(ngModel)]="performance.selected" id="{{performance.name}}" name="{{performance.name}}" style="margin-top: 20px;"> {{ performance.name }}
                                    </label>
                                
                                </div>
                            
                                
                                </ng-container>
                               </div>
                               <label class="sub" >Is the Student significantly dissatisfied by any of the following?</label>
                               <div class="row" style="color: #000000; width: 700px ;margin-left: 25px;" >
                                <ng-container *ngFor="let disatisfy of dissatisified; let i = index">
                                <div class="col-md-4">
                                    <label>
                                    <input type="checkbox" [(ngModel)]="disatisfy.selected" id="{{disatisfy.name}}" name="{{disatisfy.name}}" style="margin-top: 20px;" required> {{ disatisfy.name }}
                                    </label>
                                    <input type="text" [(ngModel)]="disatisfy.othersDissatification" name="activityOthers" *ngIf="disatisfy.selected && disatisfy.name === 'Other'" placeholder="Others,specify"  class="rounded-input" style="width:600px;margin-left: -250px;" required>

                                </div>
                            
                                <div *ngIf="showDisatisfyWarning && disatisfy.selected && disatisfy.name === 'Other'" class="text-danger" style="margin-top: 5px;margin: left -10px; ">Please fill in the Input</div>

                                </ng-container>
                               </div>
                            </form>   
                          </div>
                        </div> 
                    </div>
                       <div class="row" style="text-align: end;">
                    <span class="col">
                        <button type="button" class="btn btn-secondary" 
                            style="width: 140px; height: Hug(70px);margin-left: 600px; background-color: #D4D4D4; color: #000000; margin-top: 20px;"
                            (click)="sectionI()">Previous</button>
                    </span>
                    
                    <span class="col">
                        <button type="submit" class="btn btn-primary"
                            style="width: 130px;margin-right:100px; height: Hug(70px); border-radius: 8px; background-color: #8F1402; margin-top: 20px;"
                            >Next</button>
                    </span>
                </div>
                </form>
                  </ng-template>
    <ng-template [ngIf]="displayName==='sectionK'">
        <form (ngSubmit)="sectionk()">
        <div class="card-container">
          <div style="display: flex;justify-content: space-between;">
            <h1 class="smallheadings"> K. Family History</h1>
            <div class="profile" *ngIf="studentPhoto">

              <label for="myFileInput">
                <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo" class="imageicon" >
                <div *ngIf="!studentPhotoImageUrl" class="box-text">
                  <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                </div>
              </label>
            </div>
          </div>
            <div class="scroll-bar_2">
                <div class="scroll-content_1">
            
          
            <label class="first" >Any ongoing Illness/ Condition among immediate members of the Student's family (grandparents, parents, siblings, aunt, uncle)</label>
            <div class="row" style="color:#000000; width:700px">
                <ng-container *ngFor="let ill of illness; let i = index" >
                  <div class="col-md-4">
                    <label>
                      <input type="checkbox" [(ngModel)]="ill.selected" id="{{ill.name}}" name="{{ill.name}}" style="margin-top: 20px;" required> {{ill.name}}
                    </label>
                    <input type="text" [(ngModel)]="ill.othersIllness" name="illothers" *ngIf="ill.selected && ill.name === 'Other'" placeholder="If others,specify"  class="rounded-input" style="width:600px;margin-left: -250px;margin-top: 5px;" required>

            </div> 
            <div *ngIf="showillWarning && ill.selected && ill.name === 'Other'" class="text-danger" style="margin-top: 5px;margin: left -10px; ">Please fill in the Input</div>

                  <!-- Create a new row after every 3 activities -->
            <div *ngIf="(i + 1) % 3 === 0" class="w-100"></div>
                </ng-container>
            </div>
            </div>
    
   
    </div>
    
    </div>
    <div class="row" style="text-align: end;">
        <span class="col">
            <button type="button" class="btn btn-secondary" 
                style="width: 140px; height: Hug(70px);margin-left: 600px; background-color: #D4D4D4; color: #000000; margin-top: 20px;"
                (click)="sectionJ()">Previous</button>
        </span>
        
        <span class="col">
            <button type="submit" class="btn btn-primary"
                style="width: 130px;margin-right:100px; height: Hug(70px); border-radius: 8px; background-color: #8F1402; margin-top: 20px;"
                >Next</button>
        </span>
    </div>
    </form>
    </ng-template>
    <ng-template [ngIf]="displayName==='sectionL'">
        <form (ngSubmit)="sectionL()">
        <div class="card-container">
          <div style="display: flex;justify-content: space-between;">
            <h1 class="smallheadings"> L. Medical History</h1>
            <div class="profile" *ngIf="studentPhoto">

              <label for="myFileInput">
                <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo" class="imageicon" >
                <div *ngIf="!studentPhotoImageUrl" class="box-text">
                  <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                </div>
              </label>
            </div>
            </div>
            <div class="scroll-bar_2">
                <div class="scroll-content_1">
            
                    <label class="first" >Did the Student have any significant medical issue in the past? (Tick all that are applicable)</label>
                    <div class="radio-buttons-container" style="margin-left: 18px;margin-top: 5px;">
                        <input type="radio" id="Yes" name="medicalIssue" [(ngModel)]="medicalIssue" value="Yes">
                        <label for="Yes" style="padding-left: 10px;color:#000000">Yes</label>
                      
                        <input type="radio" id="No" name="medicalIssue" [(ngModel)]="medicalIssue" value="No" style="margin-left: 150px;">
                        <label for="No" style="padding-left: 10px;color:#000000">No</label>
                      </div>
             
                      <!-- Input fields for "Yes" option -->
                      <div class="row" style="color: #000000; width: 750px ;margin-left: 15px; " *ngIf="medicalIssue === 'Yes'">
                        <ng-container *ngFor="let medical of medicalproblem; let i = index">
                          <div class="col-md-4">
                            <label>
                              <input type="checkbox" [(ngModel)]="medical.selected" [ngModelOptions]="{ standalone: true }" style="margin-top: 20px;"> {{ medical.name }}
                            </label>
                           
                          </div>
                      
                        
                        </ng-container>
                         
                      </div>
                        <label class="sub" for="pastMedication">Past Medication, if any</label>
                <div>
                        <!-- <input type="text" class="form-control" id="pastMedication" name="pastMedication" [(ngModel)]="pastMedication" placeholder="Enter Past Medication"  class="rounded-input"  style="width:600px"> -->
                        <!-- <div *ngFor="let pastMed of pastmedications; let i = index">
                            <input type="text" class="form-control" [id]="'pastMedication_' + i" [name]="'pastMedication_' + i" [(ngModel)]="pastmedications[i]" placeholder="Enter Past Medication"  class="rounded-input" style="width:600px;margin-top: 10px;" >
                            <button type="button" (click)="removePastMedicationInput(i)">-</button>
                          </div> -->
                          <div *ngFor="let pastMed of pastmedications; let i = index">
                            <input type="text" class="rounded-input" [(ngModel)]="pastMed.pastMedLabel" name="pastMedLabel{{i}}" placeholder="Enter Past Medication" style="width: 300px; margin-left: 10px;">
                            <button class="removePet" type="button" (click)="addPastMed()">+</button>
                            <button class="removePet" (click)="removePastMed(i)" type="button" *ngIf="pastmedications.length > 1">-</button>
                        </div>
                          </div>
                          <label class="sub" >Did the Student have any significant medical issue currently? (Tick all that are applicable)</label>
                    <div class="radio-buttons-container" style="margin-left: 18px;margin-top: 5px;">
                        <input type="radio" id="Yes" name="medicalIssueCurrently" [(ngModel)]="medicalIssueCurrently" value="Yes">
                        <label for="Yes" style="padding-left: 10px;color:#000000">Yes</label>
                      
                        <input type="radio" id="No" name="medicalIssueCurrently" [(ngModel)]="medicalIssueCurrently" value="No" style="margin-left: 150px;">
                        <label for="No" style="padding-left: 10px;color:#000000">No</label>
                      </div>
                      
                      <!-- Input fields for "Yes" option -->
                      <div class="row" style="color: #000000; width: 750px ;margin-left: 15px; " *ngIf="medicalIssueCurrently === 'Yes'">
                        <ng-container *ngFor="let current of currentproblem; let i = index">
                          <div class="col-md-4">
                            <label>
                              <input type="checkbox" [(ngModel)]="current.selected" id="{{current.name}}" name="{{current.name}}" style="margin-top: 20px;"> {{ current.name }}
                            </label>
                           
                          </div>
                      
                        
                        </ng-container>
                         
                      </div>
                      <label class="sub" for="currentMedication">Current Medication, if any</label>
                      <!-- <input type="text" class="form-control" id="currentMedication" name="currentMedication" [(ngModel)]="currentMedication" placeholder="Enter current medication"  class="rounded-input"  style="width:600px"> -->
                      <!-- <button type="button" (click)="addMedicationInput()">+</button>
                      <div *ngFor="let medication of medications; let i = index">
                          <input type="text" class="form-control" [id]="'currentMedication_' + i" [name]="'currentMedication_' + i" [(ngModel)]="medications[i]" placeholder="Enter current medication"  class="rounded-input" style="width:600px;margin-top: 10px;" >
                          <button type="button" (click)="removeMedicationInput(i)">-</button>
                        </div> -->
                        <div *ngFor="let currentMed of currentMedications; let i = index">
                            <input type="text" class="rounded-input" [(ngModel)]="currentMed.currentMedLabel" name="currentMedLabel{{i}}" placeholder="Enter Current Medication" style="width: 300px; margin-left: 10px;">
                            <button class="removePet" type="button" (click)="addCurrentMed()">+</button>
                            <button class="removePet" (click)="removeCurrentMed(i)" type="button" *ngIf="currentMedications.length > 1">-</button>
                        </div>
                          <label class="sub" for="allergies">Any known Allergies?</label>
                          <div class="radio-buttons-container" style="margin-left: 12px; color: #000000;">
                              <input type="radio" id="allergiesyes" name="allergiesyes" [(ngModel)]="allergies" value="Yes">
                              <label for="allergies" style="padding-left: 10px;">Yes</label>
                          
                              <input type="radio" id="allergiesno" name="allergiesno" [(ngModel)]="allergies" value="no" style="margin-left: 150px;">
                              <label for="allergies" style="padding-left: 10px;">No</label>
                          </div>
                          
                          <div *ngIf="allergies === 'Yes'">
                            <div *ngFor="let allergy of allergiesList; let i = index">
                                <input type="text" class="rounded-input" [(ngModel)]="allergy.allergyLabel" name="allergyLabel{{i}}" placeholder="If yes, to what?" style="width: 300px; margin-left: 10px;">
                                <button class="removePet" type="button" (click)="addAllergiesInput()">+</button>
                                <button class="removePet" (click)="removeAllergiesInput(i)" type="button" *ngIf="allergiesList.length > 1">-</button>
                            </div>
                          </div>
                          
    
            </div>
            </div>
    
   
    </div>
    
   
    <div class="row" style="text-align: end;">
        <span class="col">
            <button type="button" class="btn btn-secondary" 
                style="width: 140px; height: Hug(70px);margin-left: 600px; background-color: #D4D4D4; color: #000000; margin-top: 20px;"
                (click)="sectionK()">Previous</button>
        </span>
        
        <span class="col">
            <button type="submit" class="btn btn-primary"
                style="width: 130px;margin-right:100px; height: Hug(70px); border-radius: 8px; background-color: #8F1402; margin-top: 20px;"
                >Next</button>
        </span>
    </div>
    </form>
    </ng-template>
    <!-- section m --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- -->
    <ng-template [ngIf]="displayName==='sectionM'">
        <form (ngSubmit)="sectionm()">
        <div class="card-container">
          <div style="display: flex;justify-content: space-between;">
            <h1 class="ba"> M. Immunization/Vaccination</h1>
            <div class="profile" *ngIf="studentPhoto">

              <label for="myFileInput">
                <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo" class="imageicon" >
                <div *ngIf="!studentPhotoImageUrl" class="box-text">
                  <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                </div>
              </label>
            </div>
            </div>
            <div class="scroll-bar_2">
                <div class="scroll-content">
            
                   <div class="vaccineHeadings">
                    <div class="vaccine">
                        <h1 style="width:50px;margin-left: 5px; font-size: 18px;color: #000000;">Vaccine</h1>
                    </div>
                    <div class="vaccine">
                        <h1 style="margin-left: 5px; font-size: 18px;color: #000000;">Dose 0</h1>
                    </div>
                    <div class="vaccine">
                        <h1 style="margin-left: 5px; font-size: 18px;color: #000000;">Dose 1</h1>
                    </div>
                    <div class="vaccine">
                        <h1 style="margin-left: 5px; font-size: 18px;color: #000000;">Dose 2</h1>
                    </div>
                    <div class="vaccine">
                        <h1 style="margin-left: 5px; font-size: 18px;color: #000000;">Dose 3</h1>
                    </div>
                    <div class="vaccine" >
                        <h1 style="margin-left: 5px; font-size: 18px;color: #000000;">Dose 4</h1>
                    </div>
                   </div>

                <div class="vaccinenames">
                    <div class="vac-container">
                        <div class="vac" >
                            <label for="Bcg" style="font-size: 15px;font-weight: 500; color:#000000">BCG</label>
                            <input type="date" id="Bcg0" name="Bcg0" [(ngModel)]="bcg0" style="width:100px;margin-left:48px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Bcg1" name="Bcg1" [(ngModel)]="bcg1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Bcg2" name="Bcg2" [(ngModel)]="bcg2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Bcg3" name="Bcg3" [(ngModel)]="bcg3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Bcg4" name="Bcg4" [(ngModel)]="bcg4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    
                    
                    <div class="vac-container" >
                        <div class="vac">
                            <label for="chickenpox" style="font-size: 15px;font-weight: 500; color:#000000;">Chicken Pox</label>
                            <input type="date" id="chickenpox0" name="chickenpox0" [(ngModel)]="chickenPox0" style="width:100px;margin-left:-5px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="chickenpox1" name="chickenpox1" [(ngModel)]="chickenPox1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="chickenpox2" name="chickenpox2" [(ngModel)]="chickenPox2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="chickenpox3" name="chickenpox3" [(ngModel)]="chickenPox3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="chickenpox4" name="chickenpox4" [(ngModel)]="chickenPox4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="cholera" style="font-size: 15px;font-weight: 500; color:#000000">Cholera</label>
                            <input type="date" id="cholera0" name="cholera0" [(ngModel)]="cholera0" style="width:100px; margin-left: 25px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="cholera1" name="cholera1" [(ngModel)]="cholera1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="cholera2" name="cholera2" [(ngModel)]="cholera2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="cholera3" name="cholera3" [(ngModel)]="cholera3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="cholera4" name="cholera4" [(ngModel)]="cholera4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="covid" style="font-size: 15px;font-weight: 500;color:#000000;width:100px;">COVID-19</label>
                            <input type="date" id="covid0" name="covid0" [(ngModel)]="covid0" style="width:100px;margin-left: -18px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="covid0" name="covid0" [(ngModel)]="covid1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="covid0" name="covid0" [(ngModel)]="covid2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="covid0" name="covid0" [(ngModel)]="covid3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="covid0" name="covid0" [(ngModel)]="covid4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="DPT" style="font-size: 15px;font-weight: 500; color:#000000">DPT</label>
                            <input type="date" id="Bcg0" name="DPT0" [(ngModel)]="dpt0" style="width:100px;margin-left:50px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Bcg1" name="DPT1" [(ngModel)]="dpt1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Bcg2" name="DPT2" [(ngModel)]="dpt2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Bcg3" name="DPT3" [(ngModel)]="dpt3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Bcg4" name="DPT4" [(ngModel)]="dpt4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="DT" style="font-size: 15px;font-weight: 500; color:#000000">DT</label>
                            <input type="date" id="DT0" name="DT0" [(ngModel)]="dt0" style="width:100px;margin-left:60px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="DT1" name="DT1" [(ngModel)]="dt1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="DT2" name="DT2" [(ngModel)]="dt2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="DT3" name="DT3" [(ngModel)]="dt3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="DT4" name="DT4" [(ngModel)]="dt4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="Hepatitis" style="font-size: 15px;font-weight: 500; color:#000000;font-weight: 500;">Hepatitis A</label>
                            <input type="date" id="Hepatitis0" name="Hepatitis0" [(ngModel)]="hepatitis0" style="width:100px;margin-left:5px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Hepatitis1" name="Hepatitis1" [(ngModel)]="hepatitis1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Hepatitis2" name="Hepatitis2" [(ngModel)]="hepatitis2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Hepatitis3" name="Hepatitis3" [(ngModel)]="hepatitis3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Hepatitis4" name="Hepatitis4" [(ngModel)]="hepatitis4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="Hepatitisb" style="font-size: 15px;font-weight: 500; color:#000000">Hepatitis B</label>
                            <input type="date" id="Hepatitisb0" name="Hepatitisb0" [(ngModel)]="hepatitisb0" style="width:100px;margin-left:5px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Hepatitisb1" name="Hepatitisb1" [(ngModel)]="hepatitisb1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Hepatitisb2" name="Hepatitisb2" [(ngModel)]="hepatitisb2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Hepatitisb3" name="Hepatitisb3" [(ngModel)]="hepatitisb3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Hepatitisb4" name="Hepatitisb4" [(ngModel)]="hepatitisb4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="Hpv" style="font-size: 15px;font-weight: 500; color:#000000">HPV</label>
                            <input type="date" id="Hpv0" name="Hpv0" [(ngModel)]="hpv0" style="width:100px;margin-left:48px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Hpv1" name="Hpv1" [(ngModel)]="hpv1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Hpv2" name="Hpv2" [(ngModel)]="hpv2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Hpv3" name="Hpv3" [(ngModel)]="hpv3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Hpv4" name="Hpv4" [(ngModel)]="hpv4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="Influenza" style="font-size: 15px;font-weight: 500; color:#000000">Influenza (HIB)</label>
                            <input type="date" id="Influenza0" name="Influenza0" [(ngModel)]="influenza0" style="width:100px;margin-left:-20px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Influenza1" name="Influenza1" [(ngModel)]="influenza1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Influenza2" name="Influenza2" [(ngModel)]="influenza2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Influenza3" name="Influenza3" [(ngModel)]="influenza3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Influenza4" name="Influenza4" [(ngModel)]="influenza4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="InfluenzaV" style="font-size: 15px;font-weight: 500; color:#000000">Influenza (Viral)</label>
                            <input type="date" id="InfluenzaV0" name="InfluenzaV0" [(ngModel)]="influenzaV0" style="width:100px;margin-left:-27px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="InfluenzaV1" name="InfluenzaV1" [(ngModel)]="influenzaV1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="InfluenzaV2" name="InfluenzaV2" [(ngModel)]="influenzaV2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="InfluenzaV3" name="InfluenzaV3" [(ngModel)]="influenzaV3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="InfluenzaV4" name="InfluenzaV4" [(ngModel)]="influenzaV4" style="width:100px;font-size: 12px;;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="MMR" style="font-size: 15px;font-weight: 500;color:#000000">MMR</label>
                            <input type="date" id="MMR0" name="MMR0" [(ngModel)]="mmr0" style="width:100px;margin-left:42px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="MMR1" name="MMR1" [(ngModel)]="mmr1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="MMR2" name="MMR2" [(ngModel)]="mmr2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="MMR3" name="MMR3" [(ngModel)]="mmr3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="MMR4" name="MMR4" [(ngModel)]="mmr4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="Mumps" style="font-size: 15px;font-weight: 500;color:#000000">Mumps- Measles</label>
                            <input type="date" id="Mumps0" name="Mumps0" [(ngModel)]="mumps0" style="width:100px;margin-left:-33px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Mumps1" name="Mumps1" [(ngModel)]="mumps1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Mumps2" name="Mumps2" [(ngModel)]="mumps2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Mumps3" name="Mumps3" [(ngModel)]="mumps3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Mumps4" name="Mumps4" [(ngModel)]="mumps4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="OralP" style="font-size: 15px;font-weight: 500; color:#000000">Oral Polio(OPV)</label>
                            <input type="date" id="OralP0" name="OralP0" [(ngModel)]="oralP0" style="width:100px;margin-left:-25px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="OralP1" name="OralP1" [(ngModel)]="oralP1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="OralP2" name="OralP2" [(ngModel)]="oralP2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="OralP3" name="OralP3" [(ngModel)]="oralP3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="OralP4" name="OralP4" [(ngModel)]="oralP4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="Rotavirus" style="font-size: 15px;font-weight: 500; color:#000000">Rotavirus</label>
                            <input type="date" id="Rotavirus0" name="Rotavirus0" [(ngModel)]="rotaVirus0" style="width:100px;margin-left:14px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Rotavirus1" name="Rotavirus1" [(ngModel)]="rotaVirus1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Rotavirus2" name="Rotavirus2" [(ngModel)]="rotaVirus2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Rotavirus3" name="Rotavirus3" [(ngModel)]="rotaVirus3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Rotavirus4" name="Rotavirus4" [(ngModel)]="rotaVirus4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="Tetanus" style="font-size: 15px;font-weight: 500; color:#000000">Tetanus Toxoid</label>
                            <input type="date" id="Tetanus0" name="Tetanus0" [(ngModel)]="teatnus0" style="width:100px;margin-left:-18px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Tetanu1" name="Tetanus1" [(ngModel)]="teatnus1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Tetanus2" name="Tetanus2" [(ngModel)]="teatnus2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Tetanu3" name="Tetanus3" [(ngModel)]="teatnus3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Tetanus4" name="Tetanus4" [(ngModel)]="teatnus4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="Typhoid" style="font-size: 15px;font-weight: 500; color:#000000">Typhoid</label>
                            <input type="date" id="Typhoid0" name="Typhoid0" [(ngModel)]="typhoid0" style="width:100px;margin-left:27px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Typhoid1" name="Typhoid1" [(ngModel)]="typhoid1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Typhoid2" name="Typhoid2" [(ngModel)]="typhoid2" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Typhoid3" name="Typhoid3" [(ngModel)]="typhoid3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Typhoid4" name="Typhoid4" [(ngModel)]="typhoid4" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    <div class="vac-container">
                        <div class="vac">
                            <label for="Yellow" style="font-size: 15px;font-weight: 500; color:#000000">Yellow Fever</label>
                            <input type="date" id="Yellow0" name="Yellow0" [(ngModel)]="yellow0" style="width:100px;margin-left:2px ;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Yellow1" name="Yellow1" [(ngModel)]="yellow1" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY">
                            <input type="date" id="Yellow2" name="Yellow2" [(ngModel)]="yellow2" style="width:100px;font-size: 12px;;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Yellow3" name="Yellow3" [(ngModel)]="yellow3" style="width:100px;font-size: 12px;" class="rounded-input" placeholder="MMM-YYYY"> 
                            <input type="date" id="Yellow4" name="Yellow4" [(ngModel)]="yellow4" style="width:100px;font-size: 12px ;" class="rounded-input" placeholder="MMM-YYYY"> 
                        </div>
                    </div>
                    
                </div>   
                      
                      
                      
                       
                         
                        
    
            </div>
            </div>
    
   
    </div>
    
   
    <div class="row" style="text-align: end;">
        <span class="col">
            <button type="button" class="btn btn-secondary" 
                style="width: 140px; height: Hug(70px);margin-left: 600px; background-color: #D4D4D4; color: #000000; margin-top: 20px;"
                (click)="sectionl()">Previous</button>
        </span>
        
        <span class="col">
            <button type="submit" class="btn btn-primary"
                style="width: 130px; margin-right:100px;height: Hug(70px); border-radius: 8px; background-color: #8F1402; margin-top: 20px;"
                >Next</button>
        </span>
    </div>
        </form>
    </ng-template>

    

</div>
