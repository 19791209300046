import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; // Import this module
// import { Subscription } from 'rxjs';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './component/header/header.component';
import { SectionsComponent } from './sections/sections.component';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { MatSliderModule } from '@angular/material/slider';
import { HcpSectionsComponent } from './hcp-sections/hcp-sections.component';
import { HcpLoginComponent } from './hcp-login/hcp-login.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ProviderSectionsComponent } from './provider-sections/provider-sections.component';
import { IdCardComponent } from './id-card/id-card.component';
import { DialogPopUpComponent } from './dialog-pop-up/dialog-pop-up.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfoseekIdCardComponent } from './infoseek-id-card/infoseek-id-card.component';
import { HCPIdCardComponent } from './hcp-id-card/hcp-id-card.component';
import { HcpproviderComponent } from './hcpprovider/hcpprovider.component';
import { MatDialogModule } from '@angular/material/dialog';
// import { HcpNoteComponent } from './hcp-note/hcp-note.component';
// import { ProviderNoteComponent } from './provider-note/provider-note.component';
import { ProviderListComponent } from './provider-list/provider-list.component';
import { ReviewDoctorComponent } from './review-doctor/review-doctor.component';
import { CampLoginComponent } from './camp-login/camp-login.component';
import { authHcpGuard } from './Guard/auth-hcp.guard';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ReviewDoctorSecondComponent } from './review-doctor-second/review-doctor-second.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'; // Import MatProgressSpinnerModule
import { CustomDateAdapter } from './custum-date-adapter';
import { CUSTOM_DATE_FORMATS } from './custum-date-formate';

import {ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    SectionsComponent,
    HcpSectionsComponent,
    HcpLoginComponent,
    ProviderSectionsComponent,
    IdCardComponent,
    DialogPopUpComponent,
    InfoseekIdCardComponent,
    HCPIdCardComponent,
    HcpproviderComponent,
    // HcpNoteComponent,
    // ProviderNoteComponent,
    ProviderListComponent,
    ReviewDoctorComponent,
    CampLoginComponent,
    ReviewDoctorSecondComponent,
    
    
    
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MatSidenavModule,
    MatCardModule,
    FlexLayoutModule,
    HttpClientModule,
    MatSliderModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    BrowserAnimationsModule,
    MatIconModule, // Add MatIconModule
    MatDialogModule, // Add MatDialogModule here
    MatPaginatorModule,
    MatPaginatorModule,
    MatTableModule,
    MatProgressSpinnerModule,

    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,

  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
  
  bootstrap: [AppComponent]
})
export class AppModule { }
