<div class="main">
    <ng-template [ngIf]="displayName==='login'">
    <div class="card-constant">
      <div class="container">
        <h1 style="margin-left: 10px;">Login</h1>
        <div class="radio-buttons" >
          <input type="radio" id="loginWithHtml" name="loginType" [(ngModel)]="loginType" value="Login with html" (change)="switchTemplate()">
          <label for="loginWithHtml" style="margin-right: 150px;padding-left: 10px; color: #000000; margin-top: 40px;">Login with Email</label>
          <input type="radio" id="loginWithOtp" name="loginType" [(ngModel)]="loginType" value="Login with otp" (change)="switchTemplate()">
          <label for="loginWithOtp" style="padding-left: 10px; color: #000000;">Login with OTP</label>
        </div>
        <ng-template [ngIf]="loginType === 'Login with html'">
          <form (ngSubmit)="camppost()" #loginForm="ngForm">
            
            <div class="camplogin" ngModelGroup="loginForm">
              <label for="Email"></label>
              <input type="text" class="form-control" id="Email" name="email" placeholder="Email" required
                     [(ngModel)]="email" minlength="3" autocomplete="username" #emailInput="ngModel"
                     [ngClass]="{ 'highlight-input': emailInput.invalid && (emailInput.dirty || emailInput.touched) }">
              
        
              <label for="Password"></label>
              <input type="password" class="form-control" id="Password" name="password" placeholder="Password" required
                     [(ngModel)]="password" minlength="3" autocomplete="current-password" #passwordInput="ngModel"
                     [ngClass]="{ 'highlight-input': passwordInput.invalid && (passwordInput.dirty || passwordInput.touched) }">
             
        
              <!-- Display error message for wrong details -->
              <div *ngIf="loginError && loginForm.submitted && !emailInput.invalid && !passwordInput.invalid" class="error-message">
                Invalid email or password. Please try again.
              </div>
        
              <!-- Display message for not providing any details -->
              <div *ngIf="!loginError && loginForm.submitted && (emailInput.invalid || passwordInput.invalid)" class="error-message">
                Please enter valid details.
              </div>
            </div> 
            <button type="submit" class="btn btn-primary" style="margin-left: 150px; margin-top:20px;" (click)="loginClicked = true">Login</button>
          </form>
        </ng-template>
        
        <ng-template [ngIf]="loginType === 'Login with otp'">
          <!-- OTP form -->
          <form (ngSubmit)="reviewotp()">
          <div class="camplogin">
            <label for="Email"></label>
            <input type="email" class="form-control" id="Email" name="Email"  placeholder="Email" [(ngModel)]="email" required  minlength="3" >
            <div *ngIf="invalidDetailsMessage" class="error-message">
              {{ invalidDetailsMessage }}
            </div>
          </div>
 
    <button type="submit" class="btn btn-primary" style="margin-left: 150px;margin-top: 40px;">Send OTP</button>
        </form>
        </ng-template>
      </div>
      
    </div>
    
</ng-template>
  

    <ng-template [ngIf]="displayName==='otp'">
        <div class="card-constant">
          <form (ngSubmit)="campotp()">
            <div class="container">
                <div class="otpVerification">
                    <h1 style="margin-left:80px;margin-top: 30px;">Enter 4 Digits Code</h1>
                    <p class="fourdigits" style="margin-left: 70px;">Enter the 4 digits code that was sent to your mail for verification </p>
                    <div class="otp-input">
                      
                      <input
                        type="text"
                        maxlength="1"
                        [(ngModel)]="otpValue[0]"
                        (input)="onOtpInputChanges(0, input2)"
                        [ngModelOptions]="{ standalone: true }"
                        autofocus
                        #input1
                      />
                      <input
                        type="text"
                        maxlength="1"
                        [(ngModel)]="otpValue[1]"
                        (input)="onOtpInputChanges(1, input3)"
                        [ngModelOptions]="{ standalone: true }"
                        #input2
                      />
                      <input
                        type="text"
                        maxlength="1"
                        [(ngModel)]="otpValue[2]"
                        (input)="onOtpInputChanges(2, input4)"
                        [ngModelOptions]= "{ standalone: true }"
                        #input3
                      />
                      <input
                        type="text"
                        maxlength="1"
                        [(ngModel)]="otpValue[3]"
                        (input)="onOtpInputChanges(3, null)"
                        [ngModelOptions]="{ standalone: true }"
                        #input4
                      />
                   
                    </div>
                    
                    <p style="margin-top:30px ; margin-left:135px;"> Didn't recieve the code? <span style="color: #0057FF;">Resend </span> </p>
                    <div class="c" style="margin-left: 50px;">
                        <button class="a" type="submit" class="btn btn-primary" style="margin-left:70px;">Submit</button>
                    </div>
                </div>
            </div>
          </form>
        </div>
        
    </ng-template>
    
   <div class="new-div">
        <img style="width: 680px;height:500px;margin-left:-1500px; margin-top: 50px;" src="assets/images/continue.png">
    </div>
   


</div>
<p style="margin-top:0px;margin-left:850px; color:#000000;">Novel Implementation of a Vision for an Integrated System of Healthcare</p>
