import { Component } from '@angular/core';
import { Router,ActivatedRoute, } from '@angular/router';
import { NivishService } from '../nivish.service';

@Component({
  selector: 'app-camp-login',
  templateUrl: './camp-login.component.html',
  styleUrls: ['./camp-login.component.css']
})
export class CampLoginComponent {
  displayName='login';
  invalidDetailsMessage: any;
  result: any;
  successmsg: any;
  onSubmit: any;
  formData: any = {};
  email:any;
  password:any;
  otpValue: string[] = ['', '', '', ''];
  loginType: string = 'Login with html';
  message: any;
  emailotp: any;
  loginForm: any;
  loginError: boolean=false;
  loginClicked = false;
emailInput: any;
passwordInput: any;
  constructor(private routes:Router,private nivishservice:NivishService){}
  verfication(){
    this.displayName='verfication';
  }
  otp(){
    this.displayName='otp'
  }
  onOtpInputChanges(index: number, nextInput: HTMLInputElement | null): void {
    this.otpValue[index] = this.otpValue[index].replace(/[^0-9]/g, '');
    if (this.otpValue[index].length === 1 && index < 3 && nextInput) {
      nextInput.focus();
    }
  }
  reviewdoctor(){
    this.routes.navigate(['/reviewdoctor']);
  }
  switchTemplate() {
  }

  
  camppost() {
    if (this.email && this.password) {
      const loginpost = {
        Registered_Email: this.email,
        Password: this.password,
        Review_Doctor: true,
      };
      this.nivishservice.campreview(loginpost).subscribe((data: any) => {
        if (data['Status'] === 200) {
          this.routes.navigate(['/reviewdoctor']);
          this.message = 'login Posted';
        } else {
          // Set a flag for login errors
          this.loginError = true;
        }
      });
      
    } else {
      // Mark the form as submitted to trigger error messages
      this.loginForm.control.markAsTouched();
      // Reset the loginError flag for not providing any details
      this.loginError = false;
    }
  }

reviewotp() {
  const emaild = this.email;
  const body = {
    Name: null,
    Date_of_Birth: null,
    Email: this.email,
    MobileNumber: null,
  };

  console.log(body);
  localStorage.setItem('person', JSON.stringify(body));

  this.nivishservice.hcpVerify(body).subscribe(
    (data: any) => {
      if (data['Status'] === 200) {
        this.successmsg = 'Verification successful';
        this.invalidDetailsMessage = '';
        this.displayName = 'otp';
      } else if (data['Status'] === 400) {
        this.successmsg = '';
        this.invalidDetailsMessage = 'Invalid OTP. Please try again.';
      } else {
        this.successmsg = '';
        this.invalidDetailsMessage = 'An error occurred';
      }
      console.log('Data posted successfully:', data);
    },
    (error: any) => {
      this.successmsg = '';
      this.invalidDetailsMessage = 'Invalid Details';
      console.log('Error posting data:', error);
    }
  );

  console.log('Form submitted with data:');
}



 




 campotp(){
  const oneTime = this.otpValue.join('')
  const verify= {
    Name: null,
    Date_of_Birth: null,
    Email:this.email,
    MobileNumber: null,
    Otp: oneTime,
    Review_Doctor: true
  };
  console.log("otp",verify)
  if (verify.Email) {
    // Make the API call to verify OTP
    this.nivishservice.hcponeTime(verify).subscribe(
      (data: any) => {
        // Check for a valid response structure
        if (data && data.Result) {
          const hcpId = data.Result.HCPID;
          console.log(hcpId, 'campaignid');

          if (hcpId !== undefined) {
            localStorage.setItem('campid', hcpId.toString());
            this.routes.navigate(['/reviewdoctor']);
            console.log(data, 'campdata');
          } else {
            console.error('HCPID is undefined in the response.');
          }
        } else {
          console.error('Invalid response structure:', data);
        }
      },
      (error) => {
        console.error('Error during OTP verification:', error);
      }
    );
  }
}

}
