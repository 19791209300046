import { Component,Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {NivishService} from '../nivish.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  displayName='userRegistration';
  formData:any= {studentFirstName:'',
  studentDOB:'',
  mothersFirstName:'',
  emailId:'',}
  verificationCode='';
  otpValue: string[] = ['', '', '', ''];
  invalidDetailsMessage: any;
  successmsg: any;
  result: any;
  oneTime:any;


  studentPersonalInformation:any;
  studentMiddleName1:any;
  studentMiddleName2:any;
  studentLastName:any;
  dateofBirth: string = '';
    age:number | null = null;
  noofSisters:any;
  noOfBrothers:any;
  sleeppernight:any;
  healthInsuranceInformation: any = {};
  addressInformation: any = {};
  guardianInformation: any = {};
  // displayName='sectionA';
  healthInsurance:any;
  studentFirstName:any;
  Insurance:any;
  gender:any;
  blood:any;
  nightsleep:any;
  wheredoesitcomefrom:any;
  Typeofmeal:any;
  Rhfactor:any;
  ethnicity:any;
  fatherethnicity:any;
  varioushouseholdchores:any;
  SelectedstatesId:any;
  digitalSignImageUrl: string | null = null;
  studentPhotoImageUrl: string | null = null;

  // sectionaForm!:NgForm;
  // sectioncForm!:NgForm;
  // sectiondForm!:NgForm;
  notelastdate:any;
  studentname:any;
  parentsname:any;
  typeclass:any;
  signature:any;
  noteplace:any;
  notedate:any;
  // sectionb-----------------------------------------------------------------------------------------------------------------
  mediAid="";
    policy:any;
  expireDate:any;
  getallcountry:any;
// sectionC------------------------------------------------------------------------------------------------------------------------
  streetAddress:any;
  city:any;
  Countrys:any;
  postalCode:any;
  phNumber:any;
  motherPhNumber:any;
  fatherPhNumber:any;
  studentphNumber:any;
  emailShm:any;
  Apartment_Name:any;
  apartmentNumber:any;
  alternativeBelongsTo:any;
  areaC:any;
  states:any;
  Country:any;
  selectedCountryId:any;
  SelectedcityId:any;
  showDatePicker: boolean = false;
  // selectedDate: NgbDateStruct | null = null;

// sectionD------------------------------------------------------------------------------------------------
  mobile_1:any;
  mobile1_belongs:any; 
  mobil1eothers:any;
  mobilebelong_Fn:any;
  mobilebelong_Ln:any;
  mobile_2:any;
  mobile2_belongs:any;
  mobil2eothers:any;
  mobile2belong_Ln:any;
  mobile2belong_Fn:any;
  familyDocName:any;
  docContactNumber:any;

  selectedActivities:string[] = [];
  date:any;
  ParentName: any;
  relationshipStudent:any;
  StudentName:any;
  Class:any;
  otherscame:any;
  Mother:any;
  Father:any;
  Guardian:any;
  Place:any;
  policies: any;
  studentsreactiontochangeothers:any;
 
 
  isOthersChecked:boolean=false;
  isOthersRelationshipChecked = false;
  isOthersReactionChecked = false;
  mealsPerDay: any;
  UsualNumberofSnacksPerDay: any;
  fluidIntake: any;
  mealType: any;
  IfYeswheredoesitcomefrom:any; 
  HawKersorstalls:any; 
  breakfastRegular: any;
 regularmealatschool:any;
 foodIntolerances: string[] = [];
 StudentFreshandRelaxedAfterniNghtsSleep:any;
  SleepRelatedIssues:any;
  AverageLengthOfSleepPerNight:any;
  napCycleDuringtheDay:any;
  mealTypeothers:any;
  IfYeswheredoesitcomefromothers:any;
  ethnicityothers:any;
  SocialPersonalityOfTheStudent:any;
  AnyIrrationalFearsOrPhobias:any;
  StudentSelfImageSelfWorth:any;
  studentsreactiontochange:any;
  relationshipwithotherstudents:any;
  significantlyclosefriends:any;
  girlsCount: any;
  boysCount: any;
  selectedNumber:any;
  selectedNumberrate:any;
  selectedNumberHealth:any;
  StudentGeneralOpinioofSchool:any;
  studentgoestoschool:any;
  RespectTotheStudent:any;
  studentPersonalInformationothers:any;
  SchoolPerformanceSignificantlyAffected:any;
  SchoolPerformanceSignificantlyDissatisfied:any;
  othertypeofmeal:any;
  otherMealatSchool:any;
  fathersFirstName: any;
  fathersLastName: any;
  mothersLastName: any;
  mothersFirstName: any;
  version:string='2.0';
  userId: any;
  resendButtonDisabled = false;
  countdownSeconds: number = 0;
  countdownInterval: any;
  otpSentMessage:any;
  otpExpired: boolean = false;
  showResendError: boolean = false;
  invalidDetailsMessages:any;


  constructor( private routes:Router,private nivishservice:NivishService ){
    console.log('Component initialized');
  }

  onOtpInputChange(index: number, nextInput: HTMLInputElement | null, prevInput: HTMLInputElement | null): void {
    this.otpValue[index] = this.otpValue[index].replace(/[^0-9]/g, '');
  
    if (this.otpValue[index] === '' && prevInput) {
      prevInput.focus();
    } else if (this.otpValue[index] !== '' && nextInput) {
      nextInput.focus();
    }
  }
  onKeyDown(event: KeyboardEvent, prevInput: HTMLInputElement | null, nextInput: HTMLInputElement | null): void {
    if (event.key === 'ArrowLeft' && prevInput) {
      prevInput.focus();
    } else if (event.key === 'ArrowRight' && nextInput) {
      nextInput.focus();
    }
  }



  onSubmit() {
    const emailId = this.formData.emailId;
    const details = {
    
        
      Student_FirstName: this.formData.studentFirstName,
      Student_DOB: this.formData.studentDOB,
      Mothers_FullName: this.formData.mothersFirstName,
      Email: this.formData.emailId,
      };
      
    
      localStorage.setItem('details', JSON.stringify(details));
    // console.log(details)
    this.nivishservice.userVerification(details).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          this.successmsg = 'Verification successful';
          this.invalidDetailsMessage = ''; 
          this.displayName = 'otp';
          
        } else if (data['Status'] === 400) {
          this.successmsg = ''; 
          this.invalidDetailsMessage = data['Message']; 
        } else {
          this.successmsg = ''; 
          this.invalidDetailsMessage = 'An error occurred'; 
        }
        // console.log('Data posted successfully:', data);
      },
      (error) => {
        this.successmsg = '';
        this.invalidDetailsMessage = 'Invalid Details'; 
        // console.log('Error posting data:', error);
      }
    );
  
    // console.log('Form submitted with data:');
  }
  terms(){
    this.displayName='terms';
  }
  otp(){
    this.displayName='otp';
  }
  // terms(){
  //   this.displayName='terms';
  // }
  user(){
    this.displayName='userRegistration';
  }

   accept(){
    this.routes.navigate(['/register']);
  }
  termsconditions() {
    try {
      const userId = this.userId;
      if (!userId) {
        console.error('User ID is missing.');
        return;
      }
    
      const currentDate = new Date().toISOString().split('T')[0];
    
      const terms = {
        Terms_and_conditions: true,
        Version: this.version || '', // Ensure default value if this.version is null/undefined
        Date: currentDate,
      };
    
      // console.log('Updating terms:', terms);
    
      this.nivishservice.updateterms(userId, terms).subscribe(
        (data: any) => {
          if (data && data.Status === 200) {
            this.displayName = 'termsupdate';
            this.routes.navigate(['/register']);
          } else {
            console.error('Failed to update terms. Unexpected response:', data);
          }
        },
        (error: any) => {
          console.error('Error updating terms:', error);
        }
      );
    } catch (error) {
      console.error('Error in termsconditions():', error);
    }
  }
  
  


  otpSubmit() {
    const oneTime = this.otpValue.join('');
    const emailId = this.formData.emailId;
    const verificationData = {
      Student_FirstName: this.formData.studentFirstName,
      Student_DOB: this.formData.studentDOB,
      Mothers_FullName: this.formData.mothersFirstName,
      Email: emailId,
      Otp: oneTime,
    };
  
    if (verificationData.Email) {
      // const postData = {
      //   Student_FirstName: this.formData.studentFirstName,
      //   Student_DOB: this.formData.studentDOB,
      //   Mothers_FullName: this.formData.mothersFirstName,
      //   Email: emailId,
      //   Otp: oneTime,
      // };
  
      this.nivishservice.otpVerfication(verificationData).subscribe(
        (data: any) => {
          if (data['Status'] === 200) {
            this.successmsg = 'Verification successful';
            this.invalidDetailsMessage = '';
            this.displayName = 'terms';
      
            this.userId = data.Result?.InfoseekId;
            const userId=this.userId 
            localStorage.setItem('userId', userId.toString()); 
            const InfoseekMasterId = data.InfoseekMasterId;
            const userDOB = data.Result?.Student_DOB;
          
                
                // this.policy =localStorage .getItem('Policy_Card');
                // this.expireDate = localStorage .getItem('Expiry_Date');
      
            if (userId) {
              
              // console.log('Posted data ID:', userId);
            } else {
              console.error('Required data "userId" not found in the response:', data);
            }
      
            if (InfoseekMasterId) {
              localStorage.setItem('InfoseekMasterId', InfoseekMasterId.toString());
              // console.log('InfoseekMasterId:', InfoseekMasterId);
            } else {
              console.error('Required data "InfoseekMasterId" not found in the response:', data);
            }
      
            if (userDOB) {
              localStorage.setItem('userDOB', userDOB.toString());
              // console.log('userDOB:', userDOB);
            } else {
              console.error('Required data "userDOB" not found in the response:', data);
            }
          } else if (data['Status'] === 400) {
            this.successmsg = '';
            this.invalidDetailsMessage = data['Message'];
          }
          // console.log('Data posted successfully:', data);
        },
        (error:any) => {
          this.successmsg = '';
          this.invalidDetailsMessage = 'Invalid OTP or Expired';
          console.error('Error posting data:', error);
        }
      );
      } else {
        console.error('EmailId is missing.');
      }
    }
  
    resetOtpValues() {
      this.otpValue = ['', '', '', ''];
    }
    allowOnlyNumbers(event: KeyboardEvent) {
      const pattern = /[0-9]/;
      const inputChar = String.fromCharCode(event.charCode);
    
      if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
      }
    }
    onResendClick() {

      
      this.otpExpired = false;
      this.showResendError = true;
      this.invalidDetailsMessages = 'OTP sent to your email';
      this.invalidDetailsMessage= false;
  
      // Disable the Resend button
      this.resendButtonDisabled = true;
      this.resetOtpValues();
  
      this.startCountdown();
      const emailId = this.formData.emailId;
      const details = {
      
     
        Student_FirstName: this.formData.studentFirstName,
        Student_DOB: this.formData.studentDOB,
        Mothers_FullName: this.formData.mothersFirstName,
        Email: this.formData.emailId,
        };
        
      
        localStorage.setItem('details', JSON.stringify(details));
      // console.log(details)
      this.nivishservice.userVerification(details).subscribe(
        (data: any) => {
          if (data['Status'] === 200) {
            this.successmsg = 'Verification successful';
            this.invalidDetailsMessage = ''; 
            this.displayName = 'otp';
            
          } else if (data['Status'] === 400) {
            this.successmsg = ''; 
            this.invalidDetailsMessage = data['Message']; 
          } else {
            this.successmsg = ''; 
            this.invalidDetailsMessage = 'An error occurred'; 
          }
          // console.log('Data posted successfully:', data);
        },
        (error) => {
          this.successmsg = '';
          this.invalidDetailsMessage = 'Invalid Details'; 
          // console.log('Error posting data:', error);
        }
      );
      
      
    
      // console.log('Form submitted with data:');
    }
    


    startCountdown() {
      this.countdownSeconds = 10;
  
      clearInterval(this.countdownInterval);
  
      this.countdownInterval = setInterval(() => {
        if (this.countdownSeconds > 0) {
          this.countdownSeconds--;
        } else {
          this.resendButtonDisabled = false;
          clearInterval(this.countdownInterval);
        }
      }, 1000); 
    }
  
    closeErrorMessage() {
      this.showResendError = false;
    }
  
   
    // formatCountdownTime(seconds: number): string {
    //   const minutes = Math.floor(seconds / 60);
    //   const remainingSeconds = seconds % 60;
    //   const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    //   const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    //   return `${formattedMinutes}:${formattedSeconds}`;
    // }
}
function userId(userId: any, terms: { Terms_and_conditions: boolean; Version: number; Date: string; }) {
  throw new Error('Function not implemented.');
}

