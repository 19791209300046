import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const providerGuardGuard: CanActivateFn = (route, state) => {
  const router = inject(Router); // Inject the Router

  const loginDetails = sessionStorage.getItem('formData');
  if (loginDetails) {
    return true;
  } else {
    return router.createUrlTree(['/hcpproviderlogin']);
  }
};
