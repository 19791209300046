import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NivishService } from 'src/app/nivish.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  loginSuccess: boolean = false;
  constructor(public nivishservice: NivishService,private routes:Router){ 

  }
  isLoggedIn(): boolean {
    return this.nivishservice.isLoggedIn();
  }
  logout() {
    console.log('Logout clicked');
    const lastVisitedUrl = sessionStorage.getItem('lastVisitedUrl');
  if (lastVisitedUrl) {
    const url = new URL(lastVisitedUrl);
    this.routes.navigate([url.pathname]);
  } else {
    this.routes.navigate(['/']);  
  }
    this.nivishservice.setLoggedIn(false);
  }
}
