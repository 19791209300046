import { Component,EventEmitter, Output  } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NivishService } from '../nivish.service';
import { DialogPopUpComponent } from '../dialog-pop-up/dialog-pop-up.component';
import {  MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-hcp-sections',
  templateUrl: './hcp-sections.component.html',
  styleUrls: ['./hcp-sections.component.css'],
  providers: [DatePipe]

})

export class HcpSectionsComponent {
  displayName='personal';
  @Output() selectedGenderChange = new EventEmitter<string>();
  personFullName: string = '';
  personDateOfBirth: string = '';
  personMobileNumber: string = '';
  sbForm:any;
  personEmail: string = '';
  person: any = {};
  selectedGender: string = ''; 
  issuedDate:any;
  validateTill:any;
  validity:any;
  myId: number=1;
  provide: any;
  fileUpd:any;
  message: any;
  nameOfInstitute:any;
  typeOfDegree:any;
  fieldOfStudy:any;
  Country:any;
  toDt:any;
  fromDt:any;
  selectedCategory:any;
  selectedCategoryothers:any;
  LicenseAuthority:any;
  LicenseAuthorityother:any;
  Years:any;
  Months:any;
  Licensenumber:any;
  State:any;
  allLicense: any;
  allEducation:any;
  personData: any;
  Name: any;
  Email: any;
  MobileNumber: any;
  Date_of_Birth: any;
  licenseExp!: NgForm; 
  errorLicense: any;
  licenseget: any;
  licenseData: any;
  EducationData: any;
  dataloading:boolean=false;
  dataLoaded:boolean=false;
  private hasReloaded: boolean = false;
  personalForm!:NgForm ;
  errorText: any;
  notedates:any;
  signaturename: any;
  loadImage: any;
  studentPhotofilename: any;
  selectedFileName: any;
  hcpPersonPicName: string | undefined;
  hcpErrorPic: any;
  noteForm!: NgForm;
  termsmessge: any;
  Upload_Your_Sign: any;
  errorsignImage: any;
  errorNote: any;
  personLastName:any;
  personMiddleName:any;
  hcppersonType:any;
  personcategoryType:any;
  personcategoryTypeOther:any;
  identificationForm!: NgForm;
  hcpPersonnationality:any;
  hcpPersonemiratesid:any;
  hcpPersonPassport:any;
  fileTypeError: boolean = false;
  selectedImage: any;
  selectedUserProfile:any | null = 'null';
  studentPhoto:any | null = 'null';
  signofParent:any;
  lstDtofSignCopy:any;
  typurName:any;
  standardClass:any;
  gurdianOf:any;
  submittedDt:any;
  placeof:any;
  terms:boolean=false;
  data: any;
  picIdpro: any;
  picId: any;
  errorMessage: any;
  minDate: string;
  maxDate: string;
  pID:any;
  digitalSignImageUrl: string | null = null;
  studentPhotoImageUrl: string | null = null;
  statesList:any;
  CountryList:any;

  policies:any;
  errorTextIdentification: any;
  addLicnesemessage: any;
  noLicenseData: boolean=false;
  noEducationData: boolean=false;
  addEducationmessage: any;

  constructor(private routes:Router,private nivishservice:NivishService,private dialog: MatDialog,private datePipe: DatePipe){
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    this.submittedDt = `${year}-${month}-${day}`;
    this.minDate = this.submittedDt; 
    this.maxDate = this.submittedDt; 
    
    const nextWeek = new Date(today);
    nextWeek.setDate(today.getDate() + 7);
    
    const yearToday = today.getFullYear();
    const monthToday = (today.getMonth() + 1).toString().padStart(2, '0');
    const dayToday = today.getDate().toString().padStart(2, '0');
    this.lstDtofSignCopy = `${yearToday}-${monthToday}-${dayToday}`;
    
    const yearNextWeek = nextWeek.getFullYear();
    const monthNextWeek = (nextWeek.getMonth() + 1).toString().padStart(2, '0');
    const dayNextWeek = nextWeek.getDate().toString().padStart(2, '0');
    this.lstDtofSignCopy = `${yearNextWeek}-${monthNextWeek}-${dayNextWeek}`;
    
    this.minDate = today.toISOString().split('T')[0]; 
    this.maxDate = this.lstDtofSignCopy;
  }
  ngOnInit(){
    this.fetchCountry();
    this.personalgetbyId();
    this.getlicenseByid();
    this.getEducationById();
    this.getlicenseExperience();
    this.presonal();

  }

  selectMale(){
    this.selectedGender = 'Male';
  }

  selectFemale(){
    this.selectedGender = 'Female';
  }

  sectionCompletion = {
    personal: false,
    License: false, 
    Education: false,
    Note: false,
    identification:false,
  };
  onKeyPress(event: KeyboardEvent) {
    const allowedChars = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!allowedChars.test(inputChar)) {
      event.preventDefault();
    }
  }
  
  presonal()
  {
    this.displayName='personal';
    this.sectionCompletion.personal = true;
  }
  License(){
    this.displayName='License';
    this.sectionCompletion.License = true;
  }
  Education()
  {
    this.displayName='Education';
    this.sectionCompletion.Education = true;
  }
  sidebarEducation()
  { 
    this.getlicenseByid(() => {

    if (this.noLicenseData) { 
      this.addLicnesemessage = 'Add some license details';
    }
    else{
      this.displayName='Education';
      this.sectionCompletion.Education = true;
    }
  });

  } 

Note(){
  this.getEducationById(() => {

    if (this.noEducationData) {
      this.addEducationmessage = 'Add some Education details';
    }
    else{
      this.displayName= 'Note';
      this.sectionCompletion.Note = true;
    }
  })
}
onCountryChange(event: any) {
  this.hcpPersonnationality = event;
  this.getStatesForCountry(this.hcpPersonnationality);
}
getStatesForCountry(selectedCountryId: any) {
  this.nivishservice.getstate(selectedCountryId).subscribe(
    (response: any) => {
      if (response && response.Result) {
        this.statesList = response.Result;
      }
    },
    (error: any) => {
      
    }
  );
}
fetchCountry() {
  this.nivishservice.getallcountry().subscribe(response => {
    if (response && response.Result) {
      this.CountryList = response.Result;

    }
  });
}
displayPartialUrl(fullUrl: string): string {
  const maxLength = 30;

  if (fullUrl.length > maxLength) {
    return fullUrl.slice(0, maxLength) + '...';
  } else {
    return fullUrl;
  }
}
formatDateString(date: string | null): string {
  if (date) {
    return date.replace(/-/g, '/');
  }
  return '';
  
}
isPDF(url: string | null | undefined): boolean {
  if (!url) {
    return false; // If the URL is null or undefined, return false
  }
  
  const extension = url.split('?')[0].split('.').pop();
  return extension?.toLowerCase() === 'pdf';
}
onDateChange(event: any): void {
  const inputDate = event.target.value;
  this.submittedDt = this.formatDateString(inputDate);
}
dateConvert(dateString: string): string {
  const date = new Date(dateString);
  const formattedDate = this.datePipe.transform(date, 'dd-MMM-yyyy');
  return formattedDate ? formattedDate : ''; // Provide a default value if null
}
formatDateStringa(dateString: string): string {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' };
  return date.toLocaleDateString('en-US', options);
}
addLicense(){
  const dialogRef = this.dialog.open(DialogPopUpComponent, {
    data: { displayName:  'License' },
  });
  dialogRef.afterClosed().subscribe(() => {
    this.getlicenseByid();
  });

}

getLicense(id: number){
  
  const dialogRef = this.dialog.open(DialogPopUpComponent, {
    data: { displayName:  'Licenseget',licenseId: id },
  });
  dialogRef.afterClosed().subscribe(() => {
    this.getlicenseByid();
  });

}
getEdu(id:number){
  const dialogRef = this.dialog.open(DialogPopUpComponent, {
    data: { displayName:  'Eductaionget',EducationId: id },
  });
  dialogRef.afterClosed().subscribe(() => {
    this.getEducationById();    
  });
}
addEducation(){
  const dialogRef = this.dialog.open(DialogPopUpComponent, {
    data: { displayName:  'Eductaion' },
  });
  dialogRef.afterClosed().subscribe(() => {
    this.getEducationById();
  });
}

eductn(){
  this.displayName='Education';
}

onFileSelected(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  if (inputElement?.files) {
    const file = inputElement.files[0];
    this.fileUpd = file;
  }
}

educationPost(){
  const eductn={
    HcpId:this.myId,
    Provider:this.provide,
    Name_of_institute:this.Country,
    Type_of_degree:this.typeOfDegree,
    Filed_of_study:this.fieldOfStudy,
    Country:this.Country,
    from_Date:this.fromDt,
    to_Date:this.toDt,
    Upload_certificate:this.fileUpd.toString()
  }
  this.nivishservice.educationUpload(eductn).subscribe(
    (data:any)=>{
      if(data['Status']===200){
        this.message='eduction Posted';
      }
    })
}

personalgetbyId() {
  const verId = localStorage.getItem('verid');
  this.nivishservice.personalhcpgetbyid(verId).subscribe(
    (data: any) => {
      this.person = data; 
      console.log(this.person, "ravan");
      if (this.person && this.person.Result && this.person.Result.length > 0) {
        this.personFullName = this.person.Result[0].First_Name || '';
        this.personMiddleName = this.person.Result[0].Middle_Name || '';
        this.personLastName = this.person.Result[0].Last_Name || '';
        this.personcategoryType = this.person.Result[0].Category || '';
        this.hcppersonType = this.person.Result[0].Type || '';
        this.hcpPersonnationality=this.person.Result[0].Nationality || '';
        this.hcpPersonemiratesid=this.person.Result[0].Emirates_Id || '';
        this.hcpPersonPassport=this.person.Result[0].Passport || ''

        this.personDateOfBirth = this.person.Result[0].Date_of_Birth || '';
        this.personMobileNumber = this.person.Result[0].Registered_MobileNumber || '';
        this.personEmail = this.person.Result[0].Registered_Email || '';
        this.selectedGender = this.person.Result[0].Gender || '';
        this.studentPhotoImageUrl=this.person.Result[0].Upload_Your_Photo || '';
        if(this.studentPhotoImageUrl){
          const filename=new URL(this.studentPhotoImageUrl).pathname.split('/').pop();
          this.hcpPersonPicName=filename
        }
      }
    });
  }

personalupdates(form: NgForm) {
  this.personalForm=form;
  if (form.invalid) {
    this.errorText = 'Please fill in all the required details';
    Object.keys(form.controls).forEach(key => {
      form.controls[key].markAsTouched();
    });
  } else {
    this.errorText = ''; 
    this.savePersonalUpdate(form.value);
  }
}
formatDate(date: string | Date): string {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}
savePersonalUpdate(personalData: any) {
  const verId = localStorage.getItem('verid');
  
  const formattedDateOfBirth = this.formatDate(this.personDateOfBirth);

  const persnupd = {
    First_Name: this.personFullName,
    Middle_Name: this.personMiddleName,
    Last_Name: this.personLastName,
    Category: this.personcategoryType,
    Date_of_Birth: formattedDateOfBirth,
    Registered_Email: this.personEmail,
    Gender: this.selectedGender,
    Registered_MobileNumber: this.personMobileNumber,
  };

  console.log(persnupd);
  this.nivishservice.personalhcpUpdate(verId, persnupd).subscribe(
    (data: any) => {
      if (data['Status'] === 200) {
        this.hcpErrorPic = ''
        console.log(data,"data")
        this.message = 'person Updated';
        this.hcpstudentpicUpd();
      }  
    }
  );
}

allowOnlyNumbers(event: KeyboardEvent) {
  const pattern = /[0-9]/;
  const inputChar = String.fromCharCode(event.charCode);

  if (!pattern.test(inputChar)) {
    event.preventDefault();
  }
}

licenseExpPost(form: NgForm) {
  this.licenseExp = form;

  if (form.invalid) {
    this.errorLicense = 'Please fill in the License Experience';
    Object.keys(form.controls).forEach(key => {
      form.controls[key].markAsTouched();
    });
  } else {
    this.errorLicense = '';
  }

  if (form.valid) {
    this.saveLicenseExpPost(form.value);
  }
}
saveLicenseExpPost(licenseD:any){

  const verId = localStorage.getItem('verid');
  const liExp={
    HCPID:verId,
    ProviderID:this.provide||null,
    Total_Experience_Years:this.Years,
    Total_Experience_Months:this.Months,
    Sub_Contracted_Form:this.sbForm,

  }
  this.nivishservice.hcpProviderExpupd(verId,liExp).subscribe(
    (data:any)=>{
      if(data['Status']===200){
        this.message='experience Updated';
        this.getlicenseByid(() => {

          if (this.noLicenseData) { 
            this.addLicnesemessage = 'Add some license details';
          }
          else{
            this.Education();
          }
        });
        
      }
    })
}
getlicenseExperience() {
  const verId = localStorage.getItem('verid');

  this.nivishservice.hcplicenseExpGet(verId).subscribe((data: any) => {
    this.Years = data.Result[0].Total_Experience_Years;
    this.Months = data.Result[0].Total_Experience_Months;
    this.sbForm = data.Result[0].Sub_Contracted_Form;
    console.log(data,"licexp");
  });
}
getlicenseByid(callback?: () => void){
  const verId = localStorage.getItem('verid');
  this.nivishservice.hcplicenseget(verId).subscribe(
    (data:any)=>{
      if (data.Result && data.Result.length > 0) {
        this.licenseData=data;
        this.dataLoaded=true;
        this.noLicenseData = false;
        this.addLicnesemessage='';
      }else{
        this.noLicenseData = true;
  
      }
      if (callback) callback();
    });  
  }
getEducationById(callback?: () => void){
  const verId = localStorage.getItem('verid');
  this.nivishservice.hcpEducationGet(verId).subscribe(
    (data:any)=>{
      if (data.Result && data.Result.length > 0) {
        this.EducationData=data;
        this.dataloading=true;
        this.noEducationData = false;
        this.addEducationmessage='';
      }else{
        this.noEducationData = true;
      }
      if (callback) callback();
    })
}

onProfilePictureSelecte(event: any) {
  const file: File = event.target.files[0];

  if (file) {
    this.selectedUserProfile = file;
    this.Upload_Your_Sign=file;
    this.fileTypeError = false;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.selectedImage = e.target.result;
      this.digitalSignImageUrl = e.target.result;
    };
    reader.readAsDataURL(file);
  } else {
    this.fileTypeError = true;
    this.selectedImage = '/assets/images/profilepicture.png';
    
    this.digitalSignImageUrl = '/assets/images/profilepicture.png';
  }
}

onProfilePictureSelected(event: any) {
  const file: File = event.target.files[0];
  if (file) {
    this.studentPhoto = file;
    this.fileTypeError = false;
    this.selectedFileName = file.name;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.selectedImage = e.target.result;
      this.studentPhotoImageUrl = e.target.result;
    };
    reader.readAsDataURL(file);
  } else {
    this.fileTypeError = true;
    this.selectedFileName = '';
    this.selectedImage = '/assets/images/profilepicture.png';
    this.studentPhotoImageUrl = '/assets/images/profilepicture.png';
  }
}

hcpnotesubmit(form: NgForm) {
  this.noteForm=form;
  if (form.invalid) {
    this.errorNote = 'Please fill in all the required details';
    Object.keys(form.controls).forEach(key => {
      form.controls[key].markAsTouched();
    });
  } else {
    this.errorNote = ''; 
    this.saveNoteUpdate(form.value);
  }
}
saveNoteUpdate(notedata:any){
  const verId = localStorage.getItem('verid');

  
  const providerId = localStorage.getItem('providerId');
  const note={
    HCPID:verId,
    ProviderID:this.pID||null,
    // Last_date_signed_copy_of_form:this.lstDtofSignCopy,
    Full_Name:this.typurName,
    // guardian_of:this.gurdianOf,
    // of_class:this.standardClass,
    Signature:this.signaturename,
    Submitted_date:this.submittedDt,
    Place:this.placeof,
    Accepted:this.terms,
    // Terms_and_conditions:this.terms,
  }
  if (!this.terms) {
    this.termsmessge = 'Policies acceptance is required.';
    return; 
  }
  console.log(note,"note");
  this.nivishservice.hcpnote(note).subscribe(
    (data: any) => {
      if (data && data.Result) {
       
         this.picId = data.Result.id;
        // console.log(this.picId,"picid")
        this.note();
      } else {
      }
    },
    (error) => {
      
      console.error('Error occurred:', error);
    });
}
note() {
  const picIdnote =this.picId;
  // console.log(picIdnote,"idssss");
  if (!this.Upload_Your_Sign) {
    this.errorsignImage = 'Upload sign is required.';
   
    return; 
  }
  const digitalSignFormData = new FormData();
  digitalSignFormData.append('id', picIdnote);
  digitalSignFormData.append('Upload_Your_Sign', this.Upload_Your_Sign);
  // digitalSignFormData.append('Upload_Your_Photo', this.studentPhoto);
  // console.log(digitalSignFormData, "narayansss");

  this.nivishservice.updateProviderNote(picIdnote, digitalSignFormData).subscribe(
    (data: any) => {
      if (data.body && data.body.Status === 200) {
        this.message = 'note';
        this.routes.navigate(['/HCPIdcard']);
       
      
      }
      // console.log(this.message,'mmmmmmmmm');
      console.log(data,'dddddddddddd');
    },
    (error: any) => {
      console.error('Error:', error);
      // Handle the error here (e.g., show an error message to the user)
    }
  );
}

// personalupdates() {
  
//   // Check if the form is valid before proceeding
//   if (this.personalForm.valid) {

//   }
// }

hcpstudentpicUpd() {
  const verid = localStorage.getItem('verid');
  if (!verid) {
    console.log('userId not found in local storage.');
    return;
  }
  if(this.studentPhoto === 'null'){
   
    if (!this.hcpPersonPicName || this.hcpPersonPicName === '') {
      this.hcpErrorPic="Please Upload Image ";
        } else {
      
      this.identification();
    }
  } else{
  const picnoteFormData = new FormData();
  picnoteFormData.append('HCPID', verid);
  
  picnoteFormData.append('Upload_Your_Photo', this.studentPhoto);
  console.log(picnoteFormData, "narayansss",this.studentPhoto);
  

  this.nivishservice.hcpupdateNote(verid, picnoteFormData).subscribe(
    (data: any) => {
      if (data['Status'] === 200)  {
        this.message = 'note';
        this.identification();
   
      } else {
        console.error('Error updating note:', data);
        if (data['HasError'] && data['Message']) {
          console.error('Server error message:', data['Message']);
        }
      }
      console.log(this.message,'mmmmmmmmm');
      console.log(data,'dddddddddddd');
    },
    (error: any) => {
      console.error('Error updating note:', error);
      if (error.status === 400) {
        console.error('Record not found. The specified ID might be incorrect.');
      }
      // Handle the error here (e.g., show an error message to the user)
    }
  );
}
}
identificationUpdate(form:NgForm) {
  this.identificationForm=form;
  if (form.invalid) {
    this.errorTextIdentification = 'Please fill in all the required details';
    Object.keys(form.controls).forEach(key => {
      form.controls[key].markAsTouched();
    });
  } else {
    this.errorText = ''; // Clear the general error message if the entire form is valid
    this.identificationdataupdate(form.value);
  }
}
identificationdataupdate(idfydata:any){
  const verId = localStorage.getItem('verid');


  const updRegId= localStorage.getItem('updRegId');

  const providerId = localStorage.getItem('providerId');
  const identification={
    Nationality: this.hcpPersonnationality,
    Emirates_Id:this.hcpPersonemiratesid,
    Passport: this.hcpPersonPassport
  }
  this.nivishservice.hcpIdentificationUpdate(verId,identification).subscribe(
    (data:any) => {
      if(data['Status']===200){
        this.errorTextIdentification='';
        this.License();
      }
    }
  )
}
  // // // console.log(userspost, "projectv");

  // // this.nivishservice.personalhcpUpdate(updRegId,userspost).subscribe(
  // //   (data: any) => {
      
  // //     if (data['Status'] === 200) {
  // //       const hcpproId = data.Result.HCPID;
  // //       // console.log(hcpproId, 'babu');

  // //       localStorage.setItem('hcpproId', hcpproId.toString());
  // //       // console.log("proid", hcpproId);

  // //       this.message = 'person posted';
  // //       this.hcpproId = hcpproId;
  // //       this.providerstudentpicUpd();

      
  // //       this.getlicenseByid();
  // //       this.getEducationById();
  // //           } else {

  // //     }
  // //     // console.log(data, "power");

  // //   },
  // //   (error: any) => {
  // //     console.error(error);
  // //   }
  // );


  
identification(){
  this.displayName='Identification';
  this.sectionCompletion.identification = true;

}

}


