import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { NivishService } from '../nivish.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { takeWhile } from 'rxjs';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-dialog-pop-up',
  templateUrl: './dialog-pop-up.component.html',
  styleUrls: ['./dialog-pop-up.component.css']
})
export class DialogPopUpComponent {
  countries: { id: number; name: string }[] = [];
  states: { id: number; name: string }[] = [];
  LicenseSpecialisation:any;
  LicenseSpecialisationUpdate:any;
  licenseId: any;
  EducationId:any;
  displayName:any;
  users: any[]=[];
  UploadCertificate: any;
  lifelongvalidity:any;
  validatetill:any;
  issueddate:any;
  State:any;
  Country:any;
  categoryother:any;
  Licensenumber:any;
  LicenseAuthority:any;
  LicenseAuthorityother:any;
  selectedCategory:any;
  selectedCategoryothers:any;
  fileUpd: File | null = null;
  myId: number=1;
  provide: any;
  message: any;
  nameOfInstitute:any;
  typeOfDegree:any;
  fieldOfStudy:any;
  country:any;
  toDt:any;
  fromDt:any;
  n:any;
  errorText: string = '';
  category:any;

  myForm!: NgForm; 
  educationForm!:NgForm;
  errorFile: string = '';
  CountryList:any;
  CountryIds:any;
  SelectedCountryId: any;
  SelectedstatesId: any;
  statesList: any;
  statesId:any;
  errorEducation: any;
  Category_Others: any;
  License_Authorityother:any;
  License_Authority: any;
  License_Number: any;
  Validate_till: any;
  Life_long_till: any;
  Upload_certificate: any;
  Issued_Date: any;
  licenseDetails: string[]=[];      
  License_Authority_others: any;
  nameOfInstituteedu:any;
  typeOfDegreeedu:any;
  fieldOfStudyedu:any;
  countryedu:any;
  fromDtedu:any;
  toDtedu:any;
  licenseDocName: string | undefined;
  licenseDoc: any;
  fileTypeError: boolean=false;
  selectedFileName: any;
  selectedImage: any;
  licenseupload: any;
  errorMessage: any;
  hcpEductaionUpload: any;
  hcpEductaionUploadDoc: any;
  EducationDoc: any;
  hcpEductaionUploadDocName: string | undefined;
  licenseDocument: any;
  postLicID: any;
  PersonalInformation(){
    this.displayName='Personal Information'
  }
  constructor(private routes:Router,private nivishservice:NivishService, public dialogRef: MatDialogRef<DialogPopUpComponent>,
  @Inject(MAT_DIALOG_DATA) public data: { displayName: string; licenseId: number;EducationId:number }){
    this.displayName = data.displayName;
   }

  formData = { 
    fullName: '',
    gender: '', 
    dateOfBirth: '',
    mobileNumber: '',
    email: ''
};

  ngOnInit(){
    this.fetchCountry();
  if (this.displayName === 'License') {
   
  } 
   if (this.displayName === 'Eductaion') {

  }
  if (this.data && this.data.licenseId) {
    this.getlicence(this.data.licenseId);
   if (this.displayName === 'Licenseget') {

  }
}
if (this.data && this.data.EducationId) {
  this.geteducation(this.data.EducationId);
 if (this.displayName === 'Eductaionget') {

  }
 } 
}
provider(){
  this.routes.navigate(['/provider']);
}

onFileSelected(event: any) {
  const file: File = event.target.files[0];

  if (file) {
    this.licenseDoc = file;
    this.licenseupload=file;
    this.hcpEductaionUpload=file;
    this.hcpEductaionUploadDoc=file;
    this.fileTypeError = false;

    this.selectedFileName = file.name;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.selectedImage = e.target.result;
    };
    reader.readAsDataURL(file);
  } else {
    this.fileTypeError = true;
    this.selectedFileName = '';
    this.selectedImage = '/assets/images/profilepicture.png';
  }
}
closeLicense(): void {
  this.dialogRef.close();
}
closeEducation(): void {
  this.dialogRef.close();
}

educationPost(form: NgForm) {
  this.educationForm = form;
  
  if (form.controls['file']) {
    if (form.controls['file'].invalid) {
      this.errorFile = 'Please provide a file for upload';
      form.controls['file'].markAsTouched();
    } else {
      this.errorFile = ''; 
    }
  }

  if (form.invalid) {
    this.errorEducation = 'Please fill in all the required details';
    Object.keys(form.controls).forEach(key => {
      form.controls[key].markAsTouched();
    });
  } else {
    this.errorText = ''; 
  }

  if (form.valid) {
    this.saveEducationPost(form.value);
  }
}
saveEducationPost(educationData: any) {
  const verId = localStorage.getItem('verid');
  const formData = new FormData();
  if (!this.hcpEductaionUpload) {
    this.errorFile = 'Upload certificate is required.';
   
    return; 
  }

  if (this.hcpEductaionUpload) {
    const uploadCertificate = this.hcpEductaionUpload;
    formData.append('Upload_certificate', uploadCertificate, 'certificate.pdf');
  }
  const formattedfromDt = this.formatDate(this.fromDt);
  const formattedtoDt = this.formatDate(this.toDt);

  formData.append('HCPID', verId ?? '');
  formData.append('ProviderID', this.provide || '');
  formData.append('Name_of_institute', this.nameOfInstitute);
  formData.append('Type_of_degree', this.typeOfDegree);
  formData.append('Filed_of_study', this.fieldOfStudy);
  formData.append('Country', this.Country);
  formData.append('from_Date', formattedfromDt);
  formData.append('to_Date', formattedtoDt);

  this.nivishservice.educationUpload(formData).subscribe(
    (data: any) => {
      if (data['Status'] === 200) {
        this.message = 'Education Posted';
        this.dialogRef.close();
      }
    },
    (error: any) => {
      console.error(error);
    }
  );
}

licensePost(form: NgForm) {
  const verId = localStorage.getItem('verid');
  
  this.myForm = form;
  
  if (form.controls['file']) {
    if (form.controls['file'].invalid) {
      this.errorFile = 'Please provide a file for upload';
      form.controls['file'].markAsTouched();
    } else {
      this.errorFile = ''; 
    }
  }

  if (form.invalid) {
    this.errorText = 'Please fill in all the required details';
    Object.keys(form.controls).forEach(key => {
      form.controls[key].markAsTouched();
    });
  } else {
    this.errorText = ''; 
  }

  if (form.valid) {
    this.saveLicensePost(form.value);
  }
}
formatDate(date: string | Date): string {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

saveLicensePost(formData: any) {
  const verId = localStorage.getItem('verid');
  if (!this.licenseupload) {
    this.errorMessage = 'Upload certificate is required.';
   
    return; 
  }
  const formattedDateOfBirth = this.formatDate(this.issueddate);
  const formattedvalidatetill = this.formatDate(this.validatetill);
  const licpo=
  
  {
    HCPID: verId,
    ProviderID: this.provide || '',
    License_Authority: this.LicenseAuthority,
    License_Authority_others: this.LicenseAuthorityother || '',
    License_Number: this.Licensenumber,
    Specialization: this.LicenseSpecialisation,
    Issued_Date: formattedDateOfBirth ,
    Validate_till:this.lifelongvalidity ? '' : (formattedvalidatetill ? formattedvalidatetill.toString() : ''),
    Life_long_till: this.lifelongvalidity ? this.lifelongvalidity.toString() : ''

  }

  this.nivishservice.licenseUpload(licpo).subscribe(
    (data: any) => {
      if (data['Status'] === 200) {
        this.message = 'updated license';
        this.postLicID=data.Result.id;
        console.log(data,"postlicense");
        this.licdocpost();
      }
    },
    (error: any) => {
      console.error(error);
    }
  );
 }

licdocpost(){
  const formData = new FormData();

  if (this.licenseupload) {
      const uploadCertificate = this.licenseupload;
  formData.append('Upload_certificate', uploadCertificate, 'certificate.pdf');
}
 
  this.nivishservice.hcpLIcenseDocumentUpdated(this.postLicID,formData).subscribe(
    (data: any) => {
      if (data['Status'] === 200) {
        this.message = 'Updated license';
        this.dialogRef.close();
      }
    },
    (error) => {
      console.error('Error occurred:', error);
    }
  );
}

  fetchCountry() {
    this.nivishservice.getallcountry().subscribe(response => {
      if (response && response.Result) {
        this.CountryList = response.Result;

      }
    });
  }
  onCountryChange(event: any) {
    this.SelectedCountryId = event;
    this.getStatesForCountry(this.SelectedCountryId);
  }

 getStatesForCountry(selectedCountryId: any) {
  this.nivishservice.getstate(selectedCountryId).subscribe(
    (response: any) => {
      if (response && response.Result) {
        this.statesList = response.Result;
        console.log(this.statesList,"get states")
      }
    },
    (error: any) => {
    }
  );
 }
onstateChange(event:any){
  this.SelectedstatesId=event;

}
getStates(SelectedCountryId: number) {
  this.nivishservice.getstate(SelectedCountryId).subscribe((response: { Result: any[]; }) => {
    if (response && response.Result) {
      this.statesList = response.Result.map(item => item.State);
      this.statesId = response.Result.map(item => item.id);
    }
  });
}

getlicence(licenseId: number) {
  this.nivishservice.gethcplicence(licenseId).subscribe(
    (data: any) => {
      if (data['Status'] === 200) {
        const result = data['Result'][0];
        const provider = result.HcpId ? result.HcpId.Provider : null;
        // this.category = result.Category;
        // this.Category_Others = result.Category_Others;
        this.License_Authority = result.License_Authority;
        this.License_Authority_others = result.License_Authority_others;
        this.License_Number = result.License_Number;
        // const Country=result.Country;
        // this.Country=result.Country;
        this.LicenseSpecialisationUpdate=result.Specialization;
        // this.fetchCountry();
        // this.getStatesForCountry(Country);
        // this.State=result.State;
        this.Issued_Date = result.Issued_Date;
        this.Validate_till = result.Validate_till;
        this.Life_long_till = result.Life_long_till;
        this.licenseDocument = result.Upload_certificate;
        if(this.licenseDocument){
          const filename=new URL(this.licenseDocument).pathname.split('/').pop();
          this.licenseDocName=filename
        }  
        console.log(data);
        
      }
    },
    (error: any) => {
      console.error('Error:', error);
    }
   );
  }

  geteducation(EducationId:number){
    this.nivishservice.eductaiongethcp(EducationId).subscribe(
      (data:any)=>{
        if(data["Status"]===200){
          const Eductaion= data['Result'][0];
          this.nameOfInstituteedu=Eductaion.Name_of_institute,
          this.typeOfDegreeedu=Eductaion.Type_of_degree,
          this.fieldOfStudyedu=Eductaion.Filed_of_study,
          this.countryedu=Eductaion.Country,
          this.fetchCountry();
          this.fromDtedu=Eductaion.from_Date,
          this.toDtedu=Eductaion.to_Date;
          this.EducationDoc = Eductaion.Upload_certificate;
        if(this.EducationDoc){
          const filename=new URL(this.EducationDoc).pathname.split('/').pop();
          this.hcpEductaionUploadDocName=filename
        }
          console.log(this.hcpEductaionUploadDocName,"filename");

        }
      })
  }
  updatehcplicence(licenseId: number) {
    const licId = this.data.licenseId;
    const updHcpLicense ={
      // Category:this.category,
      // Category_Others:this.category === 'Other' ? this.Category_Others || '' : null,
      Specialization:this.LicenseSpecialisationUpdate,
      License_Authority:this.License_Authority,
      License_Authority_others:this.License_Authority === 'Other'?this.License_Authority_others ||  '':null,
      License_Number:this.License_Number,
      // Country:this.Country,
      // State:this.State,
      Issued_Date:this.Issued_Date,
      Validate_till:this.Life_long_till ? '' : this.Validate_till || '',
      Life_long_till: this.Life_long_till ? this.Life_long_till.toString() : '',

    }
    console.log(updHcpLicense,"updated");
    this.nivishservice.updatehcplicence(licId, updHcpLicense).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          console.log(data,"upd lic success");
          this.hcpLicensedocUpdate();
        }
      },
      (error: any) => {
        console.error('Error:', error);
      }
    );
  }
  hcpLicensedocUpdate(){
    const licId = this.data.licenseId;

    if (this.licenseDoc === 'null' || this.licenseDoc === undefined) {
      console.log("Image not provided, using filename:", this.licenseDocName);
    
      if (!this.licenseDocName || this.licenseDocName === '') {
       
      } else {
        this.message = 'Updated licence';
        this.dialogRef.close();
      }
    }else {

    const formData = new FormData();
      if (this.licenseDoc) {
      console.log(this.licenseDoc,"licensedoc");
      formData.append('Upload_certificate', this.licenseDoc);
    }
    this.nivishservice.hcpLIcenseDocumentUpdated(licId, formData).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          this.message = 'Updated licence';
          this.dialogRef.close();
        }
      },
      (error: any) => {
        console.error('Error:', error);
      }
    );
  }
}
  educationUpd(EducationId: number) {
    const eduid = this.data.EducationId;
    const eductaionupdate={
      Name_of_institute:this.nameOfInstituteedu,
      Type_of_degree:this.typeOfDegreeedu,
      Filed_of_study:this.fieldOfStudyedu,
      Country:this.countryedu,
      from_Date:this.fromDtedu,
      to_Date:this.toDtedu

    }
    this.nivishservice.educationhcpUpdate(eduid, eductaionupdate).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          this.educationUpdateDoc();
        }
      },
      (error: any) => {
        console.error('Error:', error);
      }
    );
  }
  educationUpdateDoc(){
    const eduid = this.data.EducationId;
      if (this.hcpEductaionUploadDoc === 'null' || this.hcpEductaionUploadDoc === undefined) {
        console.log("Image not provided, using filename:", this.hcpEductaionUploadDocName);
      
        if (!this.hcpEductaionUploadDocName || this.hcpEductaionUploadDocName === '') {
         
        } else {
          this.message = 'Updated Eductaion';
          this.dialogRef.close();
        }
      }else {
  
        const formData = new FormData();
        if (this.hcpEductaionUploadDoc) {
            formData.append('Upload_certificate', this.hcpEductaionUploadDoc);
          }
      this.nivishservice.hcpEductaionDocumentUpdate(eduid, formData).subscribe(
        (data: any) => {
          if (data['Status'] === 200) {
            this.message = 'Updated edu';
            this.dialogRef.close();
          }
        },
        (error: any) => {
          console.error('Error:', error);
        }
      );
  }
}
getFormattedMaxDate(): string {
  const currentDate = new Date();
  const formattedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1);
  return formattedDate.toISOString().split('T')[0];
} 

 handleRadioChange() {
  if (this.lifelongvalidity) {
    this.issueddate = null;
    this.validatetill = null;
  } 
  else {
    this.issueddate = ''; 
    this.validatetill = ''; 
  }
}

hasDataInDateFields() {
  return  !!this.validatetill;
}

onValidityChange() {
  if (this.lifelongvalidity) {
    this.validatetill = null; 
    
  }
}

onvalidityupdchange(){
  if(this.Life_long_till){
    this.Validate_till = null;
  }
}
getsFormattedMaxDate(): string {
  
  const currentDate = new Date();
  return currentDate.toISOString().split('T')[0];
  
}
getscomingMaxDate(): string {
  if (this.issueddate) {
    return formatDate(this.issueddate, 'yyyy-MM-dd', 'en');
  }
  return '';
}
updateValidTillMinDate() {
  if (!this.validatetill) {
    if (this.issueddate && !this.Life_long_till) {
      const issueDate = new Date(this.issueddate);
      const minDate = new Date(issueDate.setDate(issueDate.getDate() + 1));
      this.validatetill = minDate.toISOString().split('T')[0];
    }
  }
}
updateValidTillofLicenseMinDate(){
  if (this.Issued_Date) {
    const issueDate = new Date(this.Issued_Date);
    const minDate = new Date(issueDate.setDate(issueDate.getDate() + 1));
    this.Validate_till = minDate.toISOString().split('T')[0];
  }
}

getMinDate(): string | undefined {
  return this.issueddate;
}
getMinlicDate():string|undefined{
  return this.Issued_Date;

}
getMaxDate(): string {
  const today = new Date();
  return formatDate(today, 'yyyy-MM-dd', 'en');
}
getMinDateEdu():string|undefined{
  return this.fromDt;

}
getMinUpdEduDt():string|undefined{
  return this.fromDtedu;

  }
}

