import { Component } from '@angular/core';
import { NivishService } from '../nivish.service';

@Component({
  selector: 'app-infoseek-id-card',
  templateUrl: './infoseek-id-card.component.html',
  styleUrls: ['./infoseek-id-card.component.css']
})
export class InfoseekIdCardComponent {
  
  displayName = 'InfoseekId';
  idCardDetails: any; // Property to hold ID card details
  message: any;
  uinValue: any;
  studentDOB: any;
  studentFirstName: any;
  class: any;
  gender: any;
  profile: any;
  qrcode: any;
  reversedDOB: any;

  constructor(private nivishservice: NivishService) {}

  goBack() {
    console.log('Going back...');
  }

  ngOnInit(){
    this.getidcard()
  }
  getidcard() {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.log('userId not found in local storage.');
      return;
    }

    this.nivishservice.getidcard(userId).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          const result = data['Result'];
        
          if (result) {
            this.uinValue = result['UIN'];
            console.log('UIN:', this.uinValue);
            
            this.studentDOB = result['Student_DOB'];
            console.log('Student DOB:', this.studentDOB);
            const dateParts: string[] = this.studentDOB.split('-');

// Create a new date string in 'DD-MM-YYYY' format
this.reversedDOB = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;

console.log('Reversed Student DOB:', this.reversedDOB);
            
            this.studentFirstName = result['Student_FirstName'];
            console.log('Student First Name:', this.studentFirstName);
        
            this.gender = result['Gender'];
            console.log('Gender:', this.gender);
        
            this.class = result['class_name'];
            console.log('Class:', this.class);

            this.profile = result['upload_photo']
            console.log('profilephoto:', this.profile);

            this.qrcode = result ['qrcode_image']
            console.log('qrcode:',this.qrcode)
     

            this.message = 'Get idcard';
          }
        }
        console.log(this.message);
        console.log(data);
      },
      (error) => {
        console.error('Error:', error);
        // Handle the error here (e.g., show an error message to the user)
      }
    );
  }
}