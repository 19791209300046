<div class="main-container">
    <ng-template [ngIf]="displayName==='HealthCamp'">

    <div class="row">
      <div class="col">
          <div class="section">
              <div class="scroll-bar">
                <p class="para">List Of Health Camps</p>

                  <div class="scroll-content">
                      <div class="round">
                          <img src="assets/images/reviewpic.png">
                      </div> 
                      <p>Health Camp A</p><br>
                      <div class="round">
                          <img src="assets/images/reviewpic.png">
                      </div>
                      <p>Health Camp B</p><br>
                      <div class="round">
                          <img src="assets/images/reviewpic.png">
                      </div>
                      <p>Health Camp C</p><br>
                      <div class="round">
                          <img src="assets/images/reviewpic.png">
                      </div>
                      <p>Health Camp D</p><br>
                    </div>
                </div>
            </div>
        </div>
     </div>

     <div class="sec">
        <div class="card-container">
           
            <div class="header-container">
              <div class="healthdown">
                <h1 class="Health">Health Camp</h1>
                <div class="tag" *ngFor="let selectedCheckbox of selectedCheckboxes">
                  {{ selectedCheckbox }}
                  <span class="close" (click)="removeCheckbox(selectedCheckbox)">x</span>
                </div>
              </div>
                <button class="image-button">
                    <img src="assets/images/SearchBar.png"/>
                </button>
                <div class="filter-container-wrapper">
                  
                    <div class="filter-header">
                      <span class="material-icons filter" id="filter" (click)="toggleFilter()">filter_alt</span>
                    </div>
            
                    <div *ngIf="showFilter" class="filter-container">
                      <mat-card>
                        <div class="filter-section dropdown-container">
                          <p>Flag</p>
                          <!-- Using *ngFor to iterate over an array of checkbox values -->
                          <div class="check" *ngFor="let checkbox of ['WNL', 'Non-Urgent', 'Urgent', 'Emergency']">
                            <!-- Checkbox input -->
                            <input type="checkbox" class="larger-checkbox" [value]="checkbox" name="checkbox" (change)="toggleCheckbox(checkbox)" />

                            <!-- Checkbox label -->
                            <span class="text">{{ checkbox }}</span>
                            
                          </div>
                    
                          <!-- Add other checkboxes... -->
                    
                          <!-- Button to apply the filter -->
                          <button type="submit" class="apply-button" (click)="applyFilter()">Apply</button>
                        </div>
                      </mat-card>
                
                      
                      <!-- <mat-card>
            
                      <div class="filter-section dropdown-container">
                        <p>Flag</p>
                        <div class="check">
                          <input type="checkbox" class="larger-checkbox" value="null" name="Caste">
                          <span class="text">WNL</span>
                          <div class="dropdown-container">
                          </div>
                        </div>
            
                        <div class="check">
                        <input type="checkbox" class="larger-checkbox" value="null" name="number"/>
                        <span class="text">Non- Urgent</span>
                        </div>
                        <div class="check">
                        <input type="checkbox" class="larger-checkbox" value="null" name="profession"/>
                        <span class="text">Urgent</span>
                        </div>
                        <div class="check">
                        <input type="checkbox" class="larger-checkbox"  value="null" name="partyInclination_id"/>
                        <span class="text">Emergency</span>
                        </div>
                        <p class="Review">Review Status</p>
                        <div class="check">
                        <input type="checkbox" class="larger-checkbox"  value="null" name="contactmode"/>
                        <span class="text">None</span>
                        </div>
                        <div class="check">
                        <input type="checkbox" class="larger-checkbox"  value="null" name="nonlocal"/>
                        <span class="text">Completed</span>
                        </div>
                        <div class="check">
                            <input type="checkbox" class="larger-checkbox"  value="null" name="nonlocal"/>
                            <span class="text">Hold</span>
                            </div>
                      </div>
                      <button type="submit" class="apply-button" >Apply</button>
                     
                    </mat-card> -->
                  
            
                    </div>
                  </div>
            </div>
            <label class="student">Student List</label>
           
         <!-- ////////////////////////////////////////********* -->
           
                    <!-- <table class="table">
                        <thead>
                            <tr>
                                <th>UIN</th>
                                <th>Student Full Name</th>
                                <th>Class & Section</th>
                                <th>Flag</th>
                                <th>Review Status</th>
                                <th>Reviewed By</th>
                                <th>Reviewed On</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>123456789</td>
                                <td>Devikanjali</td>
                                <td>5Th B</td>
                                <td class="emergency">Emergency</td>
                                <td>On Hold</td>
                                <td>Patrudu</td>
                                <td>8th Oct 2023</td>
                            </tr><br>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>123456789</td>
                                <td>Devikanjali</td>
                                <td>5Th B</td>
                                <td class="Non-Urgent">Non-Urgent</td>
                                <td>On Hold</td>
                                <td>Patrudu</td>
                                <td>8th Oct 2023</td>
                            </tr><br>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>123456789</td>
                                <td>Devikanjali</td>
                                <td>5Th B</td>
                                <td class="Normal">Normal</td>
                                <td>On Hold</td>
                                <td>Patrudu</td>
                                <td>8th Oct 2023</td>
                            </tr><br>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>123456789</td>
                                <td>Devikanjali</td>
                                <td>5Th B</td>
                                <td class="Urgent">Urgent</td>
                                <td>On Hold</td>
                                <td>Patrudu</td>
                                <td>8th Oct 2023</td>
                            </tr><br>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>123456789</td>
                                <td>Devikanjali</td>
                                <td>5Th B</td>
                                <td class="emergency">Emergency</td>
                                <td>On Hold</td>
                                <td>Patrudu</td>
                                <td>8th Oct 2023</td>
                            </tr><br>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>123456789</td>
                                <td>Devikanjali</td>
                                <td>5Th B</td>
                                <td class="emergency">Emergency</td>
                                <td>On Hold</td>
                                <td>Patrudu</td>
                                <td>8th Oct 2023</td>
                            </tr><br>
                        </tbody>

                        
                    </table> -->
                    <!-- ///////////////////////////////////////////////******* -->

                    
                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource">
                      
                          
                          <ng-container matColumnDef="UIN">
                            <th mat-header-cell *matHeaderCellDef>UIN </th>
                            <td mat-cell *matCellDef="let element"> {{element.UIN}} </td>
                          </ng-container>
                      
                          <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef> Student Full Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                          </ng-container>
                      
                          
                          <ng-container matColumnDef="Class">
                            <th mat-header-cell *matHeaderCellDef> Class & Section </th>
                            <td mat-cell *matCellDef="let element"> {{element.Class}} </td>
                          </ng-container>
                      
                          
                          <ng-container matColumnDef="Flag">
                            <th mat-header-cell *matHeaderCellDef> Flag </th>
                            <td mat-cell *matCellDef="let element"> {{element.Flag}} </td>
                          </ng-container>
                          <ng-container matColumnDef="Review">
                            <th mat-header-cell *matHeaderCellDef> Review Status </th>
                            <td mat-cell *matCellDef="let element"> {{element.Review}} </td>
                          </ng-container>
                          <ng-container matColumnDef="Reviewed">
                            <th mat-header-cell *matHeaderCellDef   > Reviewed By </th>
                            <td mat-cell *matCellDef="let element" > {{element.Reviewed}} </td>
                          </ng-container>
                          <ng-container matColumnDef="Reviewedon">
                            <th mat-header-cell *matHeaderCellDef> Reviewed On </th>
                            <td mat-cell *matCellDef="let element"> {{element.Reviewedon}} </td>
                          </ng-container>

                          
                      
                          <tr class="tablerowcolor" mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onRowClicked()"></tr>
                        </table>
                      
                        <mat-paginator showFirstLastButtons aria-label="Select page">
                        </mat-paginator>
                      </div>
                    </div>
            </div>
          </ng-template>

         

  </div>