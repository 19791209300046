import { Component, EventEmitter, Output } from '@angular/core';
import { Router ,NavigationExtras } from '@angular/router';
import { NivishService } from '../nivish.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-provider-list',
  templateUrl: './provider-list.component.html',
  styleUrls: ['./provider-list.component.css'],
  providers: [DatePipe]

})
export class ProviderListComponent {

  @Output() selectedGenderChange = new EventEmitter<string>();
  displayName='List';
  users: any[]=[];
  activeTab: 'HCP' | 'Ops' = 'HCP';
  message: any;
  isLoading: boolean=false;
  displaymessage: any;
  selectedIndex: number=-1;
  
    PersonalInformation(){
    this.displayName='Personal Information'
  }

  constructor(private routes:Router,private nivishservice:NivishService,private datePipe: DatePipe){}

  // displayName='Hcpsection';

  formData = { 
  
  fullName: '',
  gender: '', 
  dateOfBirth: '',
  mobileNumber: '',
  email: ''
};

ngOnInit() {
  this.activeTab = 'HCP'; // Set the default tab to 'HCP'
  this.getProvide(this.activeTab);
}
formatDateString(dateString: string): string {
  const date = new Date(dateString);
  const formattedDate = this.datePipe.transform(date, 'dd-MMM-yyyy');
  return formattedDate ? formattedDate : ''; // Provide a default value if null
}
getProvide(filter: any) {
  this.nivishservice.provideallget(filter).subscribe(
    (data: any) => {
      this.users = data.Result.map((user: any) => {
        user.status = this.getStatus(user.Tag);
        return user;
      });

      console.log(this.users, "sdfghjk");
    },
    (error: any) => {
      console.error('Error fetching user data:', error);
    }
  );
}
getStatusFontClass(tag: any): string {
  if (tag === null) {
    return 'New';
  } else if (tag === false) {
    return 'Partial';
  } else if (tag === true) {
    return 'Completed';
  } else {
    return 'Unknown';
  }
}
getStatus(tag: any): string {
  if (tag === null) {
    return 'New';
  } else if (tag === false) {
    return 'Partial';
  } else if (tag === true) {
    return 'Completed';
  } else {
    return 'Unknown';
  }
}

personl(){
  this.routes.navigate(['/provider'])
}
goToAnotherComponent() {
  const displayName = 'presonalPost';
  const navigationExtras: NavigationExtras = {
    state: {
      displayName: displayName
    }
  };
  this.routes.navigate(['/provider'],navigationExtras); 
}


person(user: any,index: number) {
  this.displaymessage='';
  console.log('Clicked user data:', user);
  const listId = user.id;
  console.log('User ID:', listId);
  localStorage.setItem('userId', listId.toString());
  this.selectedIndex = index;
  this.isLoading = true;
  this.providertaghcpupd(user)
 
    // Navigation will only happen if providertaghcpupd is successful
  

}
reloadPage(): void {
  // Use the location service to trigger a page reload
 
}
postProviderlist(user:any){
  const providerId = localStorage.getItem('providerId');

  // if (
  //   user &&
  //   user.FullName &&
  //   user.Date_of_Birth &&
  //   user.Registered_Email &&
  //   user.Registered_MobileNumber &&
  //   user.Type
  // ) {
    // Construct the JSON object
    const postData = {
      ProviderID: providerId, // You may need to set the actual ProviderID value
      // FullName: user.FullName,
      First_Name:user.First_Name,
      Middle_Name:user.Middle_Name,
      Last_Name:user.Last_Name,
      Category:user.Category,
      Date_of_Birth: user.Date_of_Birth,
      Registered_Email: user.Email,
      Gender:user.Gender,
      Registered_MobileNumber: user.MobileNumber,
      Type: user.Type
    };

    // Log the constructed JSON object
    console.log('Constructed JSON for post:', postData);

console.log(postData,"raja")
this.nivishservice.personamaster(postData).subscribe(
  (data:any)=>{
    if(data['Status']===200){
      this.message='success post';
      const updRegId = data.Result.HCPID
      console.log(updRegId,'updId');
      localStorage.setItem('updRegId', updRegId.toString());

      console.log("list posted",this.message);
      console.log('posted data',data);
      this.routes.navigate(['/provider'], { queryParams: { id: user.id } });
      this.isLoading=false;

      // this.providertaghcpupd(user);
    }
  },(error) => {
    console.error('Error occurred:', error);
        this.isLoading = false; 
        if (error instanceof HttpErrorResponse) {
          this.displaymessage = error.error.Message || 'An error occurred'; 
        } else {
          this.displaymessage = 'An error occurred'; 
        }
  }
)
}
providertaghcpupd(user:any){
  const listId = user.id;
  const tagUpd={
    First_Name:user.First_Name,
    Middle_Name:user.Middle_Name,
    Last_Name:user.Last_Name,
    Category:user.Category,
    Date_of_Birth: user.Date_of_Birth,
    Gender:user.Gender,
    MobileNumber:user.MobileNumber,
    Email:user.Email,
    Type:user.Type,
    // Tag:false,
  }
  this.nivishservice.hcpmasterUpdate(listId,tagUpd).subscribe(
    (data:any)=>{
      if(data["Status"]===200){
        this.message='hcpupdated';
        this.postProviderlist(user);
      }
      console.log(data,"hcpdata");

    },
    (error) => {
      console.error('Error occurred:', error);
      this.isLoading = false; 
      if (error instanceof HttpErrorResponse) {
        this.displaymessage = error.error.Message || 'An error occurred'; 
      } else {
        this.displaymessage = 'An error occurred'; 
      }
        })
}

otp(){
  this.displayName='showTerms';
}
updateTab(tab: 'HCP' | 'Ops') {
  this.activeTab = tab;
  this.getProvide(tab);
  
}


}
