import { Component ,ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NivishService } from '../nivish.service';


@Component({
  selector: 'app-hcp-login',
  templateUrl: './hcp-login.component.html',
  styleUrls: ['./hcp-login.component.css']
})
export class HcpLoginComponent {
  
    displayName='hcplogin';
    formData:any={
      FullName: '',
      DateofBirth:'' ,
      RegisterEmail: '',
      RegisterMobile: '',
    } 
    
    FullName:any;
    Gender:any;
    DateofBirth:any;
    PhoneNumber:any;
    Email:any;

    selectorStyles: { [key: string]: string } = {};
    dateOfBirth: string | undefined;
    otpExpired: boolean = false;
    showResendError: boolean = false;
    invalidDetailsMessages:any;
    version:string='2.0';
 


  activeTab: string = 'hcp';
    formSubmitted = false;
    showModal: boolean = false;

    otpValue: string[] = ['', '', '', ''];
    invalidDetailsMessage: any;
    successmsg: any;
    showTerms: boolean=false;
    otpSentMessage:any;
    verify: any;
    verId: any;
    datePipe: any;

    constructor(private routes:Router,private nivishservice:NivishService){
    
  }
  ngOnInit(){
    sessionStorage.removeItem("hcpForm");
    this.nivishservice.setLoggedIn(false);
  }
  // placeholderVisibilityChange(event: any) {
  //   this.placeholderVisible = event.detail;
  // }

  // ---------------------------for dob label-----------------------------------------
  // onFocus() {
  //   const placeholderLabel = document.querySelector('.placeholder-label');
  //   if (placeholderLabel) {
  //     placeholderLabel.classList.add('hidden');
  //   }
  // }

  // onBlur() {
  //   const placeholderLabel = document.querySelector('.placeholder-label');
  //   if (placeholderLabel && !this.formData.DateofBirth) {
  //     placeholderLabel.classList.remove('hidden');
  //   }
  // }
  // ----------------------------------------------------------


  onOtpInputChange(index: number, nextInput: HTMLInputElement | null, prevInput: HTMLInputElement | null): void {
    this.otpValue[index] = this.otpValue[index].replace(/[^0-9]/g, '');
  
    if (this.otpValue[index] === '' && prevInput) {
      prevInput.focus();
    } else if (this.otpValue[index] !== '' && nextInput) {
      nextInput.focus();
    }
  }
  onKeyDown(event: KeyboardEvent, prevInput: HTMLInputElement | null, nextInput: HTMLInputElement | null): void {
    if (event.key === 'ArrowLeft' && prevInput) {
      prevInput.focus();
    } else if (event.key === 'ArrowRight' && nextInput) {
      nextInput.focus();
    }
  }
  resetOtpValues() {
    this.otpValue = ['', '', '', ''];
  }
    resendCode() {
      const formattedDateOfBirth = this.formatDate(this.formData.DateofBirth);

      this.formSubmitted = true; 
    // Check if any of the required fields are empty
    if (
      !this.formData.FullName ||
      !formattedDateOfBirth ||
      !this.formData.RegisterEmail ||
      !this.formData.RegisterMobile
    ) {
      this.successmsg = '';
      this.invalidDetailsMessage = 'Please fill in all details.';
      return; // Stop further execution
    }
  
    const emailId = this.formData.RegisterEmail;
    const person = {
      Name: this.formData.FullName,
      Date_of_Birth: formattedDateOfBirth,
      Email: this.formData.RegisterEmail,
      MobileNumber: this.formData.RegisterMobile,
    };
    // console.log(person);
    localStorage.setItem('person', JSON.stringify(person));
  
    this.nivishservice.hcpVerify(person).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          this.successmsg = 'The code was resent to';
          this.invalidDetailsMessage = '';
          
         
        } else if (data['Status'] === 400) {
          this.successmsg = '';
          this.invalidDetailsMessage = data['Message'];
        } else {
          this.successmsg = '';
          this.invalidDetailsMessage = 'An error occurred';
        }
        // console.log('Data posted successfully:', data);
      },
      (error: any) => {
        this.successmsg = '';
        this.invalidDetailsMessage = '';
        // console.log('Error posting data:', error);
      }
    );
      // console.log('Resending OTP code...');
    }
    hcplogin() {
      this.displayName='hcplogin';
      location.reload();
    }

    termshcp(){
      this.displayName='termshcp';
    }

  

  updateTab(tab: string) {
    this.activeTab = tab;
  }
  otp(){
    this.displayName='otp_1';
  }
  closeTerms(){
    this.showTerms=false;
  }
  formatDate(date: string | Date): string {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
  
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
  
    return [year, month, day].join('-');
  }
  allowOnlyNumbers(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
  
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  onVerify() {
    this.formSubmitted = true;
    const formattedDateOfBirth = this.formatDate(this.formData.DateofBirth);

    
    if (
      !this.formData.FullName ||
      !formattedDateOfBirth||
      !this.formData.RegisterEmail ||
      !this.formData.RegisterMobile
    ) {
      this.invalidDetailsMessage = 'Please fill in all details.';
      this.successmsg = ''; 
      return; 
    }

    const emailId = this.formData.RegisterEmail;
    const person = {
      Name: this.formData.FullName,
      Date_of_Birth: formattedDateOfBirth,
      Email: this.formData.RegisterEmail,
      MobileNumber: this.formData.RegisterMobile,
    };

    localStorage.setItem('person', JSON.stringify(person));

    this.nivishservice.hcpVerify(person).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          this.successmsg = 'Verification successful';
          this.invalidDetailsMessage = ''; 
          this.displayName = 'otp_1';
          sessionStorage.setItem('lastVisitedUrl', window.location.href);
          sessionStorage.setItem('hcpForm', JSON.stringify(person));
        } else if (data['Status'] === 400 && data['Message'] === 'Invalid details') {
          this.successmsg = '';
          this.invalidDetailsMessage = 'Invalid details. Please check and try again.';
        } else {
          this.successmsg = '';
          this.invalidDetailsMessage = 'An error occurred';
        }
      },
      (error: any) => {
        this.successmsg = '';
        this.invalidDetailsMessage = 'Invalid Details';
      }
    );
  }

  

  

  
  
  hcpOtp() {
    const formattedDateOfBirth = this.formatDate(this.formData.DateofBirth);

    const oneTime = this.otpValue.join('');
    const verify = {
      Name: this.formData.FullName,
      Date_of_Birth: formattedDateOfBirth,
      Email: this.formData.RegisterEmail,
      MobileNumber: this.formData.RegisterMobile,
      Otp: oneTime,
      Review_Doctor:false,
    };
    console.log("otp", verify);
    if (verify.Email) {
      this.nivishservice.hcponeTime(verify).subscribe(
        (data: any) => {
          console.log(data,'any')
          if (data['Status'] === 200 && data.Result ) {
            const verId = data.Result.HCPID;
            if (verId !== undefined) {
              console.log(verId, 'hcppppppppp');
          
          
              localStorage.setItem('verid', verId.toString());
              this.verId=verId;
              this.displayName='termshcp' 
              console.log(data, 'hcpdata');
            } else {
              console.log('Full Response:', data);            }
          } else {
            console.error('Invalid response structure:', data);
          }
        },
        (error) => {
          this.successmsg = '';
          this.invalidDetailsMessage = 'Please enter a valid OTP';
          this.invalidDetailsMessage = 'Invalid or Expired OTP';
          console.log(this.invalidDetailsMessage);
  
         

          console.error('Error posting data:', error);
        }
      );
    }
  }
          
   
  termsconditions() {
    try {
      const verId = this.verId;
      console.log(verId,'sdfghjkl')
      if (!verId) {
        console.error('User ID is missing.');
        return;
      }
    
      const currentDate = new Date().toISOString().split('T')[0];
    
      const termshcp = {
        Terms_and_conditions: true,
        Version: this.version || '',
        Date: currentDate,
        
      };
    
      // console.log('Updating terms:', terms);
    console.log(termshcp,'sdfghj')
      this.nivishservice.hcpterms(verId, termshcp).subscribe(
        (data: any) => {
          if (data && data.Status === 200) {
            this.displayName = 'termsupdate';
            this.nivishservice.setLoggedIn(true);
            this.routes.navigate(['/hcpsections']);
          } else {
            console.error('Failed to update terms. Unexpected response:', data);
          }
        },
        (error: any) => {
          console.error('Error updating terms:', error);
        }
      );
    } catch (error) {
      console.error('Error in termsconditions():', error);
    }
  }
  
  
  
  onResendClick() {
    const formattedDateOfBirth = this.formatDate(this.formData.DateofBirth);

    this.otpExpired = false;
    this.showResendError = true;
    this.invalidDetailsMessages = 'OTP sent to your email';
    this.invalidDetailsMessage= false;
    
    this.resendButtonDisabled = true;
    this.resetOtpValues();
    this.startCountdown();
    this.formSubmitted = true; 
    
    if (
      !this.formData.FullName ||
      !formattedDateOfBirth||
      !this.formData.RegisterEmail ||
      !this.formData.RegisterMobile
    ) {
      this.successmsg = '';
      this.invalidDetailsMessage = 'Please fill in all details.';
      return; 
    }
  
    const emailId = this.formData.RegisterEmail;
    
    const person = {
      Name: this.formData.FullName,
      Date_of_Birth: formattedDateOfBirth,
      Email: this.formData.RegisterEmail,
      MobileNumber: this.formData.RegisterMobile,
    };
    console.log(person);
    localStorage.setItem('person', JSON.stringify(person));
  
    this.nivishservice.hcpVerify(person).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          this.successmsg = 'The code was resent to';
          this.invalidDetailsMessage = '';
         
        } else if (data['Status'] === 400) {
          this.successmsg = '';
          this.invalidDetailsMessage = data['Message'];
        } else {
          this.successmsg = '';
          this.invalidDetailsMessage = 'An error occurred';
        }
        console.log('Data posted successfully:', data);
      },
      (error: any) => {
        this.successmsg = '';
        this.invalidDetailsMessage = '';
        console.log('Error posting data:', error);
      }
    );
      console.log('Resending OTP code...');
    setTimeout(() => {
      this.invalidDetailsMessages = '';
    }, 4000);
  }

  startCountdown() {
    this.countdownSeconds = 10;

    clearInterval(this.countdownInterval);

    this.countdownInterval = setInterval(() => {
      if (this.countdownSeconds > 0) {
        this.countdownSeconds--;
      } else {
        this.resendButtonDisabled = false;
        clearInterval(this.countdownInterval);
      }
    }, 1000); 
  }

  closeErrorMessage() {
    this.showResendError = false;
  }

  resendButtonDisabled = false;
  countdownSeconds: number = 0;
  countdownInterval: any;

  formatCountdownTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    return `${formattedMinutes}:${formattedSeconds}`;
  }
  


  // formatDate(date: string): string {
  //   // Check if the date is valid before formatting
  //   if (date) {
  //     const formattedDate = this.datePipe.transform(date, 'dd/MM/yyyy');
  //     return formattedDate || date; // Return the formatted date or the original date if formatting fails
  //   }
  //   return '';
  // }
  
}
function userId(userId: any, terms: { Terms_and_conditions: boolean; Version: number; Date: string; }) {
  throw new Error('Function not implemented.');
}