import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {NivishService} from '../nivish.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-hcpprovider',
  templateUrl: './hcpprovider.component.html',
  styleUrls: ['./hcpprovider.component.css']
})
export class HcpproviderComponent {
  displayName='hcpprovider';
  formData:any={
 

  RegisterEmail:'',}
  verificationCode='';
  otpValue: string[] = ['', '', '', ''];
  invalidDetailsMessage: any;
  successmsg: any;
  result: any;
  oneTime:any;
  userverification: any;
  otpSentMessage:any;
  otpExpired: boolean = false;
  showResendError: boolean = false;
  invalidDetailsMessages:any;

  // activeTab: any;
  activeTab: string = 'hcp'; // Initialize it with the default tab value

  showTerms: boolean = false;
 version:string='2.0';
  providerId: any;
  verificationData: any;

  constructor( private route: ActivatedRoute, private routes:Router,private nivishservice:NivishService ){
    // console.log('Component initialized');
  }

  ngOnInit() {
    sessionStorage.removeItem("formData");
    this.nivishservice.setLoggedIn(false);
    this.route.url.subscribe(url => {
      if (url[0].path === 'hcplogin') {
        this.activeTab = 'hcp';
      } else if (url[0].path === 'hcpproviderlogin') {
        this.activeTab = 'provider';
      }
    });
  }
  



  onSubmit() {
    const RegisterEmail = this.formData.RegisterEmail;
    const provider = {
        
   
      // 
      Email: this.formData.RegisterEmail,
      };
      
    
      localStorage.setItem('provider', JSON.stringify(provider));
    // console.log(provider)
    this.nivishservice.provideremail(provider).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          this.successmsg = 'Verification successful';
          this.invalidDetailsMessage = ''; 
          this.displayName='otp_1';
          sessionStorage.setItem('lastVisitedUrl', window.location.href);
          sessionStorage.setItem('formData', JSON.stringify(provider));

        } else if (data['Status'] === 400) {
          this.successmsg = ''; 
          this.invalidDetailsMessage = data['Message']; 
        } else {
          this.successmsg = ''; 
          this.invalidDetailsMessage = 'An error occurred'; 
        }
        // console.log('Data posted successfully:', data);
      },
      (error) => {
        this.successmsg = '';
        this.invalidDetailsMessage = 'Please enter a valid email address'; 
        // console.log('Error posting data:', error);
      }
    );
  
    // console.log('Form submitted with data:');
  }



  closeTerms(){
    this.showTerms=false;
  }

  
  otp(){
    this.displayName='otp_1';
  }
  terms(){
    this.displayName='terms';
  }
  
  allowOnlyNumbers(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
  
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  updateTab(tab: string) {
    this.activeTab = tab;
  }
  onOtpInputChange(index: number, nextInput: HTMLInputElement | null, prevInput: HTMLInputElement | null): void {
    this.otpValue[index] = this.otpValue[index].replace(/[^0-9]/g, '');
  
    if (this.otpValue[index] === '' && prevInput) {
      prevInput.focus();
    } else if (this.otpValue[index] !== '' && nextInput) {
      nextInput.focus();
    }
  }
  onKeyDown(event: KeyboardEvent, prevInput: HTMLInputElement | null, nextInput: HTMLInputElement | null): void {
    if (event.key === 'ArrowLeft' && prevInput) {
      prevInput.focus();
    } else if (event.key === 'ArrowRight' && nextInput) {
      nextInput.focus();
    }
  }
  accept(){
    this.routes.navigate(['/register']);
  }
  hcpOtp() {
    const oneTime = this.otpValue.join('');
    const RegisterEmail = this.formData.RegisterEmail;
    const verificationData = {
      Email: RegisterEmail,
      Otp: oneTime,
    };
  
    // console.log(verificationData);
  
    if (verificationData.Email) {
      this.nivishservice.provideotp(verificationData).subscribe(
        (data: any) => {
          console.log(data,"proOtp");
          if (data['Status'] === 200) {
            this.successmsg = 'Verification successful';
            this.invalidDetailsMessage = '';
            const providerId = data.Result.ProviderID;
            console.log('pavan', providerId);
            localStorage.setItem('providerId', providerId.toString());
            this.providerId=data.Result.ProviderID;
            this.displayName='termshcp'
          } else if (data['Status'] === 400) {
            this.successmsg = '';
            this.invalidDetailsMessage = data['Message'];
  
            // Hide the error message after 5 seconds (5000 milliseconds)
            setTimeout(() => {
              this.invalidDetailsMessage = '';
            }, 500);
          }
          // console.log('not success', data);
        },
        (error) => {
          this.successmsg = '';
          this.invalidDetailsMessage = 'Invalid or Expired OTP';
  
          // Hide the error message after 5 seconds (5000 milliseconds)
          
  
          console.error('Error posting data:', error);
        }
      );
    } else {
      console.error('EmailId is missing.');
    }
  }


  termsconditions() {
    try {
      const providerId = this.providerId;
      console.log(providerId,'sdfghjkl')
      if (!providerId) {
        console.error('User ID is missing.');
        return;
      }
    
      const currentDate = new Date().toISOString().split('T')[0];
    
      const termshcp = {
        Terms_and_conditions: true,
        Version: this.version || '', // Ensure default value if this.version is null/undefined
        Date: currentDate,
        
      };
    
      // console.log('Updating terms:', terms);
    console.log(termshcp,'sdfghj')
      this.nivishservice.providerterms(providerId, termshcp).subscribe(
        (data: any) => {
          if (data && data.Status === 200) {
            this.displayName = 'termsupdate';
            this.nivishservice.setLoggedIn(true);
            this.routes.navigate(['/providerlist']);

          } else {
            console.error('Failed to update terms. Unexpected response:', data);
          }
        },
        (error: any) => {
          console.error('Error updating terms:', error);
        }
      );
    } catch (error) {
      console.error('Error in termsconditions():', error);
    }
  }
  
  
  // formatCountdownTime(seconds: number): string {
  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = seconds % 60;
  //   const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  //   const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
  //   return `${formattedMinutes}:${formattedSeconds}`;
  // }

  onResendClick() {
    this.otpExpired = false;
    this.showResendError = true;
    this.invalidDetailsMessages = 'OTP sent to your email';
    this.invalidDetailsMessage= false;

    // Disable the Resend button
    this.resendButtonDisabled = true;
    

    this.startCountdown();
    const RegisterEmail = this.formData.RegisterEmail;
    const provider = {
        
   
    
      Email: this.formData.RegisterEmail,
      };
      
    
      localStorage.setItem('provider', JSON.stringify(provider));
    // console.log(provider)
    this.nivishservice.provideremail(provider).subscribe(
      (data: any) => {
        if (data['Status'] === 200) {
          this.successmsg = 'Verification successful';
          this.invalidDetailsMessage = ''; 
          this.showTerms=true;

        } else if (data['Status'] === 400) {
          this.successmsg = ''; 
          this.invalidDetailsMessage = data['Message']; 
        } else {
          this.successmsg = ''; 
          this.invalidDetailsMessage = 'An error occurred'; 
        }
        // console.log('Data posted successfully:', data);
      },
      (error) => {
        this.successmsg = '';
        this.invalidDetailsMessage = 'Please enter a valid email address'; 
        // console.log('Error posting data:', error);
      }
    );
  
    // console.log('Form submitted with data:');
    
    setTimeout(() => {
      this.invalidDetailsMessages = '';
    }, 4000);
  }

  startCountdown() {
    this.countdownSeconds = 10;

    clearInterval(this.countdownInterval);

    this.countdownInterval = setInterval(() => {
      if (this.countdownSeconds > 0) {
        this.countdownSeconds--;
      } else {
        this.resendButtonDisabled = false;
        clearInterval(this.countdownInterval);
      }
    }, 1000); 
  }

  closeErrorMessage() {
    this.showResendError = false;
  }

  resendButtonDisabled = false;
  countdownSeconds: number = 0;
  countdownInterval: any;
  hcpprovider(){
    this.displayName='hcpprovider';
    location.reload();
  }


  
}
