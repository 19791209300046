<div class="main-container">
  <div class="section" style="display: flex;" >
    <div class="scroll-bar">
      <div class="scroll-content">
          <div class="persona">
              <div class="circle"[ngClass]="{'highlighted':sectionCompletion.personal}">
          <img src="assets/images/personalicon.png" style="margin-left: 10px;" ></div></div>
          <p (click)="presonal()" [ngClass]="{'underline':sectionCompletion.personal}" style="width:100%;cursor: pointer;">Personal Information</p><br>
          <div class="persona">
            <div class="circle"[ngClass]="{'highlighted': sectionCompletion.identification}">
        <img src="assets/images/lice.png" style="margin-left: 10px;" ></div></div>
        <p (click)="identification ()" [ngClass]="{'underline': sectionCompletion.identification}" style="width:100%;cursor: pointer;">Identification Details</p><br>    
          <div class="licens">
            <div class="circle"[ngClass]="{'highlighted':sectionCompletion.License}">
        <img src="assets/images/licenseicon.png" style="margin-left: 10px;" ></div></div>
        <p (click)="License()" [ngClass]="{'underline':sectionCompletion.License}" style="width:100%;cursor: pointer;">License Details</p><br>  
          <div class="educat">
              <div class="circle" [ngClass]="{'highlighted':sectionCompletion.Education}">
          <img src="assets/images/educationicon.png" style="margin-left: 10px;" ></div></div>
          <p (click)="sidebarEducation()" [ngClass]="{'underline': sectionCompletion.Education}" style="cursor: pointer;">Education</p><br>
          <div class="notes">
            <div class="circle" [ngClass]="{'highlighted': sectionCompletion.Note}">
              <img src="assets/images/consent.png" style="margin-left: 10px;">
            </div>
          </div>
          <p (click)="Note()" [ngClass]="{'underline':  sectionCompletion.Note}" style="cursor: pointer;">Consent</p>
        </div>
      </div>
       
      <ng-template [ngIf]="displayName==='Education'">
        <div class="sec">
        <div class="card-container">
          <div style="display: flex;justify-content: space-between;">
              <h1  class="ba" >Education</h1>
                <div class="profile" *ngIf="studentPhoto">
                  <label for="myFileInput">
                    <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo"  class="imageicon">
                    <div *ngIf="!studentPhotoImageUrl" class="box-text">
                      <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                    </div>
                  </label>
                </div>
                </div>
                <ng-container *ngIf="addEducationmessage !== ''" >
                  <div style="color: red; margin-top: 10px;margin-left: 6%;">{{addEducationmessage }}</div>
                </ng-container>
                <button type="button" class="btn btn-primary"   style="margin-top: 50px;margin-left: 40px; color: #8F1402;background: none;border: none;" (click)="addEducation()" *ngIf="!dataloading" >+ Add Education</button>
              <div class="Education" *ngIf="dataloading">
                <div *ngFor="let Education of EducationData?.Result" >
                  <div class="card">
                    <div>
                      <button type="button" class="btn-close" ></button>
                      <div class="cardhead">{{ Education.Name_of_institute.toUpperCase() }}</div>
                      <div class="cardlicense" style="font-size: 18px;font-weight: 500;">Type of Degree&nbsp;-&nbsp;{{ Education.Type_of_degree }}</div>
                      <div class="cardlicense">{{Education.Country}}</div>
                      <div class="cardlicense">{{ dateConvert(Education.from_Date) }}&nbsp;-&nbsp;{{ dateConvert(Education.to_Date) }}</div>
                      <div class="cardlicense">
                        <a [href]="Education.Upload_certificate" target="_blank" title="Click to view certificate">
                          <ng-container *ngIf="isPDF(Education.Upload_certificate); else imageTemplate">
                            <img src="assets/images/pdf.png" alt="PDF Icon" class="imageborder">
                          </ng-container>
                          <ng-template #imageTemplate>
                            <img [src]="Education.Upload_certificate" alt="Certificate Image" class="imageborder">
                          </ng-template>
                          <!-- {{ displayPartialUrl(Education.Upload_certificate) }} -->
                        </a>
                      </div>               
                      <div class="iconUpd" ><i (click)="getEdu(Education.id)" class="fas fa-edit" ></i></div>
                    </div>
                  </div>
                </div>
                <div>
                  <button  type="button" class="btn btn-primary" style="margin-top: 20px;margin-left: 40px; color: #8F1402;background: none;border: none;" (click)="addEducation()"> + Add More</button>
                </div>
              </div>
           </div>
         <div class="row" style="text-align: end;">
          <span class="col">
            <button type="button" class="btn btn-secondary custom-button btn-previous" (click)="License()">Previous</button>
          </span>
          <span class="col">
            <button type="submit" class="btn btn-primary custom-button btn-next" (click)='Note()'>Next</button>
          </span>
        </div>
      </div>
    </ng-template>

      <ng-template [ngIf]="displayName==='License'">
        <div class="sec">
         <form #licenseExp="ngForm" (ngSubmit)="licenseExpPost(licenseExp)">
        <div class="card-container">
          <div style="display: flex;justify-content: space-between;">
            <h1  class="ba"  >License Details</h1>
            <div class="profile" *ngIf="studentPhoto">
              <label for="myFileInput">
                <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo"  class="imageicon">
                <div *ngIf="!studentPhotoImageUrl" class="box-text">
                  <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                </div>
              </label>
            </div>
            </div>
            <div>
              <label class="sub" style="margin-left:50px;" >Total Experience </label>
              <div class="row" style="margin-left: 40px;" >
                <div class="col" style="margin-right: -330px;">
                  <label for="years" class="sub">Years </label><br>
                  <input type="text" id="Years" name="Years" placeholder="type here....." style="max-width: 120px;" class="rounded-input" [(ngModel)]="Years" required>
                </div>
                <div class="col">
                  <label for="Months" class="sub" >Months </label><br>
                  <input type="text" id="Months" name="Months" placeholder="type here....." style="max-width: 120px;margin-bottom: 20px;" class="rounded-input" [(ngModel)]="Months" required>
                </div>
              </div>
              <div class="row" style="margin-left: 40px;font-weight: 500;" >
                <div class="col" style="margin-right: -330px;">
                  <label for="Sub-Contracted" style="font-size: small;">Sub-Contracted From </label><br>
                  <input type="text" id="Sub-Contracted" name="sbForm" placeholder="type here....." style="max-width: 120px; margin-bottom: 20px;" class="rounded-input" [(ngModel)]="sbForm" required>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ng-container *ngIf="errorLicense !== ''|| addLicnesemessage !== ''" >
                    <div style="color: red; margin-top: 10px;margin-left: 9%;">{{ errorLicense || addLicnesemessage }}</div>
                  </ng-container>
                </div>
              </div>
              <div>
                <button type="button" class="btn btn-primary"   style="margin-top: 50px;margin-left: 40px; color: #8F1402;background: none;border: none; " (click)="addLicense()"  *ngIf="!dataLoaded" >+ Add License</button>
              </div>
              <label class="sub" style="margin-left:50px;">License Details</label>
              <div class="license" *ngIf="dataLoaded">
              <div *ngFor="let license of licenseData?.Result" >
                <div class="card">
                  <div>
                    <button type="button" class="btn-close" ></button>
                    <div class="cardhead">
                      {{ license.Specialization }}
                    </div>
                    <div class="cardhead">
                      {{ license.License_Authority }}{{ license.License_Authority_others ? ' - ' + license.License_Authority_others : '' }}
                    </div>
                    <div class="cardnum">{{ license.License_Number }}</div>

                    <div class="cardlicense">{{ license.State }}<span *ngIf="license.State && license.Country">, </span>{{ license.Country }}</div>
                  
                    <div class="cardlicense">
                      {{ dateConvert(license.Issued_Date) }}
                      &nbsp;-&nbsp;
                      <span *ngIf="license.Life_long_till; else validateTill">
                        Life Long
                      </span>
                      <ng-template #validateTill>
                        {{ dateConvert(license.Validate_till) }}
                      </ng-template>
                    </div>
                    <div class="cardlicense">
                      <a [href]="license.Upload_certificate" target="_blank" title="Click to view certificate">
                        <ng-container *ngIf="isPDF(license.Upload_certificatee); else imageTemplate">
                          <img src="../assets/images/pdf.png" alt="PDF Icon" class="imageborder">
                        </ng-container>
                        <ng-template #imageTemplate>
                          <img src="../assets/images/pdf.png" alt="Certificate Image" class="imageborder">
                        </ng-template>
                        <!-- {{ displayPartialUrl(Education.Upload_certificate) }} -->
                      </a>
                    </div> 
                    <div class="iconUpd"><i (click)="getLicense(license.id)" class="fas fa-edit" ></i></div>
                  </div>
                </div>
              </div>
              <div>
                <a  class="btn btn-primary"  style="margin-top: 20px;margin-left: 40px; color: #8F1402;background: none;border: none; " (click)="addLicense()" >+ Add More</a>
              </div>
            </div>
           </div>
          <div class="a">
         </div>
        </div>
        <div class="row" style="text-align: end;">
          <span class="col">
              <button type="button" class="btn btn-secondary custom-button btn-previous" (click)="identification()">Previous</button>
          </span>
          <span class="col">
              <button type="submit" class="btn btn-primary custom-button btn-next">Next</button>
          </span>
      </div>
    </form>
   </div>
  </ng-template>

      <ng-template [ngIf]="displayName==='Identification'">
        <div class="sub">
          <div class="container">
            <form (ngSubmit)="identificationUpdate(identificationForm)" #identificationForm="ngForm">
              <div class="card-container" >
                <div style="display: flex;justify-content: space-between;">
                  <h1 class="ba" >Identification Details</h1>
                  <div class="profile" *ngIf="studentPhoto">
                    <label>
                      <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo"  class="imageicon">
                      <div *ngIf="!studentPhotoImageUrl" class="box-text">
                        <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                      </div>
                    </label>
                  </div>
                </div>
                <div class="information">
                <ng-container *ngIf="errorTextIdentification !== '' ">
                  <div style="color: red; margin-top: 10px;margin-left: 100px;">{{ errorTextIdentification  }}</div>
                </ng-container>
                <div  class="field">
                  <br>
                  <label for="Nationality">Nationality </label><br>
                  <select id="Nationality" name="hcpPersonnationality" [(ngModel)]="hcpPersonnationality" (ngModelChange)="onCountryChange($event)" style="margin-bottom:10px;margin-top:10px" class="rounded-input" required>
                    <option [value]="country" *ngFor="let country of CountryList; let i = index">{{ country }}</option>
                      <!-- Add more options as needed -->
                  </select>
                  <label for="EmiratesID">Emirates ID </label><br>
                  <input type="text"  maxlength="15" id="EmiratesID" name="hcpPersonemiratesid" [(ngModel)]="hcpPersonemiratesid" placeholder="Type here"class="rounded-input"   (keypress)="allowOnlyNumbers($event)" style="margin-bottom:10px;margin-top:10px" required><br>
                  <label for="Passport">Passport # </label><br>
                  <input type="text"  maxlength="9" id="Passport" name="hcpPersonPassport" [(ngModel)]="hcpPersonPassport" class="rounded-input" placeholder="Type here" style="margin-bottom:10px;margin-top:10px"  required>
                </div>
              </div>
              </div>
              <div class="row" style="text-align: end;">
                <span class="col">
                  <button type="button" class="btn btn-secondary custom-button btn-previous" (click)="presonal()">Previous</button>
                </span>
                <span class="col">
                  <button type="submit" class="btn btn-primary custom-button btn-next" >Next</button>
                </span>
              </div>
            </form>
          </div>
        </div>
      </ng-template>

      <ng-template [ngIf]="displayName==='personal'">
        <div class="sec">
          <form (ngSubmit)="personalupdates(personalForm)" #personalForm="ngForm">
          <div class="card-container" style="margin-left: 27%;" >
          <div style="display: flex;justify-content: space-between;">
              <h1 class="ba" >Personal Information</h1>
              <div class="profile" *ngIf="studentPhoto">
                <label >
                  <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo"  class="imageicon">
                  <div *ngIf="!studentPhotoImageUrl" class="box-text">
                    <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                  </div>
                </label>
              </div>
            </div>
              <ng-container *ngIf="errorText !== '' || hcpErrorPic">
                <div style="color: red; margin-top: 10px;margin-left: 100px;">{{ errorText || hcpErrorPic }}</div>
              </ng-container>
              <div class="information">
              <div class="field" >
                <label for="Full Name" class="sub">First Name</label><br>
                <input type="text" id="FullName" name="FullName"  [(ngModel)]="personFullName" placeholder="Type here" size="60" class="rounded-input" style="margin-bottom:10px;margin-top:10px" required><br>
                <label for="middleName" class="sub">Middle Name</label><br>
                <input type="text" id="middleName" name="middleName"  [(ngModel)]="personMiddleName" placeholder="Type here" size="60" class="rounded-input" style="margin-bottom:10px;margin-top:10px" required><br>
                <label for="lastName" class="sub">Last Name</label><br>
                <input type="text" id="lastName" name="lastName"  [(ngModel)]="personLastName" placeholder="Type here" size="60" class="rounded-input" style="margin-bottom:10px;margin-top:10px" required><br>
                <div class="firstname" style="margin-top: -7px;">
                <label for="studentPhoto" class="sub">Upload Photo</label><br>
                <div class="custom-file-input"  style="margin-bottom:10px;margin-top:10px" style="display: flex;">
                  <input type="file" id="myFileInput" name="studentPhoto" accept="image/*" value="studentPhoto" (change)="onProfilePictureSelected($event)" style="display: none;" >
                  <label for="myFileInput" class="uploadButton"><p></p>Upload</label>
                  <div class="image-path">{{studentPhoto?.name||hcpPersonPicName ||"No File Choosen" }}</div> 
                </div>
               </div>
          
                <label style="margin-left: -3px;" class="sub">Gender</label>
                <div class="gender-buttons" style="margin-bottom:10px;margin-top:10px">
                  <div class="genderInfo" >
                    <button type="button" (click)="selectMale()" class="btnmale"  [ngClass]="{'selected': selectedGender === 'Male','not-selected': selectedGender !== 'Male'}">
                      <img src="assets/images/maleicon.png" class="male" >
                      <span>Male</span>
                    </button>
                    <button type="button" (click)="selectFemale()" class="btnfemale" [ngClass]="{'selected': selectedGender === 'Female','not-selected': selectedGender !== 'Female'}">
                      <img src="assets/images/image (3).png" class="male">
                      <span>Female</span>
                    </button>
                  </div>
                </div>
              
                <label for="Date of Birth" class="sub" style="margin-top: -10px;">DOB</label><br>
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput [matDatepicker]="startPicker" placeholder="DD-MMM-YYYY" name="personDateOfBirth"  [(ngModel)]="personDateOfBirth">
                  <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                  <mat-datepicker #startPicker></mat-datepicker>
              </mat-form-field>
                <label for="personMobileNumber" class="sub">Mobile</label><br>
                <input type="number" id="personMobileNumber"  name="personMobileNumber" [(ngModel)]="personMobileNumber" (keypress)="onKeyPress($event)" placeholder="Type here" class="rounded-input" style="margin-bottom:10px;margin-top:10px" inputmode="numeric" required maxlength="15"><br>
              
                <label for="Email" class="sub">Email </label><br>
                <input type="text" id="Email" name="Email" [(ngModel)]="personEmail" placeholder="Type here" class="rounded-input" style="margin-bottom:10px;margin-top:10px" required [readOnly]="true"><br>
                <label for="ptype">Type </label><br>
                <select id="ptype" name="hcppersonType" [(ngModel)]="hcppersonType" style="margin-bottom:10px;margin-top:10px" class="rounded-input" required>
                    <option value="HCP" disabled selected>HCP</option>
                    <option value="Ops" disabled selected>OPs</option>
                </select>
                <label for="Email" class="sub">Category </label><br>
                <select id="pcategory" name="personcategoryType" [(ngModel)]="personcategoryType" style="margin-bottom:10px;margin-top:10px" class="rounded-input" required>
                  <option value="" disabled selected>Select your Category</option>
                  <option value="Nurse">Nurse</option>
                  <option value="Doctor">Doctor</option>
                  <option value="Physiotherapist">Physiotherapist</option>
                  <option value="Optometrist">Optometrist</option>
                  <option value="Dentist">Dentist</option>
                  <option value="Other">Other</option>  
              </select>
              <!-- <input type="text" [(ngModel)]="personcategoryTypeOther" name="personcategoryTypeOther" *ngIf="personcategoryType === 'Other'" placeholder="Enter other Category" class="form-control"class="rounded-input" required> -->
              </div>
              </div>
          </div>
          <div class="row" style="text-align: end;">
            <span class="col">
              <button type="submit" class="btn btn-primary" class="nextbtn" >Next</button>
              </span>
          </div>
        </form>
      </div>
      </ng-template>

      <ng-template [ngIf]="displayName==='Note'" >
        <div class="sec">
        <form (ngSubmit)="hcpnotesubmit(noteForm)" enctype="multipart/form-data" #noteForm="ngForm">
          <div class="card-container">
            <div style="display: flex;justify-content: space-between;">
              <h1 class="ba">Consent</h1>
              <div class="profile" *ngIf="studentPhoto">

                <label for="myFileInput">
                  <img *ngIf="studentPhotoImageUrl" [src]="studentPhotoImageUrl" alt="Selected Student Photo"  class="imageicon">
                  <div *ngIf="!studentPhotoImageUrl" class="box-text">
                    <img src="assets/images/imageupload.png" alt="Upload Icon" class="upload-profile">
                  </div>
                </label>
              </div>
              </div>
              <ng-container *ngIf="errorNote !== '' ">
                <div style="color: red; margin-top: 10px;margin-left: 100px;">{{ errorNote  }}</div>
              </ng-container>
              <div class="scroll-bar_3" >
                  <div class="scrollable-content">
                      <div class="page" style="color: #000;">
                          <p>Sign and put date in the last section of the page below the consent section in the space provided.</p> <br>
                          <p>You will be notified of the receipt and a second confirmation will be sent via email.</p><br>
                          <p><b>Important: </b>Once saved or/and printed, you are advised to take utmost care of the Onboarding Confirmation form as the information contained therein is confidential. Neither the Enterprises connected in NIVISH Program nor the NIVISH takes responsibility of the printed form
                          </p><br>
                          <p><span style="margin-left: 0px;"></span>I  <input type="text" placeholder="Form Filled by" [(ngModel)]="typurName" name="typurName" style="  max-width: 120px;  "  class="rounded-input" required > , provided all the license details, education details to register as an Health Care Practitioner under NIVISH program and do hereby give my consent to NIVISH to :
                          </p><br>
                          <div style="margin-left:35px;">
                            <ol>
                              <li>Create my electronic record;</li>
                              <li>Update the electronic record with the information provided in the Onboarding questionnaire and with information gathered from the Designated / Provider from time to time.</li>
                              <li>Process and analyze the information/data gathered and recorded through the NIVISH program for quality and transparency.</li>
                            </ol>
                          </div>                        
                          <br>
                          <p>Signature of <input type="text" placeholder="Name" [(ngModel)]="signaturename" name="signaturename" style="  max-width: 120px;  "  class="rounded-input" required> 
                          </p><br>
                          <p style="color: #000;">Upload your digital Sign</p>

                         </div> 
                          
                          <ng-container *ngIf="errorsignImage !== ''">
                            <div style="color: red; margin-left: 100px;margin-bottom: 10px;">{{ errorsignImage }}</div>
                          </ng-container>
                          <div class="sign">
                            <div class="image-upload-container">
                              <input type="file" accept="image/*" id="digitalSignInput" name="Upload_Your_Sign" (change)="onProfilePictureSelecte($event)" style="display: none;" required/>
                              <label for="digitalSignInput" class="image-box">
                                <img *ngIf="digitalSignImageUrl" [src]="digitalSignImageUrl" alt="Selected Digital Sign" class="preview-image"/>
                                <div  *ngIf="!digitalSignImageUrl" class="box-text">
                                  <img src="assets/images/imageupload.png"  style="margin-left: 0px;" alt="Upload Icon" class="upload-icon" />
                                </div>
                              </label>
                            </div>
                          </div>
                          <p>
                            <p style="color: #000;">
                              <span class="text-danger"></span>
                              Date
                              <mat-form-field appearance="outline" style="max-width: 120px; margin-left:5px"  >
                                <input matInput readonly  placeholder="DD/MMM/YYYY" name="submittedDt" [value]="dateConvert(submittedDt)"
                                (ngModelChange)="onDateChange($event)" required>
                                <!-- <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                                <mat-datepicker #startPicker></mat-datepicker> -->
                              </mat-form-field>
                          </p>                                            
                          <br>
                           <p style="color: #000;"> <span class="text-danger"></span>Place   <input type="text" [(ngModel)]="placeof" name="placeof"  placeholder="Type location"style="  max-width: 120px; margin-lrft:5px "  class="rounded-input" required> 
                          </p>                            
                          <br>
                          <p><input type="checkbox" style="margin-left: 50px;" id="policies"  [(ngModel)]="terms"  name="policies" value="policies"  [(ngModel)]="policies" required><span  style="margin-left: 2px;"></span>I have read the policies and I provide my consent to NIVISH.
                          </p>
                          <div *ngIf="termsmessge" class="error-message">
                            {{ termsmessge }}
                          </div>                            
                          <br>
                  </div>
              </div>
          </div>
          <div class="row_1">
            <div class="col_2">
                <button type="button" class="btn btn-secondary back-btn"(click)="Education()">Previous</button>
            </div>
            <div class="col_1">
                <button type="submit" class="btn btn-primary submit-btn">Submit</button>
            </div>
        </div> 
      </form>
      </div>
    </ng-template> 
  </div>
 </div>