
<div class="main-container">
    <div class="row">
        <div class="col">
            <div class="section">
                <div class="scroll-bar">
                  <p class="para"> Health Camp</p>
                    <div class="scroll-content">
                        <div class="round">
                            <img src="assets/images/png.png">
                        </div>
                        <p>Station A</p><br>
                        <div class="round">
                            <img src="assets/images/hisymbol.png">
                        </div>
                        <p>Station B</p><br>
                        <div class="round">
                            <img src="assets/images/png.png.png">
                        </div>
                        <p>Station C</p><br>
                        <div class="round">
                            <img src="assets/images/png.png.png">
                        </div>
                        <p>Station D</p><br>
                        <div class="round">
                            <img src="assets/images/png.png.png">
                        </div>
                        <p>Station E</p><br>
                        <div class="round">
                            <img src="assets/images/png.png.png">
                        </div>
                        <p>Station F</p><br>
                        <div class="round">
                            <img src="assets/images/png.png.png">
                        </div>
                        <p>Station G</p><br>
                    </div>
                </div>
              </div>
          </div>
        </div>
        <ng-template [ngIf]="displayName==='medicalcamp'">
            
            <div class="sec">
            <div class="card-container">
                
                <button (click)="goBack()" class="backbutton">
                    <img src="assets/images/arrow.png" alt="Back" class="backbutton-img">
                    Back To Student List
                </button>

                <!-- <div class="selected-tags"> -->
                    
                  <!-- </div> -->
                <div class="header-container">
                   
                    <div class="healthdown">
                        
                       
                    <h1 class="Health">UIN:234145768</h1>
                    <div class="tag" *ngFor="let selectedCheckbox of selectedCheckboxes">
                        {{ selectedCheckbox }}
                        <span class="close" (click)="removeCheckbox(selectedCheckbox)">x</span>
                      </div>
                              
                    <!-- <button class="image-button">
                        <img src="assets/images/SearchBar.png"/>
                    </button> -->
                   
                </div>
               
               
                    <div class="filtercontainer">
                        <div class="filter-header">
                          <span class="material-icons filter" id="filter" (click)="toggleFilter()">filter_alt</span>
                        </div>

                        

                       
                        <div *ngIf="showFilter" class="filter-container">
                          
                          <!-- <mat-card>
                          <div class="filter-section dropdown-container">
                            <p>Flag</p>
                            <div class="check">
                              <input type="checkbox" class="larger-checkbox" value="null" name="Caste">
                              <span class="text">WNL</span>
                              <div class="dropdown-container">
                              </div>
                            </div>
                            <div class="check">
                            <input type="checkbox" class="larger-checkbox" value="null" name="number"/>
                            <span class="text">Non- Urgent</span>
                            </div>
                            <div class="check">
                            <input type="checkbox" class="larger-checkbox" value="null" name="profession"/>
                            <span class="text">Urgent</span>
                            </div>
                            <div class="check">
                            <input type="checkbox" class="larger-checkbox"  value="null" name="partyInclination_id"/>
                            <span class="text">Emergency</span>
                            </div>
                            <p class="Review">Review Status</p>
                            <div class="check">
                            <input type="checkbox" class="larger-checkbox"  value="null" name="contactmode"/>
                            <span class="text">None</span>
                            </div>
                            <div class="check">
                            <input type="checkbox" class="larger-checkbox"  value="null" name="nonlocal"/>
                            <span class="text">Completed</span>
                            </div>
                            <div class="check">
                                <input type="checkbox" class="larger-checkbox"  value="null" name="nonlocal"/>
                                <span class="text">Hold</span>
                                </div>
                          </div>
                          <button type="submit" class="apply-button" >Apply</button>

                        </mat-card> -->
                        
                        <div *ngIf="showFilter" class="filter-container">
                            <mat-card>
                              <div class="filter-section dropdown-container">
                                <p>Flag</p>
                                <!-- Using *ngFor to iterate over an array of checkbox values -->
                                <div class="check" *ngFor="let checkbox of ['WNL', 'Non-Urgent', 'Urgent', 'Emergency']">
                                  <!-- Checkbox input -->
                                  <input type="checkbox" class="larger-checkbox" [value]="checkbox" name="checkbox" (change)="toggleCheckbox(checkbox)" />

                                  <!-- Checkbox label -->
                                  <span class="text">{{ checkbox }}</span>
                                  
                                </div>
                                <p class="reciewstatus">Review status</p>
                                <!-- Using *ngFor to iterate over an array of checkbox values -->
                                <div class="check" *ngFor="let checkbox of ['Hold', 'Completed', 'None', ]">
                                  <!-- Checkbox input -->
                                  <input type="checkbox" class="larger-checkbox" [value]="checkbox" name="checkbox" (change)="toggleCheckbox(checkbox)" />

                                  <!-- Checkbox label -->
                                  <span class="text">{{ checkbox }}</span>
                                  
                                </div>
                          
                                <!-- Add other checkboxes... -->
                          
                                <!-- Button to apply the filter -->
                                <button type="submit" class="apply-button" (click)="applyFilter()">Apply</button>
                              </div>
                            </mat-card>
                          </div>
                          
                    </div>
                        </div>
                </div>
                
                
                <div class="header-container-2">
                    <span class="Station">Station B: Vitals</span>
                    <span >
                        <button class="editbutton" (click)="doctorreview()">Edit</button>
                    </span>
                    <span class="image-button_1">
                        <img src="assets/images/hisymbol.png" alt="Hold" />
                    </span>
                    <span class="image-button_1">
                        <img src="assets/images/click.png" alt="Vector" />
                    </span>
                    <span class="image-button_1" (click)="toggleTextField()">
                        <img src="assets/images/message.png" alt="Comment" />
                    </span>
                    <div class="comment-container">
                        <input *ngIf="showTextField" type="text" placeholder="Enter your comment" [(ngModel)]="comment" class="commenttextbox" />
                        <div class="comment-buttons" *ngIf="showTextField">
                            <button (click)="saveComment()">Save</button>
                            <button (click)="cancelComment()">Cancel</button>
                        </div>
                    </div>
                </div>
                <div class="custom-container">
                    <div class="custom-text">Date Of Assessment : 31 July 2023</div>
                </div>
                <div class="custom-container_1">
                    <div class="custom-text_1" (click)="openProfile()" > Assessed By :  Dr. Al Balushi</div>
                </div>
              

                <div class="tabledta">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Fields</th>
                                <th>Normal Range</th>
                                <th>Health camp</th>
                            </tr>
                        </thead>
                        <h1 class="blood">Blood Pressure</h1>
                        <tbody>
                            <tr>
                                <td>Type of instrument</td>
                                <td>90 -120  mm hg</td>
                                <td>Electronic digital instrument</td>
                            </tr>
                            <tr>
                                <td>Systolic BP</td>
                                <td>60-80 mm hg</td>
                                <td>77 mm hg in</td>
                            </tr>
                            <tr>
                                <td>Diastolic BP</td>
                                <td>20/min</td>
                                <td>77 min</td>
                            </tr>
                            <tr>
                                <td>Respiration</td>
                                <td>60-100/ min</td>
                                <td>77 Spo2</td>
                            </tr>
                            <tr>
                                <td>Oxygen Saturation Spo2</td>
                                <td>95-100% Spo2</td>
                                <td>77 Spo2</td>
                            </tr>
                            <h1 class="blood">Temperature</h1>
                            <tr>
                                <td>Measurement Site</td>
                                <td>Aural</td>
                                <td>Aural</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
         </div>

         <div class="popup-cont" *ngIf="isProfileOpen">
         
            <div class="container2">
             <h1 style="margin-left:270px;">Doctor Profile</h1>
             <h3 style="margin-left:270px;">Personal Information</h3>
           
              <div class="field" >
                <label for="Full Name">Full Name</label>

                <input type="text" id="Full Name" name="Full Name"  [(ngModel)]="personFullName" placeholder="Type here" size="60" class="rounded-input"><br><br>
              
                <label style="margin-left:3px;" class="sub">Gender</label><br>
                <div class="gender-buttons">
                  <div class="genderInfo" >
                    <button type="button" class="btnmale"  [ngClass]="{'selected': selectedGender === 'Male'}">
                      <img src="assets/images/maleicon.png" class="male" >
                      <span>Male</span>
                    </button>
                  </div>
                </div>
              
                <label for="Date of Birth" class="sub" style="margin-top:10px;">DOB</label><br>

                <input type="date" id="Date of Birth" name="Date of Birth" [(ngModel)]="personDateOfBirth" placeholder="Type here" class="rounded-input"><br><br>
              
                <label for="Mobile Number" class="sub">Mobile Number</label><br>
                <input type="text" id="Mobile Number" name="Mobile Number" [(ngModel)]="personMobileNumber" placeholder="Type here" class="rounded-input"><br><br>
              
                <label for="Email" class="sub">Email</label><br>
                <input type="text" id="Email" name="Email" [(ngModel)]="personEmail" placeholder="Type here" class="rounded-input"><br>
               
                <h1 style="margin-left:250px;margin-top:20px;">Education</h1><br>
                <div>Education</div>
                 <div class="education_class"> 
                <div class="card_education">
                </div>
                    <div class="card_education2">
                    </div>
                    </div>

                    <h1 style="margin-left:250px;margin-top:-40px;">Licensee Details</h1><br>
                    <h3>Experience</h3><br>
                    <h4 class="years">8years 4years</h4>
                    <div class="Licensee">Licensee Details</div>
                     <div class="_classedding"> 
                    <div class="educationedding">
                    </div>
                        <div class="education2edding">
                        </div>
                        
                        </div>
                        <button class="buttonpopup1"  >Back to edit</button>
                        <button class="buttonpopup" (click)="closepopup3()" >close</button>
                  </div>
              </div>
          </div> 
        </ng-template>

<ng-template [ngIf]="displayName==='doctorreview'">
            <div class="sec">
            <div class="card-container">
                <button (click)="goBack()" class="backbutton">
                    <img src="assets/images/arrow.png" alt="Back" class="backbutton-img">
                    Back To Student List
                </button>
                <div class="header-container">
                    <h1 class="Health">UIN:234145768</h1>
                    <button class="image-button">
                        <img src="assets/images/SearchBar.png"/>
                    </button>
                </div>
                <div class="header-container-2">
                    <span class="Station">Station B: Vitals</span>
                    <span >
                        <button class="reviewbutton" (click)="opencampreview()">save</button>
                    </span>
                     <span >
                        <button class="cancelbutton" (click)="closemedicalcamp()">Cancel</button>
                    </span>
                    <span class="image-button_1">
                        <img src="assets/images/hisymbol.png" alt="Hold" />
                    </span>
                    <span class="image-button_1">
                        <img src="assets/images/click.png" alt="Vector" />
                    </span>
                    <span class="image-button_1" (click)="toggleTextField()">
                        <img src="assets/images/message.png" alt="Comment" />
                    </span>
                    <div class="comment-container">
                        <input *ngIf="showTextField" type="text" placeholder="Enter your comment" [(ngModel)]="comment" class="commenttextbox" />
                        <div class="comment-buttons" *ngIf="showTextField">
                            <button (click)="saveComment()">Save</button>
                            <button (click)="cancelComment()">Cancel</button>
                        </div>
                    </div>
                </div>
                <div class="custom-container">
                    <div class="custom-text">Date Of Assessment : 31 July 2023</div>
                </div>
                <div class="custom-container_1">
                    <div class="custom-text_1"> Assessed By :  Dr. Al Balushi</div>
                </div>
                <div class="tabledta">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Fields</th>
                                <th>Normal Range</th>
                                <th>Health camp</th>
                            </tr>
                        </thead>
                        <h1 class="blood">Blood Pressure</h1>
                        <tbody>
                            <tr>
                                <td>Type of instrument</td>
                                <td>90 -120  mm hg</td>
                                <td><input type="text"></td>
                            </tr>
                            <tr>
                                <td>Systolic BP</td>
                                <td>60-80 mm hg</td>
                                <td><input type="text"></td>
                            </tr>
                            <tr>
                                <td>Diastolic BP</td>
                                <td>20/min</td>
                                <td><input type="text"></td>
                            </tr>
                            <tr>
                                <td>Respiration</td>
                                <td>60-100/ min</td>
                                <td><input type="text"></td>
                            </tr>
                            <tr>
                                <td>Oxygen Saturation Spo2</td>
                                <td>95-100% Spo2</td>
                                <td><input type="text"></td>
                            </tr>
                            <h1 class="blood">Temperature</h1>
                            <tr>
                                <td>Measurement Site</td>
                                <td>Aural</td>
                                <td><input type="text"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
         </div>
         <div class="radio-buttons" >
            <input type="text"   >
           
          
           <input type="text">
          
        </div>


        </ng-template>


        <ng-template [ngIf]="displayName==='campreview'">
        <div class="sec">
            <div class="card-container">
                <button (click)="goBack1()" class="backbutton">
                    <img src="assets/images/arrow.png" alt="Back" class="backbutton-img">
                    Back To Student List
                </button>
            <div class="header-container">
                <h1 class="Health">Review Comment</h1>
                <button class="image-button">
                    <img src="assets/images/SearchBar.png"/>
                </button>
            </div>
                <p class="commit"> Comment/Details</p>
                <div>
                <textarea type="textarea" class="textbox"></textarea>
            </div>
            <div class="radio-buttons" >
                <input type="radio"id="Yes" name="alternative#" value="Mark as completed And send email to parent" style="margin-left: 40px;"(click)="openPopup()" >
               <label for="Yes" style="padding-left: 10px; color: #000000; margin-top: 40px;"  >Mark as completed And send email to parent</label>
               <input type="radio" id="No" name="alternative#" value="Mark as completed And send email to parent" style="margin-left:25px;" (click)="Popup()">
               <label for="No" style="padding-left: 10px; color: #000000;" >Mark as completed and hand over in person</label>
               <input type="radio" id="Save as Draft" name="alternative#" value="Mark as completed And send email to parent" style="margin-left:25px;">
               <label for="No" style="padding-left: 10px; color: #000000;">Save as Draft</label>
            </div>
            <button type="submit" class="btn btn-primary"  style="margin-left:450px;margin-top:30px;background-color: #8F1402;width:100px;" (click)="openPopup_1">Confirm</button>
            <div class="popup-container" *ngIf="isPopupOpen">
                <div class="container2">
                 <h1 style="margin-left:130px;">submit conformation </h1>
                 <h4 style="margin-left: 50px;margin-top: 40px;">The student health report will be sent to the parents.</h4>
                <h5 style="margin-left: 130px;margin-top: 40px;">Are You Sure You Want To Send? </h5>
                    <button style="margin-left:125px;margin-top: 50px;width: 100px;border-radius: 40px;background-color: #8F1402;color:white" (click)="closePopup()">ok</button>
                    <button style="margin-left:50px;margin-top: 50px;width: 100px;border-radius: 40px;background-color: #8F1402;color: white;" (click)="closePopup()">cancel</button>
                  </div>
                </div>
            </div>
            </div>
            <div class="popup-container" *ngIf="isOpen">
                <div class="container2">
                 <h1 style="margin-left:130px;">submit conformation </h1>
                 <h6 style="margin-left:170px;margin-top: 40px;">Is Person Report</h6>
                <h5 style="margin-left:150px;margin-top: 40px;">Are You Sure You Want To Send? </h5>
                    <button style="margin-left:125px;margin-top: 50px;width: 100px;border-radius: 40px;background-color: #8F1402;color:white" (click)="close()">ok</button>
                    <button style="margin-left:50px;margin-top: 50px;width: 100px;border-radius: 40px;background-color: #8F1402;color: white;" (click)="close()">cancel</button>
                  </div>
                </div>
                <div class="popup-container" *ngIf="isOpen_1">
                    <div class="container2">
                        <button type="button" class="btn-close" ></button>
                    </div>
                </div>
          </ng-template>

    
        </div>





